import axios from "axios";
import { defineStore } from "pinia";
import { EventType, iEventLog } from "./useWebSocketStore";
import { ActionMessageInterface } from "rundown-common";

export const useMessageStore = defineStore("messages", {
  state: () => ({
    events: { error: [], status: [] } as Record<string, Array<iEventLog>>,
    client: [] as Array<string>,
  }),

  getters: {
    hasError(state) {
      return state.events["error"].length > 0;
    },

    getError(state): iEventLog {
      return state.events["error"][0] ?? [];
    },

    getErrors(state): Array<iEventLog> {
      return state.events["error"];
    },

    hasStatus(state) {
      return state.events["status"].length > 0;
    },

    getStatus(state): iEventLog {
      return state.events["status"][0] ?? [];
    },

    getStatuses(state): Array<iEventLog> {
      return state.events["status"];
    },

    hasClient(state) {
      return state.client.length > 0;
    },

    getClient(state): string {
      return state.client[0] ?? "";
    },
  },

  actions: {
    handleAxiosError(pAxiosError: any, pMessage: string = "") {
      if (!axios.isAxiosError(pAxiosError)) return;

      const messages: Record<string | number, string> = {
        ECONNABORTED: "Run-Down server is currently not available",
        ERR_NETWORK: "Run-Down server is currently not available",
        403: "Permission Denied",
        ERR_BAD_RESPONSE: "Unknown server error",
      };

      const errorKey = pAxiosError?.code || pAxiosError?.response?.status;
      const baseMessage = errorKey && errorKey in messages ? messages[errorKey] : "An error occurred";

      this.add(`${baseMessage}${pMessage ? `: ${pMessage}` : ""}`);
    },

    processAction(pMessage: ActionMessageInterface) {
      const entry = {
        id: 0,
        level: EventType.Status,
        message: pMessage.message,
      } as iEventLog;

      this.process(entry);
    },

    process(pEventLog: iEventLog) {
      // Empty Messages can be discarded
      if (pEventLog.message == "") {
        return;
      }

      var targetArray = this.getEventArray(pEventLog);
      if (targetArray == undefined) return;

      targetArray.push(pEventLog);
    },

    getEventArray(pEventLog: iEventLog) {
      switch (pEventLog.level) {
        case EventType.Error:
          return this.events["error"];

        case EventType.Notice:
        case EventType.Status:
          return this.events["status"];
      }
    },

    remove(pType: string) {
      if (pType === "client") {
        this.client.shift();
        return;
      }

      this.events[pType].shift();
    },

    removeItem(pEventLog: iEventLog) {
      var targetArray = this.getEventArray(pEventLog);
      if (targetArray == undefined || targetArray?.length == 0) return;

      const index = targetArray.indexOf(pEventLog);
      targetArray.splice(index, 1);
    },

    add(pMessage: string) {
      // Don't allow duplicated messages
      if (!this.client.includes(pMessage)) {
        this.client.push(pMessage);
      }
    },
  },
});

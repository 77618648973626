<script setup lang="ts">
  import { ref } from "vue";
  import { useItemSlidingWindowStore } from "@/stores/useItemSlidingWindowStore";
  import { useItemStore } from "@/stores/useItemStore";
  import ItemTable from "@/components/table/ItemTable.vue";
  import { useKeywordStore } from "@/stores/useKeywordStore";

  import { useDisplay } from "vuetify/lib/framework.mjs";
  import Image from "@/components/Image.vue";
  import { usePersonSearch } from "@/utility/UsePersonSearch";
  import { useItemSelection } from "@/utility/useItemSelection";
  import { useDialogHandling } from "@/utility/useDialogHandling";

  const itemWindow = useItemSlidingWindowStore();
  const itemStore = useItemStore();

  const keywords = useKeywordStore();

  const {
    selectedItems,
    selectAllItems,
    toggleSelectedItem,
    isAllItemsInPageSelected,
  } = useItemSelection(itemWindow);

  const { personGetName, peopleSearchInput, peopleSearch, peopleAvailable } =
    usePersonSearch();

  const {
    dialogStart,
    dialogSaving,
    showStartDialog,
    closeStartDialog,
    showSavingDialog,
    closeSavingDialog,
  } = useDialogHandling();

  var category = ref("");
  var keyword = ref([] as Array<string>);
  var personNames = ref([]);

  var enableCategory = ref(false);
  var enableKeyword = ref(false);
  var enablePeople = ref(false);

  function calcImageHeight(): string {
    const { name, height } = useDisplay();
    return Math.floor(height.value / 3.75).toString();
  }

  function calcItemsHeight(): string {
    const { name, height } = useDisplay();
    const fin =
      Math.floor(height.value - Number(calcImageHeight()) - 430).toString() +
      "px";

    return fin;
  }

  /**
   * Apply the tags
   */
  async function applyTags() {
    showSavingDialog();

    for (let item of selectedItems.value.all()) {
      if (enableCategory.value) {
        item.category = category.value;
      }
      if (enableKeyword.value) {
        item.keywords = keyword.value;
      }
      if (enablePeople.value) {
        item.people = personNames.value;
      }

      await item.save();
      selectedItems.value.removeItem(item);
    }

    closeSavingDialog();
  }
</script>

<template>
  <v-container class="px-0">
    <v-card class="mx-0">
      <template v-slot:title>Tag Selection for Bulk Update</template>

      <v-layout>
        <v-row>
          <v-col cols="7">
            <Image
              :item="itemStore.getSelectedItem"
              :height="calcImageHeight()"
              thumbnail="large"
            >
            </Image>
          </v-col>
          <v-col cols="5">
            <v-col
              cols="12"
              justify="end"
            >
              <v-row>
                <v-col cols="6">
                  <v-switch
                    v-model="enablePeople"
                    color="primary"
                    label="Names"
                    inset
                  ></v-switch>
                </v-col>

                <v-col cols="6">
                  <v-switch
                    v-model="enableCategory"
                    color="primary"
                    label="Category"
                    inset
                  ></v-switch>
                  <v-switch
                    v-model="enableKeyword"
                    color="primary"
                    label="Keywords"
                    inset
                  ></v-switch>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="11">
              <v-combobox
                v-if="enableCategory"
                v-model="category"
                :items="keywords.getCategories"
                label="Category"
                clearable
                item-title="content"
                variant="underlined"
                :menu-props="{ maxHeight: 500 }"
              >
              </v-combobox>

              <v-combobox
                v-if="enableKeyword"
                v-model="keyword"
                :delimiters="[',']"
                :items="keywords.getKeywords"
                label="Keywords"
                multiple
                return-object
                closable-chips
                chips
                :menu-props="{ maxHeight: 500 }"
                item-title="content"
                variant="underlined"
              >
              </v-combobox>

              <v-combobox
                v-if="enablePeople"
                v-model="personNames"
                v-model:search="peopleSearch"
                :delimiters="[',']"
                :items="peopleAvailable"
                label="People"
                multiple
                return-object
                chips
                closable-chips
                @update:search="peopleSearchInput"
                :menu-props="{ maxHeight: 500 }"
                :item-title="personGetName"
                :hide-no-data="false"
                variant="underlined"
              >
                <template
                  v-slot:no-data
                  v-if="peopleSearch.length > 2"
                >
                  <v-list-item>
                    <v-list-item-title>
                      No results matching "<strong>{{ peopleSearch }}</strong
                      >". Press <kbd>enter</kbd> to create a new one
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template
                  v-slot:no-data
                  v-else
                >
                </template>
              </v-combobox>
            </v-col>

            <v-row class="justify-center">
              <v-col
                cols="5"
                class="justify-center"
              >
                <v-btn
                  variant="tonal"
                  @click="showStartDialog(selectedItems.size())"
                >
                  Apply Tags
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-layout>
    </v-card>

    <ItemTable
      :isLoading="itemStore.isLoading"
      :height="calcItemsHeight()"
      read-only
      :items="itemWindow.items"
      :item-current="itemStore.getSelectedItem"
      :page="itemWindow.windowNumber"
      :pages="itemWindow.totalWindows"
      :items-per-page="itemWindow.windowSize"
      @page-select="itemWindow.changeToWindow"
      @item-select="itemStore.selectItem"
      @items-per-page-change="itemWindow.setWindowSize"
      @item-next="itemWindow.moveDown"
      @item-previous="itemWindow.moveUp"
      @page-next="itemWindow.moveWindowDown"
      @page-previous="itemWindow.moveWindowUp"
      show-actions
    >
      <template v-slot:title>Item Selection</template>
      <template #actionHeader="{}">
        <v-checkbox-btn
          label="All"
          :model-value="isAllItemsInPageSelected()"
          @click="selectAllItems"
        ></v-checkbox-btn>
      </template>
      <template #action="{ item }">
        <v-checkbox-btn
          :model-value="selectedItems.hasItem(item)"
          @click.stop="toggleSelectedItem(item)"
        ></v-checkbox-btn>
      </template>
    </ItemTable>
  </v-container>

  <v-dialog
    v-model="dialogStart"
    persistent
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h5">Start Tagging</v-card-title>
      <v-card-text
        >There is {{ selectedItems.size() }} items selected.</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="green-darken-1"
          variant="text"
          @click="closeStartDialog()"
        >
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="green-darken-1"
          variant="text"
          @click="
            closeStartDialog();
            applyTags();
          "
        >
          Bulk Tag
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <v-dialog
    v-model="dialogSaving"
    :scrim="false"
    persistent
    width="auto"
  >
    <v-card color="primary">
      <v-card-text>
        Tagging, please stand by
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

import { defineStore } from "pinia";
import { cItem } from "../domain/item";
import { ItemData, ItemRotate } from "rundown-common";
import axios from "axios";
import { handleError } from "../utility/useErrorHandler";
import { useItemPagination } from "../utility/useItemPagination";
import { useItemNavigation } from "../utility/useItemNavigation";
import { useFilterStore } from "./useFilterStore";
import { ref, watch } from "vue";
import { useCollectionStore } from "./useCollectionStore";

export const useItemStore = defineStore("items", () => {
  const {
    pages,
    getRange,
    getTotalRecords,
    isLoading,
    load: loadPage,
    setItemsPerPage,
    reloadCounter,
    refreshCounter,
    removeItem,
    getIndexOfItemId
  } = useItemPagination();

  const navigation = useItemNavigation(pages);
  const filters = useFilterStore();

  async function reload() {
    await pages.reload();
  }
  const isLoadingAll = ref(false);
  const cancelLoadingAll = ref(false);

  async function load() {
    // Cancel any ongoing loadAllPages operation before loading
    if (isLoadingAll.value) {
      cancelLoadingAll.value = true;
    } else {
      // If loadMissing is not running, start loading immediately
      await loadPage();
    }
  }

  /**
   * Load all pages
   */
  async function loadAllPages() {
    if (isLoadingAll.value) return;
    while (isLoading.value) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    isLoadingAll.value = true;
    cancelLoadingAll.value = false;

    try {
      while (!cancelLoadingAll.value && (await pages.loadNextPage())) {
        // Continue loading missing pages
      }
    } finally {
      isLoadingAll.value = false; // Reset once loading is complete or canceled
    }
  }

  /**
   * Load each page until we find the itemid
   */
  async function loadPagesUntilItemFound(pItemId: number) {
    if (isLoadingAll.value) return false;

    // Wait if another loading process is in progress
    while (isLoading.value) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    isLoadingAll.value = true;
    cancelLoadingAll.value = false;

    try {
      // Continue loading pages until the item is found or all pages are loaded
      while (!cancelLoadingAll.value && (await pages.loadNextPage())) {
        const index = getIndexOfItemId(pItemId);
        if (index !== -1) {
          return true;
        }
      }
    } finally {
      isLoadingAll.value = false; // Reset once loading is complete or canceled
    }
    return false;
  }


  watch(isLoadingAll, async (newVal) => {
    if (!newVal && cancelLoadingAll.value) {
      // Reset cancel flag and start loading a new page
      cancelLoadingAll.value = false;
      await loadPage();
    }
  });

  /**
   *
   */
  async function update(pItem: ItemData, pDeleted: boolean, pContext: object) {
    const item = pages.getItem(pItem.id);
    if (
      item &&
      item?.item.collection_id !== pItem.collection_id &&
      pItem.collection_id !== pages.getCollectionId()
    ) {
      pDeleted = true;
    }

    if (pDeleted) {
      removeItem(pItem.id);
      const collectionId = item?.item.collection_id;
      if (collectionId) {
        const collection = useCollectionStore().getCollectionById(collectionId);
        if (collection) {
          collection.itemcount--;
        }
      }

      if (
        navigation.getSelectedItem.value.getId() ===
        navigation.getSelectedItemIndex.value
      ) {
        if (navigation.getSelectedItemIndex.value < getTotalRecords.value - 1) {
          navigation.selectNextItem();
        } else {
          navigation.clearSelectedItem();
        }
      }
    } else {
      item?.refresh(pItem, pContext);
    }
  }

  /**
   *
   */
  async function getByID(pID: number): Promise<cItem> {
    try {
      const { data } = await axios.get(`/v1/item/${pID}`);
      return new cItem(data);
    } catch (error) {
      handleError(error, `Failed to load Item: ${pID}`);
      return new cItem();
    }
  }

  function addHours(numOfHours: number, date: Date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);
    return date.toUTCString();
  }

  async function share(pID: number, pHours: number) {
    try {
      const { data } = await axios.post(`/v1/item/${pID}/share`, {
        expires: addHours(pHours),
      });
      return data;
    } catch (error) {
      handleError(error, "Failed to create share");
      return null;
    }
  }

  async function download() {
    try {
      const query = `${filters.getCollectionId}?${filters.getParameters}`;
      const { data } = await axios.get(`/v1/item/download/${query}`, {
        responseType: "blob",
      });
      return data;
    } catch (error) {
      handleError(error, "Failed to download image");
    }
  }

  async function rotate(pItemID: number, pDirection: ItemRotate) {
    try {
      const { data } = await axios.put(
        `/v1/item/${pItemID}/tools/rotate?direction=${pDirection.toLowerCase()}`
      );
      return data;
    } catch (error) {
      handleError(error, "Failed to rotate image");
      return false;
    }
  }

  return {
    getRange,
    getTotalRecords,
    getIndexOfItemId,
    isLoading,
    reloadCounter,
    refreshCounter,
    setItemsPerPage,
    load,
    loadAllPages,
    loadPagesUntilItemFound,
    reload,
    update,
    getByID,
    share,
    download,
    rotate,
    remove: removeItem,
    ...navigation,
  };
});

import { defineStore } from "pinia";

export type Option = {
  title: string;
  value: string;
  icon: string;
  isActive: boolean;
  isSlider: boolean;
  sliderValue: number;
};

export const useDynamicToolbarSettings = defineStore("DynamicToolbarSettings", {
  state: () => ({
    ItemMetaDataButtons: [] as string[],
    ItemMetaDataActions: {} as Record<string, boolean>,
    ItemMetaDataValues: {} as Record<string, number>,
  }),

  getters: {
    isFaceRecognitionEnabled(state) {
      return state.ItemMetaDataActions["FaceRecognition"] ?? false;
    },
    isFaceRecognitionAutoTagEnabled(state) {
      return state.ItemMetaDataActions["FaceRecognitionAutoTag"] ?? false;
    },
    getFacialRecognitionConfidence(state) {
      return state.ItemMetaDataValues["FaceRecognitionConfidence"] ?? 0.7;
    },
  },

  actions: {
    setItemMetaDataButtons(newState: string[]) {
      this.ItemMetaDataButtons = newState;
    },

    setItemMetaDataOption(pName: string, pState: boolean) {
      this.ItemMetaDataActions[pName] = pState;
    },

    getItemMetaDataOption(pName: string) {
      return this.ItemMetaDataActions[pName];
    },

    setItemMetaDataValue(pName: string, pValue: number) {
      this.ItemMetaDataValues[pName] = pValue;
    },
    getItemMetaDataValue(pName: string) {
      return this.ItemMetaDataValues[pName];
    },
  },

  persist: true,
});

<script setup lang="ts">

import { computed} from 'vue';
import { useSettingsStore } from '../stores/useSettingsStore';
import FilterCollectionList from './sidebar/FilterCollectionList.vue';
import FilterDateList from './sidebar/FilterDateList.vue';
import RouteMenuList from './sidebar/RouteMenuList.vue';
import FilterLocation from './sidebar/FilterLocation.vue';

const builddate = __BUILD_DATE__;

const settings = useSettingsStore();

var drawer = computed({
  get(): boolean {
    return settings.isSidebarVisible;
  },
  set(newValue) {
    settings.setSidebarVisible();
  }
}
);

</script>


<template>
  <v-navigation-drawer :permanent="!settings.isScreenSmall" v-model="drawer" rail-width="20" width="200">

    <FilterCollectionList></FilterCollectionList>
    <v-divider></v-divider>

    <FilterDateList></FilterDateList>
    <v-divider></v-divider>

    <FilterLocation></FilterLocation>
    <v-divider></v-divider>

    <RouteMenuList></RouteMenuList>
    <v-spacer></v-spacer>
    <v-divider />

    <template v-slot:append>
      <v-list-item class="justify-center">
        <v-list-item-title><small>{{ builddate }}</small></v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>

</template>


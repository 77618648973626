import { ref } from 'vue'
import { usePeopleStore } from '../stores/usePeopleStore';
import { PeopleSearchMenuItem, PersonFaceData, PersonInterface, ItemPersonInterface } from 'rundown-common';
import { useHumanDetector } from './useHumanDetector';
import { FaceResult } from '@vladmandic/human';
import { cPerson } from '../domain/Person';
import { cItemPersonObject } from '../domain/ItemPersonObject';

export interface FaceMatch {
    similarity: number;
    person: PersonInterface;
    count: number;
}

export function usePersonSearch() {
    const people = usePeopleStore();
    const human = useHumanDetector();

    const peopleSearch = ref('');
    const peopleFindMenu = ref<Array<PeopleSearchMenuItem>>([]);
    const peopleAvailable = ref<Array<PeopleSearchMenuItem | PersonInterface | ItemPersonInterface>>([]);

    function isMenuItem(pItem: any): pItem is PeopleSearchMenuItem{
        return pItem && (typeof pItem.isHeadline === 'boolean') && (typeof pItem.object !== 'undefined');
    }

    /**
     * Get a person name
     */
    function personGetName(pItem: string | PeopleSearchMenuItem | PersonInterface | ItemPersonInterface) {
        if (isMenuItem(pItem)) {

            // Menu items with text and returned as objects
            if (pItem.text.length)
                return pItem;

            return pItem.object?.getSearchName();
        }

        if(pItem instanceof cPerson) {
            return pItem.getSearchName();
        }

        if(pItem instanceof cItemPersonObject) {
            if(!pItem.object.person)
                return '';
            
            return pItem.getPerson()?.getSearchName();
        }

        return pItem;
    }

    /**
     * Find a list of possible Person's by searching for a face
     */
    function peopleFindByFace(pFace: PersonFaceData): Array<PersonInterface> {
        const faces = people.getFaces;
        const results = human.compareFaces(pFace.embedding_data, Array.from(faces.values()));
        const seenPersonIds = new Set<number>();

        return results.reduce((acc, result) => {
            if (!seenPersonIds.has(result.face.person)) {
                seenPersonIds.add(result.face.person);
                const person = people.getPersonById(result.face.person);
                if (person) acc.push(person);
            }
            return acc;
        }, [] as Array<PersonInterface>);
    }

    /**
     * 
     */
    function peopleFindHighMatch(pFace: FaceResult, pSkipId: Array<number>): FaceMatch | null {
        if(!pFace.embedding)
            return null;
        
        const faces = people.getPersonFacesWithout(pSkipId);
        const results = human.compareFaces(pFace.embedding, faces);

        // Check if there are no results
        if (results.length === 0) {
            return null;
        }

        // Accumulate similarity scores and count for each person
        const similarityScores: { [personId: number]: { total: number; count: number } } = {};
        results.forEach(result => {
            const personId = result.face.person;
            if (!similarityScores[personId]) {
                similarityScores[personId] = { total: 0, count: 0 };
            }
            similarityScores[personId].total += result.similarity;
            similarityScores[personId].count++;
        });

        // Calculate the average similarity for each person and find the highest
        let highestAverage: { personId: number; average: number } | null = null;
        for (const personId in similarityScores) {
            const average = similarityScores[personId].total / similarityScores[personId].count;
            if (similarityScores[personId].count > 5) {
                if (average > 0 && (!highestAverage || average > highestAverage.average)) {
                    highestAverage = { personId: Number(personId), average };
                }
            }
        }

        // Return the result with the highest average similarity
        if (highestAverage) {
            const person = people.getPersonById(highestAverage.personId);
            if (person)
                return { similarity: highestAverage.average, 
                        count: similarityScores[highestAverage.personId].count, 
                        person: person };
        }

        return null;
    }

    /**
     * Setup a menu with a lsit of names matching the current search embedding
     */
    async function peopleSearchFace(pSearch: PersonFaceData | null) {
        const headlineBefore = { isHeadline: true, text: 'Face Matches', object: null } as PeopleSearchMenuItem;

        peopleFindMenu.value = [];

        if (pSearch) {
            const persons = peopleFindByFace(pSearch);
            const personItems = persons.map(person => ({
                isHeadline: false,
                text: '',
                object: person
            } as PeopleSearchMenuItem));

            // Add headline before the person items
            peopleFindMenu.value = [headlineBefore, ...personItems];
        }
    }

    /**
     * 
     */
    async function peopleSearchInput(pSearchValue: string) {
        peopleAvailable.value = peopleFindMenu.value;

        if (pSearchValue.length < 2) {
            return;
        }

        peopleAvailable.value = [...peopleAvailable.value, ...people.people];
    }

    return {
        personIsMenuItem: isMenuItem,
        personGetName,
        peopleSearchInput,
        peopleSearch,
        peopleAvailable,
        peopleSearchFace,
        peopleFindByFace,
        peopleFindHighMatch
    };
}
<script setup lang="ts">
import { PropType, onMounted, onUnmounted, ref, watch } from 'vue'
import { useDisplay } from 'vuetify';
import { cItem, ThumbnailSize } from '../domain/item';
import { useItemStore } from '../stores/useItemStore';
import { useSettingsStore } from '../stores/useSettingsStore';
import PanelItem from '../components/PanelItem.vue';
import ItemDate from '../components/item/ItemDate.vue';
import ItemKeywords from '../components/item/ItemKeywords.vue';
import ItemCategory from '../components/item/ItemCategory.vue';
import ItemPeople from '../components/item/ItemPeople.vue';
import ItemRotateButtons from '../components/item/Buttons/ItemRotateButtons.vue';
import PluginRender from '../components/PluginRender.vue';

const items = useItemStore();
const settings = useSettingsStore();

const item = ref(new cItem());

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: false
    },
    itemid: {
        type: String,
        required: false
    },
    shared: {
        type: String,
        default: "",
        required: false
    },
    show: {
        type: Boolean,
        default: true,
        required: false
    }
});

const emit = defineEmits<{
    (e: 'closed'): void
}>();

const visible = ref(true);
const overlayVisible = ref(true);

watch(props, () => {
    itemLoad();
    visible.value = props.show;
    settings.setDialogOpen(visible.value);

}, { immediate: true });

async function itemLoad() {
    if (props.item !== undefined) {
        item.value = props.item;
    } else {
        if (props.itemid != undefined) {
            item.value = await items.getByID(Number(props.itemid));
        }
    }
}

function calcImageHeight(): number {
    const { name, height } = useDisplay();
    return Math.floor(((height.value) - settings.getHeaderSize * 3));
}

const dialogClosed = () => {
    emit('closed');
};

function videoEnded() {

}

/**
 * Manage key presses
 */
function keypress(event: KeyboardEvent) {
    if (!visible.value) {
        return;
    }

    if (event.defaultPrevented) {
        return;
    }

    switch (event.key) {
        default:
            return;

        case "Left":
        case "ArrowLeft":
            items.selectPreviousItem();
            break;

        case "Right":
        case "ArrowRight":
            items.selectNextItem();
            break;
    }

    event.preventDefault();
}

onMounted(() => {
    document.addEventListener("keydown", keypress, { capture: true });
});

onUnmounted(() => {
    document.removeEventListener("keydown", keypress, { capture: true })
});

function toggleOverlay() {
    if (props.item?.isImage())
        overlayVisible.value = !overlayVisible.value
}

</script>

<template>
    <v-dialog transition="dialog-bottom-transition" v-model="visible" @update:model-value="dialogClosed">
        <v-card>
            <v-toolbar density="compact">
                <ItemRotateButtons :item="item"></ItemRotateButtons>
                <PluginRender render-element="ItemDialog" render-piece="Toolbar" :item="item">
                    <v-spacer></v-spacer>
                </PluginRender>
            </v-toolbar>

            <PanelItem can-zoom :imageThumb="ThumbnailSize.Large" :item="item" :shared="shared" :height="calcImageHeight()"
                :image-height="calcImageHeight()" :cover="false" :showInfoOverlay="false" :is-dialog="true"
                @video-ended="videoEnded" @click.stop="toggleOverlay" :ripple="false">

                <div v-if="overlayVisible == true" class="panel-media text-subtitle-2">

                    <v-list density="compact" class="panel-media-overlay text-subtitle-2 text-medium-emphasis">

                        <v-list-item v-if="item.hasCategory() == true" class="text-capitalize text-secondary"
                            style="position: absolute; right:0;">
                            <ItemCategory variant="text" :item="item"></ItemCategory>
                        </v-list-item>

                        <v-list-item prepend-icon="mdi-calendar-range" class="text-h6 text-high-emphasis float-right"
                            style="position: absolute; bottom: 0; right:0">
                            <ItemDate :item="item"></ItemDate>
                        </v-list-item>

                        <v-list-item prepend-icon="mdi-key-variant" style="">
                            <ItemKeywords :item="item"></ItemKeywords>
                        </v-list-item>

                        <v-list-item prepend-icon="mdi-hiking" style="">
                            <ItemPeople :item="item"></ItemPeople>
                        </v-list-item>
                    </v-list>

                </div>
            </PanelItem>


        </v-card>
    </v-dialog>
</template>

<style scoped>
.panel-media-overlay {
    height: 90px;
    overflow-y: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.6) !important;
}
</style>../stores/useItemStore
import { ref } from "vue";

export function useDialogHandling() {
  const dialogStart = ref(false);
  const dialogSaving = ref(false);

  function showStartDialog(selectedItemsSize: number) {
    if (selectedItemsSize === 0) return;
    dialogStart.value = true;
  }

  function closeStartDialog() {
    dialogStart.value = false;
  }

  function showSavingDialog() {
    dialogSaving.value = true;
  }

  function closeSavingDialog() {
    dialogSaving.value = false;
  }

  return {
    dialogStart,
    dialogSaving,
    showStartDialog,
    closeStartDialog,
    showSavingDialog,
    closeSavingDialog,
  };
}

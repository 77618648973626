import { ItemObjectInterface, ObjectInterface, ObjectSearchMenuItem } from "rundown-common";
import { useObjectsStore } from "../stores/useObjectsStore";
import { cObject } from "../domain/Object";
import { cItemObject } from "../domain/ItemObject";
import { ref } from "vue";

export function useObjectSearch() {
    const objects = useObjectsStore();

    const objectSearch = ref('');
    const objectsAvailable = ref<Array<ObjectSearchMenuItem | ObjectInterface | ItemObjectInterface>>([]);

    function isMenuItem(pItem: any): pItem is ObjectSearchMenuItem {
        return pItem && (typeof pItem.isHeadline === 'boolean') && (typeof pItem.object !== 'undefined');
    }

    function objectGetName(pItem: string | ObjectSearchMenuItem | ObjectInterface | ItemObjectInterface): string | ObjectInterface | ItemObjectInterface {
        if (isMenuItem(pItem)) {
            if (pItem.text.length)
                return pItem.text;

            return pItem.object?.getSearchName() ?? '';
        }

        if (pItem instanceof cObject) {
            return pItem.getSearchName();
        }

        if (pItem instanceof cItemObject) {
            if (!pItem.object.object)
                return '';

            return pItem.getObject()?.getSearchName();
        }

        return pItem;
    }

    async function objectSearchInput(pSearchValue: string) {
        objectsAvailable.value = [];

        if (pSearchValue.length < 2) {
            return;
        }

        objectsAvailable.value = objects.objects;
    }

    return {
        objectIsMenuitem: isMenuItem,
        objectSearch,
        objectsAvailable,
        objectGetName,
        objectSearchInput
    }
}
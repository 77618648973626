<script setup lang="ts">
import { useItemMissingStore } from '../../stores/useItemMissingStore';
import { useItemMissingSlidingWindowStore } from '../../stores/useItemMissingSlidingWindowStore';
import ItemCleanupCard from './Item/AdminItemCleanupCard.vue';
import { cItem } from '../../domain/item';

const items = useItemMissingStore();
const itemWindow = useItemMissingSlidingWindowStore();

const handleRemoveItem = (item: cItem) => {
  items.remove(item);
};

const handleRemoveAllItems = () => {
  items.removeAll();
};
</script>

<template>
  <ItemCleanupCard
    description="A missing item indicates the file is no longer available on the filesystem"
    noitemsMsg="No missing items"
    :itemStore="items"
    :itemWindowStore="itemWindow"
    @removeItem="handleRemoveItem"
    @removeAllItems="handleRemoveAllItems"
  />
</template>

<script setup lang="ts">
import { useAuthStore } from '../../stores/useAuthStore';

const auth = useAuthStore();

</script>

<template>

    <v-list density="compact" nav>
        <v-list-subheader prepend-icon="mdi-image-multiple">
            <v-list-item-title>Main Menu</v-list-item-title>
        </v-list-subheader>

        <template v-for="(item, i) in $router.options.routes.values()">
            <template v-if="!item.name || item.meta?.hide">
            </template>

            <template v-else-if="item.meta?.requiresAuth && !auth.isAuthed">
            </template>

            <template v-else-if="item.meta?.requiresGlobalAdmin && !auth.isGlobalAdmin">
            </template>

            <template v-else-if="!item.children">
                <v-list-item :prepend-icon="item.meta?.icon" :to="{ name: item.name }" >
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
            </template>

            <template v-else>
                <v-list-subheader :prepend-icon="item.meta?.icon">
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-subheader>

                <template v-for="(itemchild, x) in item.children">
                    <template v-if="!itemchild.name || itemchild.meta?.hide">
                    </template>
                    <template v-else-if="itemchild.meta?.requiresGlobalAdmin && !auth.isGlobalAdmin">
                    </template>
                    <template v-else-if="itemchild.meta?.requiresAuth && !auth.isAuthed">
                    </template>
                    <template v-else>
                        <v-list-item :prepend-icon="itemchild.meta?.icon" :to="{ name: itemchild.name }">
                            <v-list-item-title>{{ itemchild.name }}</v-list-item-title>
                        </v-list-item>
                    </template>
                </template>

            </template>

        </template>

    </v-list>
</template>
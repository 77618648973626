<script setup lang="ts">
import { ref } from 'vue';
import { useItemStore } from '../../../stores/useItemStore';

const items = useItemStore();
const confirmDialogVisible = ref(false);

const removeItem = async () => {
    items.getSelectedItem.delete();
    items.remove(items.getSelectedItem.getId());
}

</script>
<template>
    <v-btn icon="mdi-trash-can" color="red" title="Delete Item" @click="confirmDialogVisible = true"></v-btn>

    <v-dialog v-model="confirmDialogVisible" persistent width="auto">
        <v-card>
            <v-card-title class="text-h5">
                Delete Item
            </v-card-title>
            <v-card-text>Are you sure you wish to delete this item</v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="green-darken-1" variant="text" @click="confirmDialogVisible = false">
                    Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn color="green-darken-1" variant="text" @click="removeItem(); confirmDialogVisible = false; ">
                    Delete It
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
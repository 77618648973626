<script setup lang="ts">
import ItemMap from '../components/map/ItemMap.vue';
import { onMounted, onUnmounted, ref } from 'vue';
import { useSettingsStore } from '../stores/useSettingsStore';
import { cItem } from '../domain/item';
import { useItemStore } from '../stores/useItemStore';

const settings = useSettingsStore();
const itemstore = useItemStore();

const item = ref(new cItem());
const props = defineProps({

    itemid: {
        type: String,
        required: false,
        default: "0"
    }
});

onMounted(async () => {
    if(props.itemid.length && props.itemid !== '0') {
        item.value = await itemstore.getByID(Number(props.itemid));
    }
});

onUnmounted(async () => {
    settings.clearSelectedItem();
});

</script>

<template>
    <ItemMap :zoom-to-item="item">
    </ItemMap>
</template>
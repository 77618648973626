export function timeUntil(targetDate: Date): string {
    const now = new Date();
    const differenceInMs = targetDate.getTime() - now.getTime();

    if (differenceInMs <= 0) return "Today";

    const seconds = Math.floor(differenceInMs / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) return `in ${days} days`;
    if (days === 1) return "in 1 day";
    //if (hours > 1) return `in ${hours} hours`;
    //if (hours === 1) return "in 1 hour";
    //if (minutes > 1) return `in ${minutes} minutes`;
    //if (minutes === 1) return "in 1 minute";
    return `Today`;
}

<script setup lang="ts">
import { ref } from 'vue';
import { cItem } from '../domain/item';
import { useSettingsStore } from '../stores/useSettingsStore';
import { useItemStore } from '../stores/useItemStore';
import PanelItem from '../components/PanelItem.vue';
import PanelMediaOverlay from '../components/PanelMediaOverlay.vue';

const settings = useSettingsStore();
const props = defineProps({
    items: {
        type: Array<cItem>,
        required: true
    }
});

const emit = defineEmits<{
    (e: 'item-click', pItem: cItem): void
}>();


</script>

<template>
    <v-row no-gutters>
        <v-col v-for="(item, i) in items" :key="item.getId" :cols="Math.floor(12 / settings.getItemsPerRow)">
            <v-hover v-slot="{ isHovering, props }">
                <v-item :value="item.getId">
                    <PanelItem v-bind="props" class="ma-1" rounded="lg" :item="item" :imageHeight="settings.getImageSize"
                        :imageThumb="settings.getImageThumb" :min-height="settings.getImageCardSize"
                        @click.stop="emit('item-click', item);">

                        <PanelMediaOverlay :item="item" :isHovering="isHovering || false" />

                    </PanelItem>
                </v-item>
            </v-hover>
        </v-col>
    </v-row>
</template>../stores/useItemStore
import { defineStore } from "pinia";
import axios from "axios";
import { useMessageStore } from "./useMessageStore";
import {
  ActionMessageInterface,
  ObjectData,
  ObjectInterface,
  PropertyData,
} from "rundown-common";
import { cObject } from "../domain/Object";
import { Ref, computed, ref } from "vue";
import { useWebSocketStore } from "./useWebSocketStore";
import { PublicationContext } from "centrifuge";

export const useObjectsStore = defineStore("objects", () => {
  const objects: Ref<Array<ObjectInterface>> = ref([]);
  const names: Ref<Array<string>> = ref([]);

  const loading = ref(false);

  const get = computed(() => objects.value);
  const getNames = computed(() => names.value);

  const isLoading = computed(() => loading.value);

  const load = async (): Promise<void> => {
    const messages = useMessageStore();

    try {
      const response = await axios.get("/v1/object");
      objects.value = response.data.map(
        (pObjectData: ObjectData) => new cObject(pObjectData)
      );
    } catch (error) {
      messages.handleAxiosError(error, "Failed to load objects");
    }
  };

  /**
   * Properties
   */
  const loadPropertyNames = async (): Promise<void> => {
    const messages = useMessageStore();

    try {
      const response = await axios.get("/v1/object/properties");
      names.value = response.data;
    } catch (error) {
      messages.handleAxiosError(error, "Failed to load property names");
    }
  };

  const loadAll = async (): Promise<void> => {
    await load();
    await loadPropertyNames();
  };

  /**
   * Handle a websocket message for a collection
   */
  const websocketMessage = async (pData: PublicationContext) => {
    const messages = useMessageStore();
    var parsed = pData.data as ActionMessageInterface;

    // Update the collection
    if (parsed.object !== null) {
      const obj = objects.value.find(
        (object: ObjectInterface) => object.getId() === parsed.object?.id
      );

      if (obj) {
        obj.loadFrom(parsed.object);
        return;
      }

      let object = new cObject(parsed.object as ObjectData);
      objects.value.push(object);
    }

    messages.processAction(parsed);
  };

  const getObjectById = (pId: number): ObjectInterface => {
    const object = objects.value.find(
      (object: ObjectInterface) => object.getId() === pId
    );

    if (object === undefined) {
      throw new Error("Object not found");
    }
    return object;
  };

  const createObject = async (
    pName: string,
    pDescription: string,
    isObject: cObject | null
  ) => {
    const data = {
      name: pName,
      description: pDescription,
      isObjectId: isObject?.getId() ?? null,
    } as ObjectData;

    const response = await axios.post("/v1/object", data);
    const newObject = new cObject(response.data);

    objects.value.push(newObject);

    return newObject;
  };

  const updateObject = async (pObject: cObject) => {
    await axios.put(`/v1/object/${pObject.getId()}`, pObject.object);
  };

  const addProperty = async (pObject: cObject, pProperty: PropertyData) => {
    const data = await axios.post(
      `/v1/object/${pObject.getId()}/property`,
      pProperty
    );

    pObject.object.properties.push(data.data);

    if (!names.value.includes(pProperty.name)) {
      names.value.push(pProperty.name);
    }

    return data.data;
  };

  const deleteProperty = async (pObject: cObject, pProperty: PropertyData) => {
    await axios.delete(
      `/v1/object/${pObject.getId()}/property/${pProperty.id}`
    );

    pObject.object.properties = pObject.object.properties.filter(
      (pProp) => pProp.id !== pProperty.id
    );
  };

  return {
    objects,
    get,
    getNames,
    isLoading,

    loadAll,
    websocketMessage,

    getObjectById,
    createObject,
    updateObject,
    addProperty,
    deleteProperty,
  };
});

import { defineStore } from "pinia";

import { useItemStore } from "./useItemStore";
import { useCollectionStore } from "./useCollectionStore";
import { useAuthStore } from "./useAuthStore";

export const useHelpStore = defineStore("help", {
  state: () => ({
    page: 0,
  }),

  getters: {
    noItems: (state) => {
      return useItemStore().getTotalRecords == 0;
    },

    noCollections: (state) => {
      return useCollectionStore().getCollections.length <= 1;
    },

    displaySignIn: (state) => {
      if (useItemStore().isLoading) return false;

      var noCollections = useCollectionStore().getCollections.length <= 1;
      var noItems = useItemStore().getTotalRecords == 0;

      if (useAuthStore().isAuthed || useAuthStore().hasDialog) {
        return false;
      }

      if (noCollections && noItems) return true;

      return false;
    },

    displayCreate: (state) => {
      if (useItemStore().isLoading) return false;

      if (useAuthStore().isAuthed && !useAuthStore().hasDialog) {
        var noCollections = useCollectionStore().getCollections.length <= 1;

        if (noCollections) return true;
      }

      return false;
    },

    displayNoItems: (state) => {
      if (useItemStore().isLoading) return false;

      var noItems = useItemStore().getTotalRecords == 0;

      return noItems;
    },
  },

  actions: {},
});

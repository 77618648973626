import { ItemData, ItemPageData } from 'rundown-common';
import Pagination, { PageLinks, PageMetaData } from '../services/Pagination';
import { ArrayMap } from './arraymap';
import { cItem } from '../domain/item';

import { useFilterStore } from '../stores/useFilterStore';
import { useMessageStore } from '../stores/useMessageStore';

import axios from 'axios';
import { Ref, ref } from 'vue';

export type ItemMap = ArrayMap<number, cItem>;

export enum ItemStatus {
    Normal = 'normal',
    Missing = 'missing',
    Recycled = 'recycled',
}

/**
 * Low level management of item pages
 */
export class ItemPageManager {

    private pageData = {
        _meta: { total_records: 0, limit: 10 } as PageMetaData,
        _links: [] as PageLinks,
        items: [] as Array<ItemData>
    } as ItemPageData;

    private collectionId = ref(-1);
    private totalPages = ref(0);
    private totalRecords = ref(0);
    private resultsPerPage = ref(0);
    private reloadCounter = ref(0);
    private refreshCounter = ref(0);

    private pages: Ref<Map<number, ItemMap>> = ref(new Map());

    private loadingTimeout: number = 0;
    private loading: Ref<boolean> = ref(false);
    private itemsPerPage: number = 0;

    private itemStatus: ItemStatus;

    private reloadTimer: number | null = null;

    /**
     *
     */
    constructor(pItemStatus: ItemStatus = ItemStatus.Normal, pItemsPerPage: number = 10) {
        this.itemStatus = pItemStatus;
        this.itemsPerPage = pItemsPerPage;
    }

    /**
     * Is a page currently loading
     */
    isLoading(): boolean {
        return this.loading.value;
    }

    /**
     * Get
     */
    getReloadCounter(): number {
        return this.reloadCounter.value;
    }

    getRefreshCounter(): number {
        return this.refreshCounter.value;
    }

    /**
     * Get the number of results per page
     */
    getResultsPerPage(): number {
        return this.resultsPerPage.value;
    }

    /**
     * Get the total number of pages
     */
    private getTotalPages(): number {
        return this.totalPages.value;
    }

    /**
     * Get the total number of records
     */
    getTotalRecords(): number {
        return this.totalRecords.value;
    }

    getCollectionId(): number {
        return this.collectionId.value;
    }

    /**
     * Get a range of items starting at pIndex
     */
    async getRange(pIndex: number, pCount: number) {
        const target: Array<cItem> = [];
        const itemsPerPage = this.getResultsPerPage();
        const totalPages = this.getTotalPages();
    
        // Calculate the starting page and initial item index
        let startPage = Math.ceil((pIndex + 1) / itemsPerPage);
        let remainingItems = pCount;
    
        for (let page = startPage; page <= totalPages && remainingItems > 0; page++) {
            const pageItems = await this.getPage(page);
            if (!pageItems) continue;
    
            // Calculate the starting index for this page
            let startIndex = page === startPage ? (pIndex) % itemsPerPage : 0;
            
            // Fetch items from the current page based on availability
            for (let i = startIndex; i < itemsPerPage && remainingItems > 0; i++) {
                const item = pageItems.getByIndex(i);
                if (item) {
                    target.push(item);
                    remainingItems--;
                }
            }
        }
        return target;
    }

    /**
     * Get a specific page
     */
    private async getPage(pNumber: number): Promise<ItemMap | undefined> {
        if (pNumber < 1 || pNumber > this.getTotalPages()) {
            return undefined;
        }
        await this.loadPage(pNumber);
        return this.pages.value.get(pNumber) ?? new ArrayMap<number, cItem>();
    }

    /**
     * Load pages in front and behind the current page
     */
    private async loadPages(pPageNumber: number): Promise<void> {
        if (pPageNumber < 1 || pPageNumber > this.getTotalPages()) {
            return undefined;
        }

        await this.loadPage(pPageNumber);

        const totalPages = this.getTotalPages();
        const prevPage = pPageNumber > 1 ? pPageNumber - 1 : totalPages;
        const nextPage = pPageNumber < totalPages ? pPageNumber + 1 : 1;
        await Promise.all([this.loadPage(prevPage), this.loadPage(nextPage)]);
    }

    /**
     * Load a page
     */
    private async loadPage(pageNumber: number, pForcePageLoad: boolean = false): Promise<void> {

        // Invalid pagenumber
        if (pageNumber < 1 || pageNumber > this.getTotalPages()) {
            return;
        }

        // If the page is already loaded
        if(pForcePageLoad == false && this.pages.value.has(pageNumber)) {
            return;
        }

        this.setLoading(true);
        try {
            const response = await Pagination.getPage(this.pageData._links, pageNumber);
            this.pages.value.set(pageNumber, this.processPageData(response.data));
        } catch (error) {
            console.error(`Error loading page ${pageNumber}:`, error);
        } finally {
            this.setLoading(false);
        }
    }

    /**
     * Load the next page which can be loaded
     */
    async loadNextPage(): Promise<boolean> {
        const totalPages = this.getTotalPages();
    
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            if (!this.pages.value.has(pageNumber)) {
                await this.loadPage(pageNumber);
                return true;
            }
        }

        return false;
    }

    /**
     * Load the first page/setup the paging info
     */
    async load() {
        const filters = useFilterStore();
        const messages = useMessageStore();
        const baseUrl = '/v1/item/';
        const collectionId = filters.getCollectionId;
        const limitParam = `limit=${this.itemsPerPage}`;
        
        let endpoint: string;
        switch (this.itemStatus) {
            case ItemStatus.Missing:
                endpoint = `missing/${collectionId}?${limitParam}`;
                break;
            case ItemStatus.Recycled:
                endpoint = `deleted/${collectionId}?${limitParam}`;
                break;
            default:
                endpoint = `list/${collectionId}?${filters.getParameters}&${limitParam}`;
                break;
        }
    
        this.setLoading(true);
        this.collectionId.value = collectionId;
    
        try {
            const response = await axios.get(baseUrl + endpoint);
            this.pages.value.clear();
            this.pages.value.set(1, this.processPageData(response.data));
    
            this.reloadCounter.value++;
            await this.loadPages(1);
        } catch (error) {
            messages.handleAxiosError(error, 'Failed to load items');
        } finally {
            this.setLoading(false);
        }
    }
    

    /**
     * Turn each in a page of results into a cItem and return a new ArrayMap containing them
     */
    private processPageData(pPageData: ItemPageData): ArrayMap<number, cItem> {
        var pageItems = new ArrayMap<number, cItem>();

        this.pageData = pPageData;

        this.totalRecords.value = this.pageData._meta.total_records;
        this.totalPages.value = Math.ceil(this.pageData._meta.total_records / (this.pageData._meta.limit ?? 1));
        this.resultsPerPage.value = this.pageData._meta.limit ?? 0;

        this.pageData.items.forEach((entry: ItemData) => {
            var item = new cItem(entry);
            pageItems.set(item.getId(), item);
        });

        return pageItems;
    }

    /**
     * Reload all loaded pages
     */
    async reload(): Promise<void> {

        if (this.reloadTimer) {
            clearTimeout(this.reloadTimer);
        }

        this.reloadTimer = setTimeout(async () => {
            const loadedPages = Array.from(this.pages.value.keys());
            for (const pageNumber of loadedPages) {
                await this.loadPage(pageNumber, true);
            }
        }, 20000);
    }

    /**
     * Get a loaded item
     */
    getItem(pItemID: number): cItem | undefined {
        const page = this.getPageWithItem(pItemID);
        return page?.getByKey(pItemID);
    }


    /**
     * Remove a loaded item and shift items from subsequent pages up to fill the gap
     */
    async removeItem(pItemID: number): Promise<void> {
        const page = this.getPageWithItem(pItemID);
        if (!page) return;

        var item = this.getItem(pItemID);
        page.delete(pItemID);
        ++this.refreshCounter.value;
    }

    /**
     * Remove a page and shift subsequent pages up
     */
    removePage(pPage: ItemMap): void {
        for (const [pageNumber, page] of this.pages.value.entries()) {
            if (pPage === page) {
                this.pages.value.delete(pageNumber);
                this.totalPages.value--;

                // Shift all subsequent pages up by one
                for (let i = pageNumber + 1; i <= this.getTotalPages() + 1; i++) {
                    const nextPage = this.pages.value.get(i);
                    if (nextPage) {
                        this.pages.value.set(i - 1, nextPage);
                        this.pages.value.delete(i);
                    }
                }

                break;
            }
        }
    }
    
    /**
     * Get the page number that contains this itemid
     */
    getPageNumberWithItem(pItemID: number): number | undefined {
        for (const [key, page] of this.pages.value.entries()) {
            if (page.hasKey(pItemID)) {
                return key;
            }
        }
        return undefined;
    }


    /**
     * Get the page with this item
     */
    private getPageWithItem(pItemID: number): ItemMap | undefined {

        for (const pPage of this.pages.value.values()) {

            if (pPage.hasKey(pItemID))
                return pPage as ItemMap;
        }

        return undefined;
    }

    /**
     * Get an item by its global index
     */
    async getItemByIndex(globalIndex: number): Promise<cItem | undefined> {
        const itemsPerPage = this.getResultsPerPage();
        const pageNumber = Math.ceil((globalIndex + 1) / itemsPerPage);
        const page = await this.getPage(pageNumber);

        if (!page) {
            return undefined;
        }

        const indexInPage = globalIndex % itemsPerPage;
        return page.getByIndex(indexInPage);
    }

    /**
     * Get the global index of a cItem
     */
    getIndexOfItem(targetItem: cItem): number {
        return this.getIndexOfItemId(targetItem.getId());
    }

    /**
     * Get the global index of an item by its id
     */
    getIndexOfItemId = (targetItem: number): number => {
        const itemsPerPage = this.getResultsPerPage();

        for (const [pageNumber, page] of this.pages.value.entries()) {
            if (page.hasKey(targetItem)) {
                const indexInPage = page.getIndexByKey(targetItem);
                if (indexInPage === undefined)
                    break;

                return ((pageNumber - 1) * itemsPerPage) + indexInPage;
            }
        }

        return -1;
    }

    /**
     * Adjust the number of items loaded per page
     */
    async setItemsPerPage(pItemsPerPage: number): Promise<void> {
        if(this.itemsPerPage != pItemsPerPage) {
            this.itemsPerPage = pItemsPerPage;
            await this.load();
        }
    }
    
    /**
     * Toggles the loading flag with a delay when disabling.
     */
    private setLoading(isLoading: boolean): void {
        if (this.loadingTimeout) {
            clearTimeout(this.loadingTimeout);
        }

        if (isLoading) {
            this.loading.value = true;
        } else {
            this.loadingTimeout = setTimeout(() => {
                this.loading.value = false;
            }, 500);
        }
    }

}

<script setup lang="ts">
import { onActivated, onMounted, onUnmounted, ref, watch, computed, watchEffect } from 'vue'
import { useSettingsStore } from '../stores/useSettingsStore';
import { cItem } from '../domain/item';
import { useItemSlidingWindowStore } from '../stores/useItemSlidingWindowStore';
import ItemPanelRow from './itemPanelRow.vue';
import { useFilterStore } from '../stores/useFilterStore';
import { useItemStore } from '../stores/useItemStore';

const settings = useSettingsStore();
const itemstore = useItemStore();
const items = useItemSlidingWindowStore();

const visibleItems = ref(new Map<number, cItem>());
const formattedVisibleItems = computed(()=> Array.from(visibleItems.value.values()));
const reloadCounter = computed(() => itemstore.reloadCounter);

const firstLoad = ref(false);
const scrollContainer = ref(null);

type InfiniteScrollStatus = 'empty' | 'ok';

watch([reloadCounter], () => {
    visibleItems.value.clear();
    scrollToTop();
});

watchEffect(() => {
    firstLoad.value = true;

    items.getItems.forEach(item => {
        if (!visibleItems.value.has(item.getId())) {
            visibleItems.value.set(item.getId(), item);
        }
    });
});

function scrollToTop() {
    window.scrollTo(0, 0);
}

/**
 * Scroller load callback
 */
async function load({ done }: { done: (status: InfiniteScrollStatus) => void }) {
    await items.moveWindowDown()

    if (visibleItems.value.size == items.totalItems && firstLoad.value) {
        done('empty');
        return;
    }
    done('ok');
}

onActivated(() => {


})
onMounted(async () => {
    items.currentPosition = 0;
    items.windowSize = (settings.getItemsPerPage * settings.getItemsPerRow) * 4;
});

onUnmounted(() => {

});


</script>

<template>
    <v-container>

        <v-item-group ref="scrollContainer">

            <v-infinite-scroll :onLoad="load" :margin="settings.getAvailableHeight * 2">
                <ItemPanelRow :items="formattedVisibleItems"></ItemPanelRow>
            </v-infinite-scroll>

        </v-item-group>

    </v-container>
</template>

../stores/useItemStore
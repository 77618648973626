<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { useScreenSaverStore, iScreenSaver } from '../../stores/useScreenSaverStore';
import { useTokenStore, iToken } from '../../stores/useTokenStore';

const tokens = useTokenStore(); const screens = useScreenSaverStore();

const props = defineProps({
    screensaver: {
        type: Object as PropType<iScreenSaver>,
        required: false
    },
});

async function saveRandom() {
    if (props.screensaver == undefined || props.screensaver == null)
        return;

    await screens.save(props.screensaver, !props.screensaver.random);
    props.screensaver.random = !props.screensaver.random;
}

async function saveSeconds() {
    if (props.screensaver == undefined || props.screensaver == null)
        return;

    await screens.save(props.screensaver, props.screensaver.random);
}

async function setFilters() {
    if (props.screensaver == undefined)
        return;

    props.screensaver.filters = (await screens.updateFilters(props.screensaver)).filters;
}

onMounted(async function () {
    tokens.load();
    await screens.load();
});

</script>

<template>
    <v-container>
        <v-card title="Filter Settings">
            <template v-if="screensaver?.id != undefined">
                <v-card-title>
                    <v-tooltip text="Set the screen saver filters to your current filters">
                        <template v-slot:activator="{ props }">

                            <v-btn v-bind="props" prepend-icon="mdi-cog-transfer" color="purple" @click="setFilters">Set from
                                Active Filters</v-btn>

                        </template>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>

                    <v-table fixed-header class="mt-10" density="compact">
                        <tbody>
                            <tr>
                                <th><strong>Setting</strong></th>
                                <th><strong>Value</strong></th>
                            </tr>
                            <tr>
                                <th><strong>Randomize</strong></th>
                                <td><v-switch inset density="compact" color="primary" :model-value="screensaver.random"
                                        @click="saveRandom" label=""></v-switch></td>
                            </tr>
                            <tr>
                                <td><strong>Seconds per picture</strong></td>
                                <td><v-col cols="4">
                                        <v-slider min=2 max=60 step=1 v-model="screensaver.seconds" thumb-label="always"
                                            @click="saveSeconds"></v-slider>
                                    </v-col></td>
                            </tr>
                            <tr v-for="object, key in screensaver.filters">
                                <th><strong>{{ key }}</strong></th>
                                <td>{{ object }}</td>
                            </tr>

                        </tbody>

                    </v-table>
                </v-card-text>
            </template>
            <template v-else>
                <v-card-text class="text-center">
                    No screen saver selected
                </v-card-text>
            </template>
        </v-card>
    </v-container>
</template>
import { ref } from 'vue';
import { cItem } from '../domain/item';
import { ItemManagerInterface } from './ItemManagerInterface';
import { useItemSelection } from './useItemSelection';
import { useItemNavigation } from './useItemNavigation';
import { ItemManager } from './ItemManager';
import { useItemSlidingWindowStore } from '../stores/useItemSlidingWindowStore';
import { useItemPagingStore } from '../stores/useItemPagingStore';

/**
 * A custom hook for item selection and navigation within a paginated list.
 */
export function useItemSelectionAndNavigation(store: ReturnType<typeof useItemSlidingWindowStore|typeof useItemPagingStore>) {
    const itemSelection = useItemSelection(store);
    const itemNavigation = useItemNavigation(itemSelection.selectedItems.value);

    return { itemSelection, itemNavigation };
};

<script setup lang="ts">
import PanelItem from '../../components/PanelItem.vue'
import { useSettingsStore } from '../../stores/useSettingsStore';
import { ThumbnailSize, cItem } from '../../domain/item';
import { PropType } from 'vue';
import { usePeopleStore } from '../../stores/usePeopleStore';
import { iScreenSaver } from '../../stores/useScreenSaverStore';
import { useDisplay } from 'vuetify/lib/framework.mjs';
import ItemDate from '../item/ItemDate.vue';
import ItemKeywords from '../item/ItemKeywords.vue';
import ItemCategory from '../item/ItemCategory.vue';
import ItemPeople from '../item/ItemPeople.vue';

const settings = useSettingsStore();
const people = usePeopleStore();

const props = defineProps({
    item: {
        type: [Object, null] as PropType<cItem | null>,
        required: true,
        default: () => null
    },
    screensaver: {
        type: Object as PropType<iScreenSaver>,
        required: false
    }
});

const emit = defineEmits<{
    (e: 'fullscreen-mode-change', pNewState: boolean): void
    (e: 'video-ended'): void
}>();

function change(pNewState: boolean) {
    emit('fullscreen-mode-change', pNewState);
}
function calcImageHeight(): number {
    const { name, height } = useDisplay();
    return Math.floor(((height.value) - settings.getHeaderSize));
}

function videoEnded() {
    emit('video-ended');
}

</script>

<template>
    <fullscreen v-model:fullscreen="settings.$state.fullscreen" @change="change">
        <v-card v-show="settings.$state.fullscreen == true">
            <v-toolbar dark color="primary" density="compact">
                <v-btn icon dark @click="settings.$state.fullscreen = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-img max-width="40px" src="/android-chrome-512x512.png"></v-img>

                <v-toolbar-title>RunDown</v-toolbar-title>

                <v-icon v-if="(screensaver?.filters?.filtergroups.length ?? 0) > 0" icon="mdi-magnify"></v-icon>
                <template v-for="term in screensaver?.filters?.filtergroups">
                    <v-chip v-for="filter in term.getKeywords()" class="ma-1" variant="outlined" color="white" label>
                        {{ filter.id() }}
                    </v-chip>

                    <v-chip v-for="person in term.getPeople()" class="ma-1" variant="outlined" color="white" label>
                        {{ person.getName().name }}
                    </v-chip>
                </template>

                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-btn variant="text" @click="settings.$state.fullscreen = false">
                        Close
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <template v-if="item != null">
                <PanelItem :imageThumb="ThumbnailSize.Large" :item="item" :height="calcImageHeight()"
                    :image-height="calcImageHeight()" :cover=false show-info-overlay :is-dialog="true"
                    @video-ended="videoEnded" :ripple="false">

                    <div class="panel-media text-subtitle-2">

                        <v-list density="compact" class="panel-media-overlay text-subtitle-2 text-medium-emphasis">

                            <v-list-item v-if="item.hasCategory() == true" class="text-capitalize"
                                style="position: absolute; right:0;">
                                <ItemCategory variant="text" :item="item"></ItemCategory>
                            </v-list-item>

                            <v-list-item prepend-icon="mdi-calendar-range" class="text-h6 text-high-emphasis float-right"
                                style="position: absolute; bottom: 0; right:0">
                                <ItemDate :item="item"></ItemDate>
                            </v-list-item>


                            <v-list-item prepend-icon="mdi-key-variant" style="">
                                <ItemKeywords :item="item"></ItemKeywords>
                            </v-list-item>

                            <v-list-item prepend-icon="mdi-hiking" style="">
                                <ItemPeople :item="item"></ItemPeople>
                            </v-list-item>
                        </v-list>

                    </div>
                </PanelItem>
            </template>
        </v-card>
    </fullscreen>
</template>

<style >

.panel-media {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.8);
}
.panel-media-overlay {
    height: 90px;
    overflow-y: hidden;
    width: 100%;
    background: rgba(0, 0, 0, 0.6) !important;
}
</style>
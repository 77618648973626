<script setup lang="ts">

import NavMenuList from './appbar/NavMenuList.vue';
import NavStatus from './appbar/NavStatus.vue';

import FilterKeywordsBox from './appbar/FilterKeywordsBox.vue';
import FilterOptions from './appbar/FilterOptions.vue';
import DynamicToolbar from './appbar/DynamicToolbar.vue';

import { useSettingsStore } from '../stores/useSettingsStore';
import { useRouter } from 'vue-router'

const settings = useSettingsStore();
const router = useRouter();

function home() {
    router.push(router.resolve({name: 'Home'}));
}
</script>

<template>

    <v-app-bar density="compact" extension-height="75">
        <template v-slot:prepend>
            <v-app-bar-nav-icon v-if="settings.isScreenSmall == true"
                @click="settings.setSidebarVisible()"></v-app-bar-nav-icon>
        </template>

        <v-img max-width="40px"  src="/android-chrome-512x512.png" @click="home" style="cursor: pointer;"></v-img>
        <v-app-bar-title class="d-none d-sm-flex">RunDown</v-app-bar-title>
        
        <DynamicToolbar></DynamicToolbar>

        <v-divider class="mx-4" vertical></v-divider>

        <FilterKeywordsBox></FilterKeywordsBox>

        <template class="d-none d-sm-flex">
            <FilterOptions></FilterOptions>
        </template>

        <template v-slot:append>

            <NavStatus></NavStatus>

            <NavMenuList></NavMenuList>
        </template>

    </v-app-bar>

</template>
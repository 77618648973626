<script setup lang="ts">
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useDynamicToolbarSettings, Option as DynamicToolbarOption } from '../../stores/useDynamicToolbarSettings';
import ItemRotateButtons from '../../components/item/Buttons/ItemRotateButtons.vue';
import ItemDeleteButton from '../../components/item/Buttons/ItemDeleteButton.vue';

import { useItemStore } from '../../stores/useItemStore';
const route = useRoute();
const toolbarSettings = useDynamicToolbarSettings();

const isMetadataPage = computed(() => route.path === '/admin/metadata');
const items = useItemStore();

const options = ref<Array<DynamicToolbarOption>>([
    { title: 'Facial Recognition', value: 'FaceRecognition', icon: 'mdi-face-recognition', isActive: false } as DynamicToolbarOption,
    { title: 'Auto Face-Tag', value: 'FaceRecognitionAutoTag', icon: 'mdi-tag', isActive: false } as DynamicToolbarOption,
    { title: 'Confidence Level', value: 'FaceRecognitionConfidence', icon: 'mdi-tune', isActive: false, isSlider: true, sliderValue: 5 } // Set a default slider value here
]);



const selectOption = (option: DynamicToolbarOption) => {
    const newState = !option.isActive;
    option.isActive = newState;
    toolbarSettings.setItemMetaDataOption(option.value, newState);
}


const updateSliderValue = (option: DynamicToolbarOption, value: number) => {
    if (option.isSlider) {
        option.sliderValue = value;
        toolbarSettings.setItemMetaDataValue(option.value, value);
    }
};


// Initialize option states based on toolbar settings
onMounted(() => {
    options.value.forEach(option => {
        option.isActive = toolbarSettings.getItemMetaDataOption(option.value) ?? false;
        if (option.isSlider) {
            option.sliderValue = toolbarSettings.getItemMetaDataValue(option.value) ?? option.sliderValue;
        }
    });
});

</script>

<template>
    <ItemRotateButtons v-if="isMetadataPage" :item="items.getSelectedItem">
    </ItemRotateButtons>
    
    <ItemDeleteButton v-if="isMetadataPage"></ItemDeleteButton>

    <v-menu v-if="isMetadataPage" offset-y :close-on-content-click="false">
        
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" icon="mdi-face-recognition" value="FaceRecognition" title="Facial Recognition"></v-btn>
        </template>

        <v-list>
            <v-list-item v-for="option in options" :key="option.value" @click="selectOption(option)">
                <template v-slot:prepend>
                    <v-icon :icon="option.icon" class="mr-0" ></v-icon>
                </template>

                <v-row no-gutters>
                    <v-col>
                        <v-list-item-title>{{ option.title }}</v-list-item-title>
                    </v-col>

                    <v-col v-if="!option.isSlider" cols="auto">
                        <v-list-item-action start>
                            <v-icon v-if="option.isActive" color="primary" class="ml-5">mdi-check</v-icon>
                            <v-icon v-else color="transparent" class="ml-5">mdi-check</v-icon>
                            <v-checkbox-btn v-model="option.isActive" class="hidden-checkbox" @click.stop></v-checkbox-btn>
                            
                        </v-list-item-action>
                    </v-col>

                </v-row>
                <v-row v-if="option.isSlider"  >
                    <v-slider v-model="option.sliderValue" :min="1" :max="10" step="1" class="ml-4 mr-12" show-ticks="always" tick-size="4" @update:model-value="updateSliderValue(option, $event)"></v-slider>
                    </v-row>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

    
<style scoped>
.hidden-checkbox {
    display: none;
}
</style>
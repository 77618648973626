import 'vue-router'

import { createWebHistory, createRouter, NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import ItemHome from './views/home.vue'

import SingleItem from './views/SingleItem.vue';
import WorldMap from './views/worldmap.vue';
import OnThisDay from './views/onthisday.vue';
import ScreenSaver from './views/screensaver.vue';
import Cart from './views/cart.vue';

import AdminCollections from './views/admin/AdminCollections.vue';
import AdminManage from './views/admin/AdminManage.vue';
import AdminManageMetadata from './views/admin/AdminItemManageMetadata.vue';
import AdminTransferItems from './views/admin/AdminTransferItems.vue';
import AdminManageTags from './views/admin/AdminManageTags.vue';

import TokenRegister from './components/screensaver/token.vue';


import { useAuthStore } from "./stores/useAuthStore";

declare module 'vue-router' {
    interface RouteMeta {
        icon: string,
        requiresAuth: boolean,
        requiresGlobalAdmin: boolean,
        hide: boolean
    }
};

const routes = [
    {
        name: "Home",
        path: "/",
        meta: {
            icon: 'mdi-view-dashboard',
            hide: false,
            requiresAuth: false,
            requiresGlobalAdmin: false
        },
        component: ItemHome
    },
    {
        name: "item",
        path: "/item/:itemid",
        component: SingleItem,
        props: true,
        meta: {
            icon: '',
            hide: true,
            requiresAuth: false,
            requiresGlobalAdmin: false
        }
    },
    {
        name: 'shared',
        path: "/share/:name",
        component: SingleItem,
        meta: {
            icon: '',
            hide: true,
            requiresAuth: false,
            requiresGlobalAdmin: false
        },
        props: true
    },
    {
        name: "On this day",
        path: "/on-this-day",
        meta: {
            icon: 'mdi-calendar-today',
            hide: false,
            requiresAuth: false,
            requiresGlobalAdmin: false
        },
        component: OnThisDay
    },
    {
        name: "Screen Saver",
        path: "/screen-saver",
        meta: {
            icon: 'mdi-monitor-screenshot',
            hide: false,
            requiresAuth: false,
            requiresGlobalAdmin: false
        },
        component: ScreenSaver,
    },
    {
        name: 'Token',
        path: '/screen-saver/token/:token',
        props: true,
        component: TokenRegister,
        meta: {
            icon: '',
            hide: true,
            requiresAuth: true,
            requiresGlobalAdmin: false
        },
        beforeEnter: authGuard
    },
    {
        name: "World Map",
        path: "/world-map/:itemid?",
        component: WorldMap,
        props: true,
        meta: {
            icon: 'mdi-earth',
            hide: false,
            requiresAuth: false,
            requiresGlobalAdmin: false
        }
    },
    {
        name: "Admin",
        path: "/admin",
        meta: {
            icon: 'mdi-forum',
            hide: false,
            requiresAuth: true,
            requiresGlobalAdmin: false
        },
        beforeEnter: authGuard,

        children: [
            {
                name: 'Collections',
                path: 'collections',
                meta: {
                    icon: 'mdi-image-multiple',
                    hide: false,
                    requiresAuth: true,
                    requiresGlobalAdmin: false
                },
                component: AdminCollections,
            },
            {
                name: 'Transfer Items',
                path: 'transfer',
                meta: {
                    icon: 'mdi-transfer',
                    hide: false,
                    requiresAuth: true,
                    requiresGlobalAdmin: false
                },
                component: AdminTransferItems,
            },
            {
                name: 'Manage Tags',
                path: 'tagging',
                meta: {
                    icon: 'mdi-tag-multiple',
                    hide: false,
                    requiresAuth: true,
                    requiresGlobalAdmin: false
                },
                component: AdminManageTags,
            },
            {
                name: 'Item Metadata',
                path: 'metadata',
                meta: {
                    icon: 'mdi-tag',
                    hide: false,
                    requiresAuth: true,
                    requiresGlobalAdmin: false
                },
                component: AdminManageMetadata,
            },
            {
                name: 'Management',
                path: 'manage',
                meta: {
                    icon: 'mdi-recycle',
                    hide: false,
                    requiresAuth: true,
                    requiresGlobalAdmin: false
                },
                component: AdminManage,
            },

        ]
    },
    {
        name: '',
        path: '/:path(.*)',
        meta: {
            icon: '',
            hide: false,
            requiresAuth: false,
            requiresGlobalAdmin: false
        },
        component: ItemHome
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});


export default router;

function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
    const auth = useAuthStore();
    if (auth.isAuthed) {
        next();
        return;
    }

    auth.protectedRoute = true;
    auth.showLoginDialog = true;
    next();
};

<script setup lang="ts">
import { ref } from 'vue';
import { usePluginStore } from '../../stores/usePluginStore';
import { PluginData } from 'rundown-common';
import EditableObjectTable from '../../components/table/EditableObjectTable.vue';

const plugins = usePluginStore();

const headers = [
    { title: 'Active', key: 'active' },
    { title: 'Name', key: 'name' },
    { title: "Description", key: 'description' },
    { title: 'Version', key: 'version', sortable: false },
    { title: 'Actions', key: 'actions', sortable: false },
]

type tOption = { key: string, value: any };

const dialog = ref(false);
const options = ref({} as tOption);
const plugin = ref({} as PluginData);

function toggleActive(pPlugin: PluginData) {
    plugins.toggle(pPlugin);
}

function editOptions(pPlugin: PluginData) {
    if(!pPlugin.active)
        return;
    
    plugin.value = pPlugin;
    options.value = JSON.parse(JSON.stringify(plugins.options.get(pPlugin.id)));

    dialog.value = true;
}

const updateData = ({ key, value }: tOption) => {
    options.value[key] = value;
};

function save() {
    plugins.optionsSave(plugin.value, options.value);
    cancel();   
}

function cancel() {
    options.value = {} as tOption;
    plugin.value = {} as PluginData;
    dialog.value = false;
}

</script>

<template>
    <v-card class="justify-center">

        <v-data-table :items="plugins.available" :headers="headers">
            <template v-slot:item.active="{ item }">
                <v-checkbox v-model="item.active" @click.stop="toggleActive(item)"></v-checkbox>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon class="me-2" @click="editOptions(item)">
                    mdi-pencil
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card>
                <EditableObjectTable :data="options" @update-data="updateData">

                </EditableObjectTable>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="cancel">Cancel</v-btn>
                    <v-btn @click="save">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

import { KeyStringData, KeystringInterface } from "rundown-common";
import { useKeywordStore } from "../stores/useKeywordStore";

/**
 * Representation of a key string
 */
export class cKeyString implements KeystringInterface {
    keystring: KeyStringData;

    constructor(pKeyString: KeyStringData = { id: 0, content: '', isHidden: false, isAssociatedItemsHidden: false }) {
        this.keystring = pKeyString;
        this.loadFrom(pKeyString);
    }

    loadFrom = (pKeyStringData: KeyStringData): void => {
        this.keystring = pKeyStringData;
    }

    getId = (): number => {
        return this.keystring.id;
    }

    getContent = (): string => {
        return this.keystring.content;
    }

    setContent = (newContent: string): void => {
        this.keystring.content = newContent;
    }

    getIsHidden = (): boolean => {
        return this.keystring.isHidden;
    }

    setIsHidden = (value: boolean): void => {
        this.keystring.isHidden = value;
    }

    getIsAssociatedItemsHidden = (): boolean => {
        return this.keystring.isAssociatedItemsHidden;
    }

    setIsAssociatedItemsHidden = (value: boolean): void => {
        this.keystring.isAssociatedItemsHidden = value;
    }

    /**
     * Save the current key string data (simulated async operation)
     */
    save = async (): Promise<void> => {
        const keywords = useKeywordStore();
        await keywords.updateKeyword(this.keystring);
    }
}

<script setup lang="ts">
import { PropType } from 'vue';
import { cItem } from '../../domain/item';
import { useFilterStore } from '../../stores/useFilterStore';

const filters = useFilterStore();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: true
    }
});

</script>

<template>
    <v-chip-group class="text-secondary">
        <template v-if="item.keywords.length > 0">
            <div class="key-box">
                <v-chip density="compact" variant="text" v-for="keyword in item.keywords"
                    @click.stop="filters.searchAddItem(keyword)">{{ keyword }}</v-chip>
            </div>
        </template>
        <template v-else>
            <v-chip density="compact" variant="text" style="visibility: hidden;"></v-chip>
        </template>
    </v-chip-group>
</template>
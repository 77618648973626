<script setup lang="ts">
  import { ref, computed, Ref } from "vue";
  import { useDisplay } from "vuetify/lib/framework.mjs";
  import Item from "./AdminItem.vue";
  import ItemTable from "../../components/table/ItemTable.vue";
  import AdminItemLocation from "./ItemMetaData/AdminItemLocation.vue";
  import PluginRender from "../../components/PluginRender.vue";
  import Image from "../../components/Image.vue";
  import PersonImageDetection from "../../components/ImageDetection/PersonImageDetection.vue";
  import {
    ItemObjectData,
    ThumbnailSize,
    ItemLocationData,
    ItemPersonInterface,
    PersonInterface,
    ItemObjectInterface,
    ObjectInterface,
  } from "rundown-common";
  import { cItem } from "../../domain/item";
  import { useCollectionStore } from "../../stores/useCollectionStore";
  import { useItemSlidingWindowStore } from "../../stores/useItemSlidingWindowStore";
  import { useItemStore } from "../../stores/useItemStore";
  import { usePeopleStore } from "../../stores/usePeopleStore";
  import { ComputedRef } from "vue";
  import { useDynamicToolbarSettings } from "../../stores/useDynamicToolbarSettings";
  import ItemPersonHover from "../../components/item/ItemPersonHover.vue";
  import { onMounted } from "vue";
  import { cPerson } from "../../domain/Person";
  import { cItemPersonObject } from "../../domain/ItemPersonObject";
  import ObjectImageDetection from "../../components/ImageDetection/ObjectImageDetection.vue";
  import { useObjectsStore } from "../../stores/useObjectsStore";
  import { cObject } from "../../domain/Object";
  import CreateMenu from "../../components/ImageDetection/CreateMenu.vue";
  import { cItemObject } from "../../domain/ItemObject";

  const collections = useCollectionStore();
  const itemWindow = useItemSlidingWindowStore();
  const items = useItemStore();

  const people = usePeopleStore();
  const objects = useObjectsStore();

  const dynamicToolbarSettings = useDynamicToolbarSettings();

  const tab = ref("details");

  const copied = ref(new cItem());
  const readonly = ref(collections.getCollection.readonly);

  const imageDetect = ref<typeof PersonImageDetection | null>(null);
  const imageRef: Ref<InstanceType<typeof Image> | null> = ref(null);

  const objectDetect = ref<typeof ObjectImageDetection | null>(null);
  const personSelected = ref<ItemPersonInterface | null>(null);
  const objectSelected = ref<ItemObjectInterface | null>(null);

  const ImagePersonObjectSelected = ref<ItemPersonInterface | null>(null);
  const imagePersonHover = ref<InstanceType<typeof ItemPersonHover> | null>(
    null
  );

  const ImageItemObjectSelected = ref<ItemObjectInterface | null>(null);

  const imageElement: ComputedRef<HTMLElement> = computed(
    () => imageRef.value?.$el as HTMLElement
  );

  const menuVisible = ref(false);
  const menuX = ref(0);
  const menuY = ref(0);
  const clickOffsetX = ref(0);
  const clickOffsetY = ref(0);

  /**
   * Clear selected person or object
   */
  const selectedPersonClear = () => {
    ImagePersonObjectSelected.value = null;
    personSelected.value = null;

    if (imageDetect.value) imageDetect.value.clearSelection();
  };

  const selectedObjectClear = () => {
    ImageItemObjectSelected.value = null;
    objectSelected.value = null;

    if (objectDetect.value) objectDetect.value.clearSelection();
  };
  /**
   *
   */
  const triggerFaceUpdate = () => {
    if (imageDetect.value) {
      imageDetect.value.updateBoxes();
    }
    if (objectDetect.value) {
      objectDetect.value.updateBoxes();
    }
  };

  /**
   * Click on the image
   */
  const imageClick = (pEvent: MouseEvent) => {
    if (imageDetect.value) imageDetect.value.clearSelection();

    if (objectDetect.value) objectDetect.value.clearSelection();

    selectedPersonClear();
    selectedObjectClear();
    menuVisible.value = false;
  };

  /**
   * Create an object
   */
  const createObject = () => {
    menuVisible.value = false;
    if (!objectDetect.value) return;

    //imageDetect.value.clearAll();
    const box = objectDetect.value.createObject(
      clickOffsetX.value,
      clickOffsetY.value,
      50
    );
    if (!box) return;

    if (ImageItemObjectSelected.value) {
      ImageItemObjectSelected.value.positionSet(
        box.x,
        box.y,
        box.width,
        box.height
      );

      objectDetect.value.addObject(ImageItemObjectSelected.value, true);
      return;
    }
    objectSelected.value = null;
    ImageItemObjectSelected.value = new cItemObject(
      {
        id: 0,
        type: "Object",
        object: null,
        person: null,
        face: null,
        x: box.x,
        y: box.y,
        width: box.width,
        height: box.height,
      } as ItemObjectData,
      items.getSelectedItem
    );

    objectDetect.value.addObject(ImageItemObjectSelected.value, true);
  };

  /**
   * Create a person
   */
  const createPerson = () => {
    menuVisible.value = false;
    if (!imageDetect.value) return;

    //imageDetect.value.clearAll();
    const box = imageDetect.value.createObject(
      clickOffsetX.value,
      clickOffsetY.value,
      50
    );
    if (!box) return;

    if (ImagePersonObjectSelected.value) {
      ImagePersonObjectSelected.value.positionSet(
        box.x,
        box.y,
        box.width,
        box.height
      );

      imageDetect.value.addObject(ImagePersonObjectSelected.value, true);
      return;
    }

    personSelected.value = null;
    ImagePersonObjectSelected.value = new cItemPersonObject(
      {
        id: 0,
        type: "Person",
        object: null,
        person: null,
        face: null,
        x: box.x,
        y: box.y,
        width: box.width,
        height: box.height,
      } as ItemObjectData,
      items.getSelectedItem
    );

    imageDetect.value.addObject(ImagePersonObjectSelected.value, true);
  };

  /**
   * Create a box at the event location
   */
  const imageDblClick = (pEvent: MouseEvent) => {
    menuVisible.value = true;
    menuX.value = pEvent.clientX;
    menuY.value = pEvent.clientY;
    clickOffsetX.value = pEvent.offsetX;
    clickOffsetY.value = pEvent.offsetY;
  };

  const imageDetectBoxUpdate = async (pPersonObject: ItemPersonInterface) => {
    // New Face
    if (pPersonObject.getId() == 0) {
    } else {
      pPersonObject.save();
    }
  };

  const imageDetectBoxSelected = (
    pPersonObject: ItemPersonInterface | null
  ) => {
    selectedObjectClear();

    personSelected.value = pPersonObject;
    ImagePersonObjectSelected.value = pPersonObject;
  };

  /**
   *
   */
  const ImageInputUpdate = async (
    pValue: string | PersonInterface | ItemPersonInterface | null
  ) => {
    if (pValue === null || pValue === undefined) {
      return;
    }

    let person: PersonInterface | ItemPersonInterface;

    if (typeof pValue === "string") {
      person = await people.createPerson(pValue);
    } else {
      person = pValue;
    }

    if (ImagePersonObjectSelected.value) {
      if (person instanceof cPerson) {
        ImagePersonObjectSelected.value.object.person = person.getId();
      }

      await items.getSelectedItem.personAdd(
        ImagePersonObjectSelected.value as ItemPersonInterface
      );
    }

    ImagePersonObjectSelected.value = null;
    personSelected.value = null;
  };

  /**
   *
   */
  const objectDetectBoxUpdate = async (pObject: ItemObjectInterface) => {
    // New Object
    if (pObject.getId() == 0) {
    } else {
      await pObject.save();
    }
  };

  /**
   *
   */
  const objectDetectBoxSelected = (pObject: ItemObjectInterface | null) => {
    selectedPersonClear();

    objectSelected.value = pObject;
    ImageItemObjectSelected.value = pObject;
  };

  /**
   *
   */
  const objectInputUpdate = async (
    pValue: string | ObjectInterface | ItemObjectInterface | null
  ) => {
    if (pValue === null || pValue === undefined) {
      return;
    }

    let object: ObjectInterface | ItemObjectInterface;

    if (typeof pValue === "string") {
      object = await objects.createObject(pValue, "", null);
    } else {
      object = pValue;
    }

    if (ImageItemObjectSelected.value) {
      if (object instanceof cObject) {
        ImageItemObjectSelected.value.object.object = object.getId();
      }

      await items.getSelectedItem.objectAdd(
        ImageItemObjectSelected.value as ItemObjectInterface
      );
    }

    ImageItemObjectSelected.value = null;
    objectSelected.value = null;
  };

  /**
   * Click on an object chip
   */
  const eventObjectSelected = (pObject: cItemObject | null) => {
    selectedPersonClear();

    objectSelected.value = pObject;

    if (pObject == null) {
      if (objectDetect.value) objectDetect.value.boxSelect(null);
      return;
    }

    if (objectDetect.value) objectDetect.value.boxSelect(pObject);

    ImageItemObjectSelected.value = pObject;
  };

  /**
   * Click on a person chip
   */
  const eventPersonSelected = (pPerson: cItemPersonObject | null) => {
    selectedObjectClear();

    personSelected.value = pPerson;

    if (pPerson == null) {
      if (imageDetect.value) imageDetect.value.boxSelect(null);
      return;
    }

    if (imageDetect.value) imageDetect.value.boxSelect(pPerson);

    ImagePersonObjectSelected.value = pPerson;
  };

  function imagePointerMove(pPointerEvent: any, pX: number, pY: number) {
    imagePersonHover.value?.positionSet(
      pPointerEvent.clientX,
      pPointerEvent.clientY,
      pX,
      pY
    );
  }

  /**
   * Event: Click an item
   */
  async function itemSelect(pItem: cItem) {
    items.selectItem(pItem);

    copied.value = items.getSelectedItem;
  }

  /**
   * Event: Click a date
   */
  async function dateClick(event: Event, date: string) {
    if (date === "" || readonly.value) return;

    event.stopPropagation();
    let item = items.getSelectedItem;

    item.setDateTaken(date);
  }

  /**
   * Event: Click a category
   */
  async function categoryClick(event: Event, pCategory: string) {
    if (pCategory === "" || readonly.value) return;

    event.stopPropagation();
    let item = items.getSelectedItem;

    item.setCategory(pCategory);
  }

  /**
   * Event: Click a person chip in a table row
   */
  async function personChipClick(event: Event, pPerson: PersonInterface) {
    if (readonly.value || pPerson == null) return;

    event.stopPropagation();

    let item = items.getSelectedItem;
    await item.personAdd(pPerson);
  }

  /**
   * Event: Click a keyword chip in a table row
   */
  async function keywordChipClick(event: Event, keyword: string) {
    if (readonly.value) return;

    event.stopPropagation();

    let item = items.getSelectedItem;
    await item.keywordAdd(keyword);
  }

  /**
   * Event: Click a location
   */
  function locationClick(
    event: Event,
    pLocation: ItemLocationData | undefined
  ) {
    if (readonly.value || pLocation == undefined) return;

    event.stopPropagation();
    let item = items.getSelectedItem;
    item.locationUpdate(pLocation);
  }

  /**
   * Copy tags to pItem
   */
  async function pastetags(event: Event, pItem: cItem) {
    // If no item is copied, then use the selected
    if (copied.value.getId() == 0) copied.value = items.getSelectedItem;

    if (pItem.datetaken == null || pItem.datetaken?.length == 0)
      pItem.datetaken = copied.value.datetaken;

    if (pItem.category.length == 0) pItem.category = copied.value.category;

    if (pItem.keywords.length == 0)
      pItem.keywords = JSON.parse(JSON.stringify(copied.value.keywords));

    if (pItem.peopleSaved.length == 0) pItem.peopleCopyFrom(copied.value);

    if (pItem.locations.length == 0)
      pItem.locations = JSON.parse(JSON.stringify(copied.value.locations));

    pItem.save();

    event.stopImmediatePropagation();
  }

  function calcItemsHeight(): string {
    const { height } = useDisplay();
    const fin = Math.floor(height.value / 3 - 15).toString() + "px";

    return fin;
  }

  function calcImageHeight(): string {
    const { height } = useDisplay();
    return Math.floor(height.value / 2 - 55).toString();
  }

  /**
   * Calculate the height of our display
   */
  const cardHeight = computed(() => {
    const { height } = useDisplay();
    return Math.floor(height.value / 2 - 90).toString();
  });

  /**
   * Goto the selected item when view is opened
   */
  onMounted(async () => {
    if (items.getSelectedItemIndex >= 0) {
      await itemWindow.changeToWindowWithIndex(items.getSelectedItemIndex);
      itemWindow.scrollToItem();
    }
  });
</script>

<template>
  <v-container class="px-0">
    <v-card class="mx-0">
      <v-row>
        <v-col cols="5">
          <Image
            ref="imageRef"
            @zoom-changed="triggerFaceUpdate"
            @dblclick="imageDblClick"
            @pointer-move="imagePointerMove"
            :item="items.getSelectedItem"
            :height="calcImageHeight()"
            :thumbnail="ThumbnailSize.Large"
            can-zoom
            @click="imageClick"
          >
            <template v-slot:overlay>
              <PersonImageDetection
                ref="imageDetect"
                :item="items.getSelectedItem"
                :auto-detect="dynamicToolbarSettings.isFaceRecognitionEnabled"
                :auto-tag="
                  dynamicToolbarSettings.isFaceRecognitionAutoTagEnabled
                "
                :image-element="imageElement"
                :selectedPerson="personSelected"
                @box-update="imageDetectBoxUpdate"
                @box-save-input="ImageInputUpdate"
                @box-selected="imageDetectBoxSelected"
              >
              </PersonImageDetection>

              <ObjectImageDetection
                ref="objectDetect"
                :item="items.getSelectedItem"
                :image-element="imageElement"
                :selectedObject="objectSelected"
                @box-update="objectDetectBoxUpdate"
                @box-save-input="objectInputUpdate"
                @box-selected="objectDetectBoxSelected"
              >
              </ObjectImageDetection>

              <ItemPersonHover
                ref="imagePersonHover"
                :item="items.getSelectedItem"
              />

              <CreateMenu
                :item="items.getSelectedItem"
                :show="menuVisible"
                :position-x="menuX"
                :position-y="menuY"
                @menu-item-click-object="createObject"
                @menu-item-click-person="createPerson"
              >
              </CreateMenu>
            </template>
          </Image>
        </v-col>

        <v-col cols="7">
          <v-tabs
            v-model="tab"
            density="compact"
            fixed-tabs
            centered
            class="mb-2"
          >
            <v-tab value="details">
              <v-icon class="mr-3">mdi-account-details</v-icon>Details
            </v-tab>
            <v-tab value="location">
              <v-icon class="mr-3">mdi-map-marker</v-icon>Location
            </v-tab>
            <PluginRender
              render-element="AdminItemManageMetaData"
              render-piece="tab"
            ></PluginRender>
          </v-tabs>

          <v-card>
            <v-window
              v-model="tab"
              :style="{ height: cardHeight + 'px' }"
            >
              <v-window-item
                value="details"
                style="height: 100%"
              >
                <Item
                  can-zoom-image
                  :item="items.getSelectedItem"
                  :selected-person="personSelected"
                  :selected-object="objectSelected"
                  @person-selected="eventPersonSelected"
                  @object-selected="eventObjectSelected"
                >
                </Item>
              </v-window-item>

              <v-window-item
                value="location"
                style="height: 100%"
              >
                <AdminItemLocation
                  :item="items.getSelectedItem"
                ></AdminItemLocation>
              </v-window-item>

              <PluginRender
                render-element="AdminItemManageMetaData"
                render-piece="content"
                :item="items.getSelectedItem"
                :max-height="cardHeight"
              ></PluginRender>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

    <ItemTable
      :isLoading="items.isLoading"
      :height="calcItemsHeight()"
      :read-only="readonly"
      :columns="[
        ...(!collections.getCollection.managed ? ['path'] : []),
        'name',
        'date',
        'category',
        'people',
        'keywords',
        'address',
      ]"
      :items="itemWindow.getItems"
      :item-current="items.getSelectedItem"
      :page="itemWindow.windowNumber"
      :pages="itemWindow.totalWindows"
      :items-per-page="itemWindow.windowSize"
      @page-select="itemWindow.changeToWindow"
      @item-select="itemSelect"
      @items-per-page-change="itemWindow.setWindowSize"
      @date-click="dateClick"
      @category-click="categoryClick"
      @person-chip-click="personChipClick"
      @keyword-chip-click="keywordChipClick"
      @location-click="locationClick"
      @item-ctrl-click="pastetags"
      @item-next="itemWindow.moveDown"
      @item-previous="itemWindow.moveUp"
      @page-next="itemWindow.moveWindowDown"
      @page-previous="itemWindow.moveWindowUp"
    >
    </ItemTable>
  </v-container>
</template>

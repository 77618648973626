<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits<{
    (e: 'update-data', payload: { key: string; value: any }): void;
}>();

const isObjectType = (value: any) => typeof value === 'object' && value !== null;
const isBooleanType = (value: any) => typeof value === 'boolean';
const isStringType = (value: any) => typeof value === 'string';
const isNumberType = (value: any) => typeof value === 'number';

const handleUpdate = (key: string, value: any) => {
    emit('update-data', { key, value });
};

const handleNestedUpdate = (parentKey: string, { key, value }: { key: string, value: any }) => {
    // Access the nested object using the parent key and update its property
    let nestedObj = props.data[parentKey];
    if (nestedObj && typeof nestedObj === 'object') {
        nestedObj[key] = value;
    }

    // Emit an update to inform the parent component of the change
    emit('update-data', { key: parentKey, value: nestedObj });
};

const tableItems = computed(() => {
    return Object.keys(props.data).map(key => ({
        key,
        value: props.data[key],
    }));
});
const headers = [
    { text: 'Key', value: 'key' },
    { text: 'Value', value: 'value' },
];

</script>


<template>
    <v-data-table-virtual :headers="headers" :items="tableItems" item-key="key" :items-per-page="-1">
        <template v-slot:item="{ item }">
            <tr>
                <td>{{ item.key }}</td>
                <td v-if="isObjectType(item.value)">
                    <!-- Recursive component for nested objects -->
                    <EditableObjectTable :data="item.value" @update-data="handleNestedUpdate(item.key, $event)" />
                </td>
                <td v-else>
                    <v-checkbox v-if="isBooleanType(item.value)" v-model="item.value"
                        @change="handleUpdate(item.key, item.value)" />

                    <v-text-field v-else-if="(isStringType(item.value) || isNumberType(item.value))" :value="item.value"
                        @input="handleUpdate(item.key, $event.target.value)" />
                    <span v-else>{{ item.value }}</span>
                </td>
            </tr>
        </template>
    </v-data-table-virtual>
</template>

  

<script setup lang="ts">
import { ref } from 'vue';
import ManageTagKeywords  from './ManageTags/ManageTagsKeywords.vue';
import ManageTagBulkTag from './ManageTags/ManageTagsBulkTag.vue';
import ManageTagReplace from './ManageTags/ManageTagsReplace.vue';

const tab = ref('recycleditems');

</script>

<template>
    <v-container>

        <v-tabs v-model="tab">
            <v-tab value="bulktag">
                Item Bulk Tag
            </v-tab>
            <v-tab value="replacetag">
                Item Tag Replace
            </v-tab>
            <v-tab value="manage">
                Tag Options
            </v-tab>
        </v-tabs>

        <v-card-text>
            <v-window v-model="tab">
                <v-window-item value="bulktag">
                    <ManageTagBulkTag/>
                </v-window-item>
                <v-window-item value="replacetag">
                    <ManageTagReplace/>
                </v-window-item>
                <v-window-item value="manage">
                    <ManageTagKeywords/>
                </v-window-item>
            </v-window>
        </v-card-text>

    </v-container>
</template>
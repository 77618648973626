import { computed } from "vue";
import { ItemPageManager, ItemStatus } from "../utility/ItemPageManager";

export function useItemPagination(
  pItemStatus: ItemStatus = ItemStatus.Normal,
  pItemsPerPage: number = 10
) {
  const pages = new ItemPageManager(pItemStatus, pItemsPerPage);

  const getRange = async (startIndex: number, count: number) =>
    await pages.getRange(startIndex, count);
  
  const getTotalRecords = computed(() => pages.getTotalRecords());
  const isLoading = computed(() => pages.isLoading());
  const reloadCounter = computed(() => pages.getReloadCounter());
  const refreshCounter = computed(() => pages.getRefreshCounter());

  async function load() {
    while (isLoading.value) {
      await new Promise(resolve => setTimeout(resolve, 100));
    }
    pages.load();
  }
  return {
    pages,
    getRange,
    getTotalRecords,
    isLoading,
    reloadCounter,
    refreshCounter,
    load,
    getIndexOfItemId: pages.getIndexOfItemId.bind(pages),
    setItemsPerPage: pages.setItemsPerPage.bind(pages),
    removeItem: pages.removeItem.bind(pages),
  };
}

<script setup lang="ts">
import { ref } from 'vue';
import ManageRecycledItems from './AdminManageRecycledItems.vue';
import ManageMissingItems from './AdminManageMissingItems.vue';
import ManagePeople from './AdminManagePeople.vue';
import ManagePlugins from './AdminManagePlugins.vue';
const tab = ref('recycleditems');

</script>

<template>
    <v-container>

        <v-tabs v-model="tab">
            <v-tab value="recycleditems">
                Recycle Bin
            </v-tab>
            <v-tab value="missingitems">
                Missing Items
            </v-tab>
            <v-tab value="people">
                People
            </v-tab>
            <v-tab value="plugins">
                Plugins
            </v-tab>
        </v-tabs>

        <v-card-text>
            <v-window v-model="tab">
                <v-window-item value="recycleditems">
                    <ManageRecycledItems/>
                </v-window-item>
                <v-window-item value="missingitems">
                    <ManageMissingItems />
                </v-window-item>

                <v-window-item value="people">
                    <ManagePeople />
                </v-window-item>

                <v-window-item value="plugins">
                    <ManagePlugins />
                </v-window-item>

            </v-window>
        </v-card-text>

    </v-container>
</template>
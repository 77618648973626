import { defineStore } from "pinia";
import axios from "axios";
import Pagination, { PageLinks, PageMetaData } from "../services/Pagination";

import { useFilterStore } from "./useFilterStore";
import { useMessageStore } from "./useMessageStore";
import { useCollectionStore } from "./useCollectionStore";

type tFeatureUpdateCallback = (
  pFeatures: GeoJSON.FeatureCollection,
  pPage: number,
  pPagesTotal: number
) => void;

export interface ItemPageData {
  _meta: PageMetaData;
  _links: PageLinks;
  items: GeoJSON.FeatureCollection;
}

export const useGeoJsonStore = defineStore("geojson", {
  state: () => ({
    result: {
      _meta: { total_records: 0, page: 0, limit: 0, count: 0 },
      _links: [],
      items: {} as GeoJSON.FeatureCollection,
    } as ItemPageData,
    page: 0,
    pages: 0,
    lastQuery: "",
    loading: false,
    loadProgress: 0,
    featureCollection: {
      type: "FeatureCollection",
      features: [] as Array<GeoJSON.Feature>,
    } as GeoJSON.FeatureCollection,
  }),

  getters: {
    isLoading: (state) => state.loading,
    getFeatureLoadProgress: (state) => state.loadProgress,
    getFeatureCollection: (state) => state.featureCollection,

    getTotalPages(state) {
      if (
        state.result._meta.total_records == undefined ||
        state.result._meta.total_records == 0
      )
        return 0;

      return Math.ceil(
        state.result._meta.total_records / (state.result._meta?.limit ?? 1)
      );
    },
  },

  actions: {
    async addFeatures(
      pCallback: tFeatureUpdateCallback,
      pFeatureCollection: GeoJSON.FeatureCollection
    ) {
      this.loadProgress = (this.page / this.getTotalPages) * 100;

      if (pFeatureCollection.features.length != 0) {
        this.featureCollection.features =
          this.featureCollection.features.concat(pFeatureCollection.features);
      }

      pCallback(this.featureCollection, this.page, this.getTotalPages);

      if (this.page < this.getTotalPages) return this.nextPage(pCallback);

      this.loading = false;
      return;
    },

    async nextPage(pCallback: tFeatureUpdateCallback) {
      await Pagination.getNextPage(this.result._links).then((response) => {
        if (response.data.length == 0) {
          this.loading = false;
          return;
        }

        this.page += 1;
        this.result = response.data;
        return this.addFeatures(pCallback, this.result.items);
      });
    },

    async loadFeatures(pCallback: tFeatureUpdateCallback) {
      const filters = useFilterStore();
      const messages = useMessageStore();
      const collection = useCollectionStore();

      try {
        const query =
          collection.getSelected + "?" + filters.getParameters + "&limit=5000";
        if (query == this.lastQuery) {
          return;
        }

        this.loading = true;
        const result = await axios.get("/v1/geojson/features/" + query);
        this.lastQuery = query;
        this.result = result.data;
        this.page = 1;
        this.featureCollection.features = [];

        return this.addFeatures(pCallback, this.result.items);
      } catch (error) {
        messages.handleAxiosError(error, "Failed to load geojson features");
      }
    },
  },
});

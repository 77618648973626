// stores/useItemMissingSlidingWindowStoreStore.js
import { defineStore } from "pinia";
import { useItemSlidingWindow } from "../utility/useItemSlidingWindow";
import { useItemRecycledStore } from "./useItemRecycledStore";

export const useItemRecycledSlidingWindowStore = defineStore(
  "itemRecycledSlidingWindow",
  () => {
    const itemStore = useItemRecycledStore();
    const {
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      isLoading,
      items,
      windowNumber,
      moveUp,
      moveDown,
      moveWindowDown,
      moveWindowUp,
      jumpTo,
      changeToWindow,
      setWindowSize,
    } = useItemSlidingWindow(itemStore);

    return {
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      isLoading,
      items,
      windowNumber,
      moveUp,
      moveDown,
      moveWindowDown,
      moveWindowUp,
      jumpTo,
      changeToWindow,
      setWindowSize,
    };
  }
);

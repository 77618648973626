<script setup lang="ts">
import router from '../../router';
import { useCollectionStore } from '../../stores/useCollectionStore';

function scan() {
    
    useCollectionStore().getCollections.forEach((pCollection) => {
        if(pCollection.lastscan == '') {
            useCollectionStore().scan(pCollection);
        }
    })

    
}
</script>

<template>
    <v-row class="mt-12" justify="center">
        <v-col cols="12" sm="8" md="6">
            <v-card class="px-5 py-8 rounded-lg" color="#252525" dark>
                <h2 class="mb-5">No Items</h2>
                <p class="mb-4">You don't have any items yet.</p>
                <p class="mb-0">
                    Now that you have a collection, you need to scan it
                    To scan your first collection, click the button below.</p>
                <v-row justify="center" class="mt-7">
                    <v-btn class="custom-btn mr-3 px-10" rounded="pill" color="green" dark @click="scan">Scan Collection</v-btn>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</template>
<script setup lang="ts">
  import { onMounted, ref } from "vue";
  import { useAccessStore } from "../stores/useAccessStore";
  import { useAuthStore } from "../stores/useAuthStore";
  import { AccessInterface, CollectionData, UserData } from "rundown-common";

  const props = defineProps({
    collection: {
      type: Object as () => CollectionData,
      required: true,
    },
  });

  const access = useAccessStore();
  const auth = useAuthStore();

  var dialog = ref(false);
  var allUsers = ref([] as Array<UserData>);
  var addinguser = ref({ id: 0, username: "", name: "" } as UserData);

  var users = ref([] as Array<Array<UserData>>);
  var collection = ref({} as CollectionData);
  var accessAdd = ref([] as Array<AccessInterface>);
  var accessRem = ref([] as Array<AccessInterface>);

  var accessAddUsers = ref([] as Array<object>);
  var accessRemUsers = ref([] as Array<object>);

  /**
   * Event: Permissions
   */
  function dialogOpen() {
    dialog.value = true;
    collection.value = JSON.parse(JSON.stringify(props.collection));
  }

  function dialogCancel() {
    dialog.value = false;
    collection.value = JSON.parse(JSON.stringify(props.collection));
  }

  function getPropAccess(pId: number) {
    return props.collection.access.find((item) => item.id === pId);
  }

  async function save() {
    collection.value.access.forEach(async function (item: AccessInterface) {
      if (item.id == 0) return;

      var prop = getPropAccess(item.id);
      if (prop !== undefined && JSON.stringify(prop) !== JSON.stringify(item)) {
        if ((await access.update(item)) == false) {
        } else {
          prop = item;
        }
      }
    });

    accessAdd.value.forEach(async function (item, index, object) {
      if ((await access.create(item)) == true) {
        if ((await access.collectionAdd(collection.value, item)) == true) {
          object.splice(index, 1);
        }
      }
    });

    accessRem.value.forEach(async function (item, index, object) {
      if ((await access.collectionRemove(collection.value, item)) == true) {
        object.splice(index, 1);
      }
    });

    accessAddUsers.value.forEach(async function (item, index, object) {
      if ((await access.userAdd(item.access.id, item.user.username)) == true) {
        object.splice(index, 1);
      }
    });

    accessRemUsers.value.forEach(async function (item, index, object) {
      if (
        (await access.userRemove(item.access.id, item.user.username)) == true
      ) {
        object.splice(index, 1);
      }
    });

    props.collection.access = collection.value.access;
  }

  /**
   * Get a list of users with this access
   */
  async function getUsers(pAccess: AccessInterface) {
    if (pAccess.id == 0) return;

    return await access.getUsers(pAccess.id);
  }

  /**
   * Remove a user
   */
  async function removeUser(pAccess: AccessInterface, pUser: UserData) {
    var found = accessAddUsers.value.find(
      (item) => item.user.username == pUser.username
    );
    if (found !== undefined) {
      accessAddUsers.value = accessAddUsers.value.filter(
        (item) => item.user.username != pUser.username
      );
    } else {
      accessRemUsers.value.push({ access: pAccess, user: pUser });
    }
    users.value[pAccess.id] = users.value[pAccess.id].filter(
      (item) => item.id !== pUser.id
    );
  }

  /**
   * Add a user
   */
  async function addUser(pAccess: AccessInterface, pUser: UserData) {
    accessAddUsers.value.push({ access: pAccess, user: pUser });
    users.value[pAccess.id].push(pUser);
  }

  /**
   * Add a group
   */
  async function addGroup() {
    var access = {
      id: 0,
      name: "",
      admin: false,
      create: false,
      view: false,
      update: false,
    } as AccessInterface;

    accessAdd.value.push(access);
    collection.value.access.push(access);
  }

  /**
   * Delete a group
   */
  async function deleteGroup(
    pCollection: CollectionData,
    pAccess: AccessInterface
  ) {
    accessRem.value.push(pAccess);
    pCollection.access = pCollection.access.filter(
      (item) => item.id !== pAccess.id
    );
  }

  /**
   *
   */
  function getLabel(pAccess: AccessInterface) {
    if (pAccess.admin) return "Administrators";

    if (pAccess.public) return "Public Access";

    var str = "";

    if (pAccess.create) str += "Create";
    if (pAccess.update) str += ", Update";
    if (pAccess.view) str += ", View";

    return str.replace(/^,+|,+$/g, "");
  }

  /**
   *
   */
  function getDisabledRole(pRole: number, pAccess: AccessInterface) {
    if (pRole == 0 && pAccess.public) return true;

    if (pRole == 1 && pAccess.admin) return true;

    return false;
  }

  /**
   *
   */
  function getDisabled(pAccess: AccessInterface) {
    if (pAccess.admin || pAccess.public) return true;

    return false;
  }

  onMounted(async () => {
    props.collection?.access.forEach(async function (pAccess: AccessInterface) {
      users.value[pAccess.id] = await getUsers(pAccess);
    });
    allUsers.value = await auth.getUsers();
  });
</script>

<template>
  <v-tooltip location="start">
    <template v-slot:activator="{ props }">
      <v-icon
        v-bind="props"
        @click="dialogOpen()"
        >mdi-account
      </v-icon>
    </template>
    <span>Permissions</span>
  </v-tooltip>

  <v-dialog v-model="dialog">
    <v-card min-width="500px">
      <v-card-title>
        <span class="text-h5">{{ collection.name }} Permissions</span>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels multiple>
          <v-expansion-panel
            v-for="(item, i) in collection.access"
            :key="i"
            :value="item"
          >
            <v-expansion-panel-title>
              {{ getLabel(item) }}
            </v-expansion-panel-title>

            <v-expansion-panel-text>
              <v-checkbox-btn
                v-model="item.admin"
                label="Admin"
                :disabled="getDisabledRole(0, item)"
              >
              </v-checkbox-btn>
              <v-checkbox-btn
                v-model="item.public"
                label="Public"
                :disabled="getDisabledRole(1, item)"
              >
              </v-checkbox-btn>

              <v-checkbox-btn
                v-model="item.create"
                label="Create"
                :disabled="getDisabled(item)"
              >
              </v-checkbox-btn>
              <v-checkbox-btn
                v-model="item.update"
                label="Update"
                :disabled="getDisabled(item)"
              >
              </v-checkbox-btn>
              <v-checkbox-btn
                v-model="item.view"
                label="View"
                :disabled="getDisabled(item)"
              >
              </v-checkbox-btn>

              <v-tooltip location="start">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    @click="deleteGroup(collection, item)"
                  >
                    mdi-delete-forever
                  </v-icon>
                </template>
                <span>Delete group</span>
              </v-tooltip>

              <v-list>
                <v-list-subheader>Users</v-list-subheader>

                <v-list-item
                  v-for="(user, x) in users[item.id]"
                  :value="item"
                  active-color="primary"
                >
                  <v-list-item-title v-text="user.username"></v-list-item-title>

                  <template v-slot:append>
                    <v-icon
                      icon="mdi-close"
                      @click="removeUser(item, user)"
                    ></v-icon>
                  </template>
                </v-list-item>
                <v-list-item
                  value="new"
                  active-color="primary"
                >
                  <v-list-item-title>
                    <v-autocomplete
                      v-model="addinguser"
                      :items="allUsers"
                      hide-no-data
                      hide-selected
                      item-title="username"
                      item-value="username"
                      label="Add User"
                      placeholder="Enter users name"
                      prepend-icon="mdi-account-plus"
                      @click:prepend="addUser(item, addinguser)"
                      return-object
                    ></v-autocomplete>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-expansion-panel-text>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <v-card-actions>
        <v-tooltip location="start">
          <template v-slot:activator="{ props }">
            <v-icon
              v-bind="props"
              @click="addGroup()"
              >mdi-plus-circle
            </v-icon>
          </template>
          <span>Add permission group</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <v-btn
          color="blue-darken-1"
          text
          @click="dialogCancel"
        >
          Cancel
        </v-btn>
        <v-btn
          color="blue-darken-1"
          text
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

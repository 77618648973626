import axios from "axios";
import { defineStore } from "pinia";
import { useMessageStore } from "./useMessageStore";
import { CollectionData, AccessInterface } from "rundown-common";

export const useAccessStore = defineStore("access", {
  state: () => ({}),

  getters: {},

  actions: {
    /**
     * Create a new access
     */
    async create(pAccess: AccessInterface) {
      const messages = useMessageStore();

      try {
        const data = await axios.post("/v1/access", pAccess);
        pAccess.id = data.data.id;
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Update an access
     */
    async update(pAccess: AccessInterface) {
      const messages = useMessageStore();

      try {
        const data = await axios.put(
          "/v1/access/" + pAccess.id.toString(),
          pAccess
        );
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Get the users attached to an access
     */
    async getUsers(pAccessID: number) {
      const messages = useMessageStore();

      try {
        const data = await axios.get(
          "/v1/users/access/" + pAccessID.toString()
        );

        return data.data;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Add an access to a user
     */
    async userAdd(pAccessID: number, pUsername: string) {
      const messages = useMessageStore();

      try {
        const data = await axios.put(
          "/v1/users/access/" + pAccessID.toString() + "/" + pUsername
        );
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Remove an access from a user
     */
    async userRemove(pAccessID: number, pUsername: string) {
      const messages = useMessageStore();

      try {
        const data = await axios.delete(
          "/v1/users/access/" + pAccessID.toString() + "/" + pUsername
        );
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Add access to a collection
     */
    async collectionAdd(pCollection: CollectionData, pAccess: AccessInterface) {
      const messages = useMessageStore();

      try {
        const data = await axios.put(
          "/v1/collection/access/" +
            pCollection.id.toString() +
            "/" +
            pAccess.id.toString()
        );
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },

    /**
     * Remove access from a collection
     */
    async collectionRemove(
      pCollection: CollectionData,
      pAccess: AccessInterface
    ) {
      const messages = useMessageStore();

      try {
        const data = await axios.delete(
          "/v1/collection/access/" +
            pCollection.id.toString() +
            "/" +
            pAccess.id.toString()
        );
        return true;
      } catch (error) {
        messages.handleAxiosError(error);
        return false;
      }
    },
  },
});

<script setup lang="ts">
import { PropType } from 'vue';
import { cItem } from '../../domain/item';
import { useFilterStore } from '../../stores/useFilterStore';

const filters = useFilterStore();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: true
    },
    variant: {
        type: String,
        default: "tonal"
    }
});

</script>

<template>
    <template v-if="item.hasCategory() == true">
        <v-chip density="compact" :variant="variant" @click.stop="filters.searchAddItem(item.category)">
            {{ item.category }}
        </v-chip>
    </template>
    <template v-else>
        <v-chip density="compact" color="primary" :variant="variant" @click.stop="filters.searchAddItem(item.category)">
        </v-chip>
    </template>
</template>
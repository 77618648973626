<script setup lang="ts">

import { PropType, watch, defineAsyncComponent } from "vue";
import { cItem } from "../domain/item";
import { ref } from "vue";
import { usePluginStore } from "../stores/usePluginStore";
import { computed } from "vue";
import { loadModule } from 'vue3-sfc-loader';

import * as RundownCommon from 'rundown-common';
import * as Vue from 'vue'
import { useWebSocketStore } from "../stores/useWebSocketStore";
import { shallowRef } from "vue";
import { Plugin } from "../domain/Plugin";
const pluginstore = usePluginStore();
const websocketstore = useWebSocketStore();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: false
    },
    itemObject: {
        type: Object as PropType<RundownCommon.ItemObjectInterface>,
        required: false
    },
    itemPerson: {
        type: Object as PropType<RundownCommon.ItemPersonInterface>,
        required: false
    },
    renderElement: {
        type: String,
        required: true
    },
    renderPiece: {
        type: String,
        required: true
    },
    maxHeight: {
        type: String,
        required: false
    }
});

interface Props {
    item?: cItem;
    maxHeight?: any;
    itemObject?: any;
    itemPerson?: any;
    [key: string]: any;
}

const propsToWatch: (keyof Props)[] = ['item', 'maxHeight', 'itemObject', 'itemPerson'];

const templateComponents = computed(() => pluginstore.getMatchingComponents(props.renderElement, props.renderPiece));
const compiledComponents = shallowRef([] as Array<any>);

// Define the templateProps with a specific type
const templateProps: Vue.Ref<{ websocket: any;[key: string]: any; }> = ref({
    websocket: websocketstore,
});
/**
 * Compile the component
 */
const compileComponent = async (pPlugin: Plugin, pElementName: string, pName: string) => {
    return defineAsyncComponent(() => loadModule(pName + '.vue', {
        moduleCache: {
            vue: Vue,
            "rundown-common": RundownCommon
        },
        getFile(pFilename: string) {
            console.log(pFilename);
            if (pFilename.endsWith('.vue')) {
                pFilename = pFilename.replace(/\.vue$/, '');;
            }

            const comp = pPlugin.getFiles(pElementName, pFilename);

            return Promise.resolve(comp)
        },

        addStyle(textContent: string) {
            const style = document.createElement('style');
            style.textContent = textContent;
            document.head.appendChild(style);
        },
    }))
};

/**
 * Compile all components
 */
const compileAllComponents = async () => {
    compiledComponents.value = await Promise.all(
        Array.from(templateComponents.value.entries()).flatMap(([key, components]) =>
            components.map(([plugin, name]) => compileComponent(plugin, key, name))
        )

    );
};

/**
 * 
 */
watch(() => props, (newProps: Props) => {
    propsToWatch.forEach((prop) => {
        if (newProps[prop] !== undefined) {
            templateProps.value[prop] = newProps[prop];
        } else {
            delete templateProps.value[prop];
        }
    });
}, { immediate: true, deep: true });

watch(templateComponents, compileAllComponents, { immediate: true });

</script>

<template>
    <template v-for="(component, index) in compiledComponents" :key="index">
        <component :is="component" v-bind="templateProps" />
        <slot></slot>
    </template>
</template>
<script setup lang="ts">
import { cItem } from '../../domain/item';
import { useFilterStore } from '../../stores/useFilterStore';

const filters = useFilterStore();

const props = defineProps({
    item: {
        type: cItem,
        required: true
    }
});

</script>

<template>
    <v-chip-group class="text-secondary">
        <template v-if="item.peopleSaved.length > 0">

            <v-chip density="compact" variant="text" v-for="person in item.peopleSaved"
                @click.stop="filters.searchAddItem(person.getPerson())">{{ person.getSearchName() }}</v-chip>

        </template>
        <template v-else>
            <v-chip density="compact" variant="text" style="visibility: hidden;"></v-chip>
        </template>
    </v-chip-group>
</template>
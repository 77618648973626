<script setup lang="ts">
import { ref, watch, computed } from 'vue';
import { useItemCartStore } from '../stores/itemcart';
import ItemTable from '../components/table/ItemTable.vue';
import { cItem } from '../domain/item';
import Image from '../components/Image.vue';

const cart = useItemCartStore();

const props = defineProps({
    visible: {
        type: Boolean,
        required: true
    }
});

const emit = defineEmits<{
    (e: 'update:visible', value: boolean): void;
}>();

const selectedItemFromCart = computed(() => cart.selectedItem);

const dialogVisible = computed({
    get: () => props.visible,
    set: (value) => emit('update:visible', value)
});


async function itemRemove(pItem: cItem) {
    cart.toggleSelectedItem(pItem);
}

watch(dialogVisible, async () => {

    if (cart.itemSelectionAndNavigation.itemNavigation.getSelectedItemIndex == -1) {
        await cart.itemSelectionAndNavigation.itemNavigation.selectNextItem();
    }
});


</script>

<template>
    <div class="text-center">

        <v-dialog v-model="dialogVisible" width="auto">
            <v-card elevation="2" class="justify-center ma-2 w-100">
                <v-row>
                    <v-col cols="5">
                        <v-card-title>{{ cart.selectedItem.getName() }}
                            <Image :item="cart.selectedItem" :height="'200'" thumbnail="large">
                            </Image>
                        </v-card-title>
                    </v-col>
                    <v-col cols="2">
                    </v-col>
                </v-row>

                <ItemTable read-only show-actions height="auto"
                    :items="cart.itemSelectionAndNavigation.itemSelection.selectedItems.all()" :items-per-page="0"
                    :item-current="selectedItemFromCart"
                    @item-select="cart.itemSelectionAndNavigation.itemNavigation.selectItem"
                    @item-next="cart.itemSelectionAndNavigation.itemNavigation.selectNextItem"
                    @item-previous="cart.itemSelectionAndNavigation.itemNavigation.selectPreviousItem">

                    <template #action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ props }">
                                <v-icon v-bind="props" @click.stop.prevent="itemRemove(item)">mdi-delete
                                </v-icon>
                            </template>
                            <span>Remove</span>
                        </v-tooltip>
                    </template>

                </ItemTable>


                <v-card-text>

                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" block @click="dialogVisible = false">Close Dialog</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

    </div>
</template>


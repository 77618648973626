import axios from 'axios';
import { ItemData, ItemLocationData, ItemObjectData, ItemRotate, ItemShareData, PersonInterface, ItemPersonInterface, tPersonCombo, tTagTypeLocations, ObjectInterface, ItemObjectInterface, PropertyData, ItemPropertiesData } from 'rundown-common';

import { useItemStore } from '../stores/useItemStore';
import itemtag from '../services/itemtag';
import itemobject from '../services/itemobject';
import { useMessageStore } from '../stores/useMessageStore';
import { usePeopleStore } from '../stores/usePeopleStore';
import { ItemInterface } from 'rundown-common';
import { cItemPersonObject } from './ItemPersonObject';
import { cPerson } from './Person';
import { tObjectCombo } from '../../../vue-library/src';
import { cItemObject } from './ItemObject';
import { useObjectsStore } from '../stores/useObjectsStore';
import { cObject } from './Object';
import { Ref, ref } from 'vue';

export enum ThumbnailSize {
    Original = '',
    Tiny = 'tiny',
    Small = 'small',
    Medium = 'medium',
    Large = 'large',
    Variant = 'variant'
}

export class cItem implements ItemInterface {
    item: ItemData;
    datetaken: string | null = null;
    category: string = '';
    description: string = '';

    peopleSaved: Array<ItemPersonInterface> = [];
    people: Array<tPersonCombo> = [];

    objectSaved: Array<ItemObjectInterface> = [];
    objects: Array<tObjectCombo> = [];

    keywords: Array<string> = [];
    locations: tTagTypeLocations = [];

    updating: boolean = false;
    reloads: number = 0;

    /**
     * 
     */
    constructor(pItem: ItemData = { 'id': 0 } as ItemData) {
        this.item = pItem;

        this.load();
    };

    /**
     * Update the item
     */
    refresh(pItem: ItemData, pContext: any = {}) {
        if (this.updating)
            return;

        if (pContext?.ImageChanged ?? false) {
            this.reloads += 1;
        }

        this.item = pItem;
        this.load();

    }

    get reloadsCount() {
        return this.reloads;
    }

    /**
     * Is this item valid
     */
    isValid(): boolean {
        if (this.item == undefined || this.item.id == 0)
            return false;

        return true;
    };

    /**
     * Has Category or atleast one keyword
     */
    hasCategory(): boolean {
        return (this.category.length > 0)
    };

    isVideo(): boolean {
        return this.item.type == 'video';
    }

    isImage(): boolean {
        return this.item.type == 'image';
    }

    /**
     * Get the url to the video
     * 
     * @param pShared 
     * @returns 
     */
    getVideoUrl(pShared: string = '') {
        var path = "/v1/item/" + this.item.id;

        if (pShared.length > 0) {
            path = "/v1/share/" + pShared;
        }

        return axios.defaults.baseURL + path + "/stream";
    }

    /**
     * Get url to image
     */
    getImageUrl(pThumbnail: string | ThumbnailSize = ThumbnailSize.Original,
        pFormat: string = '', pShared: string = ''): string {
        var query = new URLSearchParams();

        if (pThumbnail != ThumbnailSize.Original) {
            query.append("pThumbnail", pThumbnail);
        }
        if (pFormat != '') {
            query.append('pFormat', pFormat);
        }

        var path = "/v1/item/" + this.item.id;
        if (pShared.length > 0) {
            path = "/v1/share/" + pShared;
        }
        
        if(this.item.properties?.Rotation)
            query.append('rotation', this.item.properties.Rotation.value);

        if (this.reloads) {
            query.append('reloads', new Date().getTime().toString());
        }
        return axios.defaults.baseURL + path + "/image?" + query.toString();
    };

    /**
     * Share an item for a period of hours
     * 
     * @param pHours 
     * @returns 
     */
    async share(pHours: number): Promise<ItemShareData | null> {
        return await useItemStore().share(this.getId(), pHours)
    };

    /**
     * Rotate an item
     * 
     * @param pDirection 
     * @returns 
     */
    async rotate(pDirection: ItemRotate) {
        return await useItemStore().rotate(this.getId(), pDirection);
    };
    
    /**
     * Get the rotation angle
     */
    getRotation(): number {
        const prop = this.getProperty("Rotation");
        return Number(prop ?? 0);
    };

    /**
     * Get the image blob
     */
    async getImageBlob(pThumbnail: ThumbnailSize, pFormat: string = '') {
        try {
            const resp = await axios.get(this.getImageUrl(pThumbnail, pFormat), { responseType: "blob" });
            return resp.data;
        } catch (error) {
            //messages.add(error);
        }
        return false;
    };

    getId(): number {
        return this.item.id;
    };

    getName(): string {
        return this.item.name;
    };

    getPath(): string {
        return this.item.path;
    };

    getProperties(): ItemPropertiesData {
        return this.item.properties;
    }

    getProperty(pName: string): string | null {
        const property = this.item.properties[pName];
        return property ? property.value : null;
    }
    
    getDateTakenAsDate(): Date {
        return new Date(this.datetaken ?? 'now');
    };

    getDateTaken(): string {
        if (this.datetaken == null)
            return '';

        return this.datetaken;
    };

    getDateTakenFormatted(): string {
        if (this.getDateTaken() == '')
            return 'Date Taken';

        return new Date(this.getDateTaken()).toLocaleString([], {
            year: 'numeric', month: '2-digit', day: '2-digit',
            hour: '2-digit', minute: '2-digit', hour12: false,
            timeZoneName: undefined
        });
    };

    getDateTakenFormattedLong(): string {
        if (this.getDateTaken() == '') {
            return '';
        }

        var options = {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        } as Intl.DateTimeFormatOptions;

        var date = new Date(this.getDateTaken());
        return date.toLocaleDateString(undefined, options);
    };

    async setDateTaken(pDateTime: string) {
        this.datetaken = pDateTime;
        await this.dateTakenUpdate();
    };

    async dateTakenClear() {
        this.datetaken = null;
        await this.dateTakenUpdate();
    }

    async dateTakenUpdate() {
        await this.save();
    };

    async descriptionUpdate() {
        await this.save();
    };

    async categoryClear() {
        this.category = '';
        this.categoryUpdate();
    };

    /**
     * Handle updated category
     */
    async categoryUpdate() {
        await this.save();
    };

    setCategory(pCategory: string) {
        this.category = pCategory;
        this.categoryUpdate();
    }

    /**
     * Add a keyword
     */
    async keywordAdd(pKeyword: string) {

        let found = this.keywords.find(function (item) {
            if (item == pKeyword) {
                return item;
            }
        });
        if (found !== undefined) {
            return;
        }

        this.keywords.push(pKeyword);
        this.keywordUpdated();
    };

    /**
     * Handle updated keywords
     */
    async keywordUpdated() {

        await this.save();
    };

    /**
     * Handle location updated
     */
    async locationUpdate(pNew: ItemLocationData) {
        this.locations[0] = pNew;
        await this.save();
    };

    /**
     * Update the location place name
     */
    async placeNameUpdate(pName: string) {
        var location = { 'id': 0 } as ItemLocationData;

        if (this.locations.length > 0) {
            location = this.locations[0];
        }

        location.placeName = pName;

        this.locationUpdate(location);
    };

    /**
     * Get a person object by the position
     */
    getPersonByPosition(pX: number, pY: number): ItemPersonInterface | null {
        return this.peopleSaved.find(person => person.isPositionInside(pX, pY)) ?? null;
    }

    /**
     * Get an object by the position
     */
    getObjectByPosition(pX: number, pY: number): ItemObjectInterface | null {
        return this.objectSaved.find(object => object.isPositionInside(pX, pY)) ?? null;
    }

    /**
     * Add a person
     */
    async personAdd(pPerson: PersonInterface | ItemPersonInterface): Promise<boolean> {
        if (pPerson instanceof cItemPersonObject) {
            const found = this.peopleSaved.some(item => item.getId() === pPerson.getId());
            if (found) {
                pPerson.save();
                return false;
            }
        }

        this.people.push(pPerson);
        await this.save();
        return true;
    }

    /**
     * Handle people updated
     */
    async peopleUpdate() {
        await this.save();
    };

    /**
     * Add an object
     */
    async objectAdd(pObject: ObjectInterface | ItemObjectInterface): Promise<boolean> {
        if (pObject instanceof cItemObject) {
            const found = this.objectSaved.some(item => item.getId() === pObject.getId());
            if (found) {
                pObject.save();
                return false;
            }
        }

        this.objects.push(pObject);
        await this.save();
        return true;
    }

    async objectUpdate(): Promise<void> {
        await this.save();
    }

    /**
     * 
     */
    load() {
        if (!this.isValid() || this.updating)
            return;

        if (this.item.tags.DateTimeOriginal === undefined) {
            this.item.tags.DateTimeOriginal = { 'id': 0, 'data': '' };
        }

        this.datetaken = String(this.item.tags.DateTimeOriginal.data);

        if (this.item.tags.People === undefined) {
            this.item.tags.People = { 'id': 0, 'data': [] };
        }

        this.loadPeople()
        this.loadObjects();

        if (this.item.tags.Category === undefined) {
            this.item.tags.Category = { 'id': 0, 'data': [] };
        }

        this.category = this.item.tags.Category.data[0]?.content ?? '';

        if (this.item.tags.Keywords === undefined) {
            this.item.tags.Keywords = { 'id': 0, 'data': [] };
        }

        this.keywords = JSON.parse(JSON.stringify(this.item.tags.Keywords.data.map(keyword => keyword.content)));

        if (this.item.tags.Locations === undefined) {
            this.item.tags.Locations = { 'id': 0, 'data': [] };
        }

        this.locations = JSON.parse(JSON.stringify(this.item.tags.Locations.data));

        if (this.item.tags.Description === undefined) {
            this.item.tags.Description = { 'id': 0, 'data': '' };
        }

        this.description = this.item.tags.Description.data;
    };

    /**
     * 
     */
    async save() {
        if (!this.isValid())
            return;

        this.updating = true;

        const messages = useMessageStore();

        try {
            await itemtag.set(this.item, 'DateTimeOriginal', this.datetaken);
        } catch (error) {
            messages.add('Failed to save date and time');
        }

        try {
            await itemtag.set(this.item, 'Description', this.description);
        } catch (error) {
            messages.add('Failed to save description');
        }

        try {
            await itemtag.setStrings(this.item, 'Category', [this.category]);
        } catch (error) {
            messages.add('Failed to save category');
        }

        try {
            await itemtag.setStrings(this.item, 'Keywords', this.keywords);
        } catch (error) {
            this.keywords = JSON.parse(JSON.stringify(this.item.tags.Keywords?.data.map(keyword => keyword.content)));

            messages.add('Failed to save keywords');
        }

        try {
            await itemtag.set(this.item, 'Locations', this.locations);
        } catch (error) {
            messages.add('Failed to save location');
        }

        await this.savePeople();
        await this.saveObjects();

        this.updating = false;
    };

    /**
     * Delete an item
     */
    async delete(): Promise<boolean> {
        const messages = useMessageStore();
        try {
            const data = await axios.delete('/v1/item/' + this.getId().toString());
            
            if(data.status == 204) {
                return true;
            }

        }
        catch (error) {
            messages.handleAxiosError(error);
        }
        return false;
    }

    /**
     * Load the people
     */
    loadPeople() {
        this.people = [];
        this.peopleSaved = [];
        this.item.tags.People?.data.forEach((pObjectData: ItemObjectData) => {
            const obj = new cItemPersonObject(pObjectData, this);
            this.peopleSaved.push(obj);
            this.people.push(obj);
        })

    };

    /**
     * Load the objects
     */
    loadObjects() {
        this.objects = [];
        this.objectSaved = [];
        this.item.tags.Object?.data.forEach((pObjectData: ItemObjectData) => {
            const obj = new cItemObject(pObjectData, this);
            this.objectSaved.push(obj);
            this.objects.push(obj);
        })

    };

    /**
     * 
     */
    async peopleCopyFrom(pItem: ItemInterface): Promise<void> {
        this.people = [];

        for (const pPerson of pItem.peopleSaved) {
            let object = new cItemPersonObject(pPerson.object, this);
            await object.positionFrom(pPerson);
            this.people.push(object);
        }

        await this.savePeople();
    }

    /**
     * Save People changes
     */
    async savePeople(): Promise<void> {
        const people = usePeopleStore();
        const messages = useMessageStore();

        // Convert existing people to a set of person IDs
        const peopleIds = new Set(this.people.map(element => {
            if (element instanceof cItemPersonObject) {
                return element.getId();
            }
            return null;
        }).filter(id => id !== null));

        // Find and process missing items
        for (const pPersonObject of this.peopleSaved) {
            const savedId = pPersonObject.getId();

            if (!peopleIds.has(savedId)) {
                if (!(await pPersonObject.delete())) {
                    messages.add('Failed to remove person');
                }
            }
        }

        const peopleTemp: Array<ItemPersonInterface> = [];

        // Process each person in people
        for (const element of this.people) {
            if (element instanceof cPerson) {
                // Process existing person
                let object = new cItemPersonObject(element, this);
                if (await object.save())
                    peopleTemp.push(object);
            } else if (element instanceof cItemPersonObject) {
                // Process existing item in an object
                // Assuming some processing needs to be done here
                await element.save();
                peopleTemp.push(element);
                continue;

            } else if (typeof element === 'string') {
                // Process new Person
                let object = new cItemPersonObject(await people.createPerson(element), this);
                if (await object.save())
                    peopleTemp.push(object);
            } else {
                messages.add('Failed to add person');
            }
        }

        this.people = peopleTemp.slice();
        this.peopleSaved = peopleTemp.slice();
    }

    /**
     * Save Object changes
     */
    async saveObjects(): Promise<void> {
        const objects = useObjectsStore();
        const messages = useMessageStore();

        // Convert existing people to a set of person IDs
        const objectIds = new Set(this.objects.map(element => {
            if (element instanceof cItemObject) {
                return element.getId();
            }
            return null;
        }).filter(id => id !== null));

        // Find and process missing objects
        for (const pObject of this.objectSaved) {
            const savedId = pObject.getId();

            if (!objectIds.has(savedId)) {
                if (!(await pObject.delete())) {
                    messages.add('Failed to remove object');
                }
            }
        }

        const objectTemp: Array<ItemObjectInterface> = [];

        // Process each object
        for (const element of this.objects) {
            if (element instanceof cObject) {
                // Process existing object
                let object = new cItemObject(element, this);
                if (await object.save())
                objectTemp.push(object);
            } else if (element instanceof cItemObject) {
                // Process existing item in an object
                // Assuming some processing needs to be done here
                await element.save();
                objectTemp.push(element);
                continue;

            } else if (typeof element === 'string') {
                // Process new object
                let object = new cItemObject(await objects.createObject(element, '', null), this);
                if (await object.save())
                objectTemp.push(object);
            } else {
                messages.add('Failed to add object');
            }
        }

        this.objects = objectTemp.slice();
        this.objectSaved = objectTemp.slice();
    }

}
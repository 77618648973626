<script setup lang="ts">
import { PropType } from 'vue';
import { cObject } from '../../domain/Object';
import { ObjectInterface, PropertyData } from 'rundown-common';
import { computed } from 'vue';
import { useObjectsStore } from '../../stores/useObjectsStore';

const props = defineProps({
    object: {
        type: Object as PropType<ObjectInterface>,
        required: true
    },
});

const objectstore = useObjectsStore();

const activeObject = computed(() => props.object);
const headers = [
    { title: 'Name', key: 'name', width: '350px' },
    { title: 'Value', key: 'value' },
    { title: '', key: 'actions', sortable: false },
];

const editableProperties = computed(() => [...activeObject.value.properties.filter((property: PropertyData) => !property.hidden), { id: -1, name: '', value: '' }]);

const isPlaceholderRow = (item: PropertyData) => {
    return item.id === -1;
};

const addProperty = (pItem: PropertyData) => {
    activeObject.value.addProperty(pItem.name, pItem.value);
    pItem.name = '';
    pItem.value = '';
}

const deleteProperty = (pItem: PropertyData) => {
    activeObject.value.deleteProperty(pItem);
}
</script>

<template>

    <v-text-field v-model="activeObject.name" color="primary" label="Name" variant="underlined"></v-text-field>

    <v-textarea clearable rows=2 label="Description" color="primary" variant="underlined"></v-textarea>

    <v-combobox placeholder="Enter name" density="compact" v-model="activeObject.parent" :items="objectstore.get"
        item-title="name" hide-no-data clearable hide-details label="Parent Object" return-object>
    </v-combobox>

    <v-card flat variant="elevated" class="mt-5">
        <v-card-title class="text-subtitle-2">Properties</v-card-title>
        <v-data-table-virtual :headers="headers" :items="editableProperties" item-key="name" class="elevation-1">

            <template v-slot:[`item.name`]="{ item, index }">

                <v-combobox v-if="isPlaceholderRow(item)" placeholder="Enter name" density="compact" v-model="item.name"
                    :items="objectstore.getNames" hide-no-data clearable hide-details label="New property name">
                </v-combobox>

                <span v-else>{{ item.name }}</span>
            </template>

            <template v-slot:[`item.value`]="{ item, index }">
                <v-text-field v-if="isPlaceholderRow(item)" density="compact" v-model="item.value"
                    placeholder="Enter value" hide-details>
                </v-text-field>
                <span v-else>{{ item.value }}</span>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
                <v-btn v-if="isPlaceholderRow(item)" icon="mdi-plus" color="primary" @click="addProperty(item)"
                    size="x-small">

                </v-btn>
                <v-btn v-else icon="mdi-delete" color="red" @click="deleteProperty(item)" size="x-small">

                </v-btn>
            </template>

        </v-data-table-virtual>
    </v-card>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted } from "vue";
  import { useFilterStore } from "../stores/useFilterStore";
  import { useItemsOnThisDayStore } from "../stores/useItemsOnThisDayStore";
  import { useSettingsStore } from "../stores/useSettingsStore";
  import Image from "../components/Image.vue";
  import ItemMenu from "../menu/ContextMenuItem.vue";
  import { ref } from "vue";

  const items = useItemsOnThisDayStore();
  const settings = useSettingsStore();

  var colors = [
    "red",
    "blue",
    "green",
    "indigo",
    "purple",
    "teal",
    "orange",
    "brown",
    "deep-orange",
    "blue-grey",
    "cyan",
  ];

  function getColor(i) {
    return colors[i % colors.length];
  }

  function getYear(pYear: string) {
    var year = new Date().getFullYear();
    if (pYear == year.toString()) return "Today";

    year -= Number(pYear);
    return pYear + " (" + year + " years ago today)";
  }

  onMounted(async () => {
    items.load();
  });

  onUnmounted(async () => {});

  const menuX = ref(0);
  const menuY = ref(0);
  const contextMenuId = ref<null | number>(null);

  /**
   * Show the context menu
   */
  function menuShow(pId: number, pEvent: MouseEvent) {
    menuX.value = pEvent.clientX;
    menuY.value = pEvent.clientY;
    contextMenuId.value = pId;
  }

  /**
   * Hide the context menu
   */
  function menuHide(pEvent: Event | undefined) {
    if (contextMenuId.value != null && pEvent) pEvent.stopPropagation();

    contextMenuId.value = null;
  }

  const isMenuVisible = (pId: number) => {
    return contextMenuId.value === pId;
  };
</script>

<template>
  <div
    v-if="Object.keys(items.getOnThisDay).length === 0"
    class="d-flex justify-center mb-6"
  >
    <h2>No items today!</h2>
  </div>

  <template v-if="!settings.isScreenSmall">
    <v-timeline>
      <v-timeline-item
        v-for="(yearItems, year, i) in items.getOnThisDay"
        :key="year"
        :dot-color="getColor(i)"
        size="small"
      >
        <template v-slot:opposite>
          <div
            :class="`pt-1 font-weight-bold text-${getColor(i)}`"
            v-text="getYear(year)"
          ></div>
        </template>

        <template v-for="(item, x) in yearItems">
          <v-lazy>
            <div class="mx-0">
              <h2 :class="` font-weight-light mb-4 text-${getColor(i)}`">
                {{ item.category }}
              </h2>
              <div
                @contextmenu.prevent="menuShow(item.getId(), $event)"
                @click.capture="menuHide"
              >
                <ItemMenu
                  :item="item"
                  :show="isMenuVisible(item.getId())"
                  @menu-close="menuHide"
                  :positionX="menuX"
                  :positionY="menuY"
                ></ItemMenu>

                <Image
                  :item="item"
                  :thumbnail="settings.getImageThumb"
                  :width="settings.getOnThisDayImageWidth"
                >
                </Image>
              </div>
            </div>
          </v-lazy>
        </template>
      </v-timeline-item>
    </v-timeline>
  </template>
  <template v-else>
    <div
      v-for="(yearItems, year, i) in items.getOnThisDay"
      :key="year"
      :dot-color="getColor(i)"
    >
      <div
        :class="`pt-1 text-center headline font-weight-bold text-${getColor(
          i
        )}`"
      >
        <span>{{ getYear(year) }}</span>
      </div>

      <template v-for="(item, x) in yearItems">
        <v-lazy>
          <div class="mx-auto">
            <h2
              :class="`mt-n1 headline text-center font-weight-light mb-4 text-${getColor(
                i
              )}`"
            >
              {{ item.category }}
            </h2>
            <div>
              <Image
                :item="item"
                :thumbnail="settings.getImageThumb"
                :max-height="settings.getImageCardSize.toString()"
              >
              </Image>
            </div>
          </div>
        </v-lazy>
      </template>
    </div>
  </template>
</template>

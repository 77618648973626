<script setup lang="ts">
  import { useItemRecycledStore } from "../../stores/useItemRecycledStore";
  import { useItemRecycledSlidingWindowStore } from "../../stores/useItemRecycledSlidingWindowStore";
  import ItemCleanupCard from "./Item/AdminItemCleanupCard.vue";
  import { cItem } from "../../domain/item";

  const items = useItemRecycledStore();
  const itemWindow = useItemRecycledSlidingWindowStore();

  const handleRemoveItem = (item: cItem) => {
    items.remove(item);
  };

  const handleRemoveAllItems = () => {
    items.removeAll();
  };
</script>

<template>
  <ItemCleanupCard
    description="A recycled item indicates the item has been deleted"
    noitemsMsg="Recycle bin is empty"
    :itemStore="items"
    :itemWindowStore="itemWindow"
    :itemColumns="['name', 'deleted', 'date', 'category']"
    @removeItem="handleRemoveItem"
    @removeAllItems="handleRemoveAllItems"
  />
</template>

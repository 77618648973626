<script setup lang="ts">
import { computed, ref } from 'vue';
import { FilterMode, useFilterStore } from '../../stores/useFilterStore';
import { usePeopleStore } from '../../stores/usePeopleStore';
import PersonDetails from './people/PeopleDetails.vue';
import { cPerson } from '../../domain/Person';

const perPage = 15;

const filters = useFilterStore();
const people = usePeopleStore();

const page = ref(1);
const pageIndex = computed(() => ((page.value - 1) * perPage));
const pages = computed(() => {
    const realSize = Object.keys(people.get).length;
    return Math.ceil(realSize / perPage);
});
var current = computed(() => {
    const personFilters = filters.getSearchboxModel.flatMap(pFilterGroup => 
        pFilterGroup.getPeople()
    );

    let filteredPeople;

    if (personFilters.length === 0) {
        filteredPeople = people.get;
    } else {
        filteredPeople = people.get.filter(element => {
            return personFilters.some(pFilter => {
                if (pFilter.mode == FilterMode.NOT) {
                    return pFilter.id() !== element.getId();
                }
                return pFilter.id() === element.getId() && pFilter.mode == FilterMode.AND;
            });
        });
    }

    return extractItems(filteredPeople, pageIndex.value, perPage);
});

function extractItems(array: cPerson[], startIndex: number, count: number) {
    const keys = Object.keys(array).sort((a, b) => parseInt(a) - parseInt(b));
    const selectedKeys = keys.slice(startIndex, startIndex + count);
    return selectedKeys.map((key)=> array[Number(key)]);
}

async function exportPeople() {
    await people.export();
}

async function removeUnused() {
    await people.removeUnused();
    await people.load();
}

</script>

<template>
    <v-card elevation="2" class="justify-center my-4 mx-auto w-50">
        <div class="d-flex justify-space-around align-center flex-column flex-sm-row fill-height">
            <v-btn prepend-icon="mdi-export" @click="exportPeople">
                Export All
            </v-btn>
            <v-btn prepend-icon="mdi-export" @click="removeUnused">
                Remove unused
            </v-btn>
        </div>
        <v-pagination v-model="page" :length="pages"></v-pagination>
        <v-expansion-panels>
            <v-expansion-panel v-for="person in current" :key="person.getId()">
                <v-expansion-panel-title>
                    <template v-slot:default="{ expanded }">
                        <v-row no-gutters>
                            <v-col cols="4" class="d-flex justify-start">
                                Person
                            </v-col>
                            <v-col cols="8" class="text-grey">
                                <v-fade-transition leave-absolute>
                                    <span v-if="expanded" key="0">
                                        Edit person details
                                    </span>
                                    <span v-else key="1">
                                        {{ person.getCurrentName().name }}
                                    </span>
                                </v-fade-transition>
                            </v-col>
                        </v-row>
                    </template>
                </v-expansion-panel-title>
                <v-expansion-panel-text>

                    <PersonDetails :person="person"></PersonDetails>

                </v-expansion-panel-text>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-card>
</template>
<script setup lang="ts">
  import { ref } from "vue";
  import { useKeywordStore } from "@/stores/useKeywordStore";

  const keywordStore = useKeywordStore();

</script>

<template>
  <v-data-table
    hover
    :headers="[
      { title: 'Keyword', value: 'content', width: '20%' },
      { title: 'Hidden', value: 'isHidden', width: '4%' },
      {
        title: 'Associated Items Hidden',
        value: 'isAssociatedItemsHidden',
        width: '4%',
      },
    ]"
    :items="keywordStore.getAdmin"
  >
    <template v-slot:item="{ item }">
      <tr>
        <td>{{ item.getContent() }}</td>
        <td>
          <v-tooltip text="Exclude this keyword from search autocomplete">
            <template v-slot:activator="{ props }">
              <v-checkbox
                v-bind="props"
                v-model="item.keystring.isHidden"
                @change="async () => await item.save()"
              ></v-checkbox>
            </template>
          </v-tooltip>
        </td>
        <td>
          <v-tooltip text="Items tagged with this keyword are hidden by default">
            <template v-slot:activator="{ props }">
              <v-checkbox
                v-bind="props"
                v-model="item.keystring.isAssociatedItemsHidden"
                @change="async () => await item.save()"
              ></v-checkbox>
            </template>
          </v-tooltip>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

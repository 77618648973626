import { ref, watch } from "vue";
import { defineStore } from "pinia";
import { useAuthStore } from "./useAuthStore";
import { useItemStore } from "./useItemStore";
import { collectionAll, useCollectionStore } from "./useCollectionStore";
import { useKeywordStore } from "./useKeywordStore";

import { useFilterStore } from "./useFilterStore";
import { usePeopleStore } from "./usePeopleStore";
import { usePluginStore } from "./usePluginStore";
import { useSettingsStore } from "./useSettingsStore";
import { useObjectsStore } from "./useObjectsStore";
import { CollectionData } from "rundown-common";
import { useWebSocketStore } from "./useWebSocketStore";

export const useLoaderStore = defineStore("loader", () => {
  const previousIncludeHidden = ref(useFilterStore().$state.includeHidden);

  function initialize() {
    load();
  }

  // Watch for auth changes
  watch(
    () => useAuthStore().isAuthed,
    async function () {
      load();
    }
  );

  // Watch for filter changes
  watch(
    () => useFilterStore().$state,
    async (newState, oldState) => {
      // Wait until height is set
      if (useSettingsStore().getAvailableHeight === 0) return;

      // Only load all if includeHidden has changed
      if (newState.includeHidden !== previousIncludeHidden.value) {
        previousIncludeHidden.value = newState.includeHidden;
        useKeywordStore().loadAll();
      }

      // Always update load
      useItemStore().load();
    },
    { deep: true }
  );

  /**
   * Attach to each object channel on the websocket
   */
  const websocketAttach = async (pCollection: CollectionData) => {
    const websocket = useWebSocketStore();
    await websocket.joinChannel(
      "collection-" + pCollection.id,
      useCollectionStore().websocketMessage
    );
    await websocket.joinChannel(
      "object-" + pCollection.id,
      useObjectsStore().websocketMessage
    );
    await websocket.joinChannel(
      "person-" + pCollection.id,
      usePeopleStore().websocketMessage
    );
    await websocket.joinChannel("plugins", usePluginStore().websocketMessage);
  };

  async function load() {
    const pluginStore = usePluginStore();
    const collectionStore = useCollectionStore();
    const peopleStore = usePeopleStore();
    const keywordStore = useKeywordStore();
    const objectsStore = useObjectsStore();
    const itemStore = useItemStore();

    collectionStore.setSelected(collectionAll);

    await Promise.all([
      pluginStore.load(),
      collectionStore.load(),
      peopleStore.load(),
      keywordStore.loadAll(),
      objectsStore.loadAll(),
      itemStore.load(),
    ]);

    // Process collections after all asynchronous operations are complete
    collectionStore.collections.forEach((pCollection: CollectionData) => {
      websocketAttach(pCollection);
    });
  }

  initialize();

  return { load, websocketAttach };
});

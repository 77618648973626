<script setup lang="ts">
import { useRouter } from 'vue-router'
import { PropType, ref, watch } from 'vue'
import { useDisplay } from 'vuetify';
import { cItem } from '../domain/item';
import { useItemStore } from '../stores/useItemStore';
import { useMessageStore } from '../stores/useMessageStore';
import { useSettingsStore } from '../stores/useSettingsStore';
import PanelItem from '../components/PanelItem.vue';
import DialogItem from '../dialogs/DialogItem.vue';

const items = useItemStore();
const settings = useSettingsStore();

const item = ref(new cItem());
const router = useRouter();

const props = defineProps({
    item: {
        type: Object as PropType<cItem>,
        required: false
    },
    itemid: {
        type: String,
        required: false
    },
    name: {
        type: String,
        required: false
    },
    show: {
        type: Boolean,
        default: true,
        required: false
    }
});

const visible = ref(true);

watch(props, () => {
    itemLoad();
    visible.value = props.show;
}, { immediate: true });

async function itemLoad() {
    if (props.item !== undefined) {
        item.value = props.item;
    } else {
        if (props.name !== undefined) {
            try {
                item.value = await items.getShared(props.name);
            } catch (error) {
                const messages = useMessageStore();
                messages.add("Shared link is not active");
                router.push({ path: "/", replace: true });
                return;
            }
        } else {
            if (props.itemid != undefined) {
                item.value = await items.getByID(Number(props.itemid));
            }
        }
    }
}

function calcImageHeight(): number {
    const { name, height } = useDisplay();
    return Math.floor(((height.value) - settings.getHeaderSize));
}

const showDialogItem = ref(true);

function dialogItemClosed() {
    
}
</script>

<template>

    <DialogItem :item="item" :shared="name" is-dialog :show="showDialogItem" @closed="dialogItemClosed"></DialogItem>


</template>

<style scoped>
.image-full-screen img {
    object-fit: contain;
}
</style>../stores/useItemStore
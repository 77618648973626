<script setup lang="ts">
import { ref, onMounted, PropType } from 'vue';
import { useScreenSaverStore, iScreenSaver } from '../../stores/useScreenSaverStore';

const screens = useScreenSaverStore();

const creating = ref(false);
const createName = ref("");
const createRandom = ref(false);
const createSecondsPerPic = ref(5);

const props = defineProps({
    screensaver: {
        type: Object as PropType<iScreenSaver>,
        required: false
    },
    title: {
        type: String,
        required: false,
        default: "Screen Savers"
    }
});

const emit = defineEmits<{
    (e: 'select-screensaver', pSaver: iScreenSaver): void
}>();


function saverDelete(saver: iScreenSaver) {
    screens.remove(saver);
}

function saverClick(saver: iScreenSaver) {
    emit('select-screensaver', saver);
}

async function createClick() {
    creating.value = false;
    await screens.create(createName.value, createRandom.value, createSecondsPerPic.value);
    createName.value = '';
}

async function createCancel() {
    creating.value = false;
}

onMounted(async function () {
    await screens.load();
});

</script>

<template>
    <v-container @click="saverClick({} as iScreenSaver)">
        <v-card :title="title">

            <v-row class="justify-center">

                <v-table density="compact" >
                    <thead>
                        <tr>
                            <th class="text-left" style="width:240px">
                                Name
                            </th>
                            <th class="text-left" style="width:120px">
                                Random
                            </th>
                            <th class="text-left">

                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="saver in screens.getScreenSavers" :key="saver.id"
                            :class="{ 'bg-primary': saver.id == screensaver?.id, 'row-pointer': true }"
                            @click.stop="saverClick(saver)">

                            <td>{{ saver.name }}</td>
                            <td>{{ saver.random }}</td>
                            <td>
                                <v-tooltip location="start">
                                    <template v-slot:activator="{ props }">
                                        <v-icon v-bind="props" @click="saverDelete(saver)">
                                            mdi-delete-forever
                                        </v-icon>
                                    </template>
                                    <span>Delete ScreenSaver</span>
                                </v-tooltip>
                            </td>

                        </tr>
                    </tbody>
                </v-table>
            </v-row>

            <v-row class="mt-12  mb-3 justify-center">
                <v-btn color="primary" @click="creating = true">
                    Create
                </v-btn>
            </v-row>

        </v-card>
    </v-container>

    <v-dialog v-model="creating" persistent width="300">
        <v-card>
            <v-card-title class="text-h5">Create ScreenSaver</v-card-title>
            <v-card-text>
                <v-text-field v-model="createName" color="primary" label="Name" variant="underlined"></v-text-field>
                <v-switch v-model="createRandom" color="primary" label="Random"></v-switch>
                <div class="text-caption">Seconds Per Picture</div>
                <v-slider min=5 max=60 step=1 v-model="createSecondsPerPic" label="" thumb-label="always"></v-slider>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="createCancel()">
                    Cancel
                </v-btn>
                <v-btn color="primary" @click="createClick()">
                    Create
                </v-btn>

            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script setup lang="ts">
  import { computed, watch } from "vue";
  import { useLoaderStore } from "./stores/useLoaderStore";

  import { useSettingsStore } from "./stores/useSettingsStore";
  import { useDisplay } from "vuetify/lib/framework.mjs";
  import { useMessageStore } from "./stores/useMessageStore";

  import Appbar from "./toolbars/appbar.vue";
  import Sidebar from "./toolbars/sidebar.vue";
  import { EventType } from "./stores/useWebSocketStore";

  const settings = useSettingsStore();
  const messages = useMessageStore();
  const { name, height } = useDisplay();

  // Messages
  var snackbarClient = computed({
    get(): boolean {
      return messages.hasClient;
    },
    set(newValue) {
      messages.remove("client");
    },
  });

  // Error Messages
  var snackbarError = computed({
    get(): boolean {
      return messages.hasError;
    },
    set(newValue) {
      messages.remove(EventType.Error);
    },
  });

  // Monitor for screen size changes
  watch(
    [height, name],
    function () {
      settings.setScreenSize(name.value);
      settings.calculateItemsPerPage(height);
    },
    { immediate: true }
  );

  useLoaderStore();
</script>

<template>
  <v-app>
    <Appbar></Appbar>
    <v-snackbar
      v-model="snackbarClient"
      location="top"
      :timeout="5000"
      color="blue-grey"
      elevation="24"
      class="mt-12"
    >
      <span class="justify-center">
        {{ messages.getClient }}
      </span>
      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="messages.remove('client')"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="snackbarError"
      :timeout="-1"
      color="primary"
      variant="tonal"
    >
      <span class="justify-center">
        {{ messages.getError.message }}
      </span>
      <template v-slot:actions>
        <v-btn
          color="blue"
          variant="text"
          @click="messages.remove(EventType.Error)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <Sidebar></Sidebar>

    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

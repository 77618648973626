import '@vuepic/vue-datepicker/dist/main.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from "./router"
import vuetify from './vue-plugins/vuetify'
import { pinia } from './vue-plugins/pinia'
import Datepicker from '@vuepic/vue-datepicker'

import VueMapboxTs from "vue-mapbox-ts"
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueFullscreen from 'vue-fullscreen'

import 'vuefinder/dist/style.css'
import VueFinder from 'vuefinder'
import { getConfig } from './config'

import VueVideoPlayer from '@videojs-player/vue'
import 'video.js/dist/video-js.css'


getConfig()
  .then((config) => {

    const app = createApp(App)
    .use(VueAxios, axios)
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(VueMapboxTs)
    .use(VueFullscreen)
    .use(VueFinder)
    .use(VueVideoPlayer)
    .component('Datepicker', Datepicker);

  axios.defaults.withCredentials = true;
  axios.defaults.baseURL = config.api;
  
  app.config.globalProperties.mapboxToken = config.mapbox_token;
  
  //import ItemDate from './components/item/ItemDate.vue';
  //app.component("ItemDate", ItemDate);

  app.provide('axios', app.config.globalProperties.axios)  // provide 'axios'
    .mount('#app')
  
  })


<script setup lang="ts">
  import { ref, watch } from "vue";
  import { useAuthStore } from "../../stores/useAuthStore";

  const auth = useAuthStore();

  const usernameRules = [
    (v: string) => !!v || "Username is required",
    (v: string) => v.length <= 20 || "Username must be less than 20 characters",
  ];

  const passwordRules = [
    (v: string) => !!v || "Password is required",
    (v: string) => v.length > 2 || "Password must be more than 2 characters",
  ];

  const valid = ref(false);
  const loading = ref(false);
  const form = ref<any | null>(null);

  const username = ref("");
  const password = ref("");

  async function login() {
    loading.value = true;
    if ((await auth.login(username.value, password.value)) == true) {
      auth.showLoginDialog = false;
    }
    loading.value = false;
  }

  function validate() {
    setTimeout(function () {
      form.value?.validate();
    }, 100);
  }

  function register() {
    auth.cancelLogin();
    auth.showRegisterDialog = true;
  }

  watch(() => auth.showLoginDialog, validate);
</script>
<template>
  <v-dialog
    v-model="auth.showLoginDialog"
    @click:outside="auth.cancelLogin()"
  >
    <v-card
      class="mx-auto px-8 py-8"
      min-width="300"
      max-width="500"
    >
      <v-form
        ref="form"
        v-model="valid"
        :lazy-validation="true"
      >
        <v-text-field
          v-model="username"
          :readonly="loading"
          label="Username"
          :rules="usernameRules"
          variant="underlined"
          color="primary"
          clearable
          required
          autofocus
          dense
          autocomplete="username"
        >
        </v-text-field>

        <v-text-field
          v-model="password"
          :readonly="loading"
          label="Password"
          :rules="passwordRules"
          type="password"
          color="primary"
          variant="underlined"
          clearable
          placeholder="Enter your password"
          required
          dense
          autocomplete="current-password"
        ></v-text-field>
      </v-form>
      <v-card-actions>
        <v-btn
          :disabled="!valid"
          :loading="loading"
          block
          color="success"
          size="large"
          type="submit"
          variant="elevated"
          @click="login"
        >
          Sign In
        </v-btn>
      </v-card-actions>

      <v-divider class="my-5"></v-divider>

      <p class="text-center mx-0 py-0 font-weight-thin">
        <a
          href="#"
          @click.prevent="register"
          >New User? Register here</a
        >
      </p>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-background-clip: text;
  }
</style>

<script setup lang="ts">
  import { ref, onMounted, onBeforeUnmount } from "vue";
  import { useAuthStore } from "../../stores/useAuthStore";

  const usernameRules = [
    (v: string) => !!v || "Username is required",
    (v: string) => v.length <= 20 || "Username must be less than 20 characters",
  ];

  const nameRules = [
    (v: string) => !!v || "Name is required",
    (v: string) => v.length <= 40 || "Name must be less than 40 characters",
  ];

  const emailRules = [
    (v: string) => !!v || "E-mail is required",
    (v: string) => /.+@.+/.test(v) || "E-mail must be valid",
  ];

  const auth = useAuthStore();

  const valid = ref(false);
  const username = ref("");
  const name = ref("");
  const email = ref("");
  const password = ref("");
  const loading = ref(false);
  const registrationSuccess = ref<string | null>(null);
  const registrationStyle = ref<string>("");
  let successTimeout: number | null = null;
  let failureTimeout: number | null = null;

  async function register() {
    loading.value = true;
    registrationSuccess.value = null;
    registrationStyle.value = "";

    const result = await auth.register(
      username.value,
      name.value,
      email.value,
      password.value
    );

    if (result === true) {
      registrationSuccess.value = "Registration successful!";
      registrationStyle.value = "color: green; font-size: 1.2em;";
      successTimeout = window.setTimeout(() => {
        auth.showRegisterDialog = false;
        auth.showLoginDialog = true;
      }, 9000);
    } else {
      registrationSuccess.value =
        result || "Registration failed. Please try again.";
      registrationStyle.value =
        "color: #d9534f; font-size: 1.2em;  padding: 10px; border-radius: 5px;";
      failureTimeout = window.setTimeout(() => {
        registrationSuccess.value = "";
        registrationStyle.value = "";
      }, 3000);
    }

    loading.value = false;
  }

  function handleOk() {
    auth.showRegisterDialog = false;
    auth.showLoginDialog = true;
  }

  onMounted(() => {
    loading.value = false;
    registrationSuccess.value = "";
    registrationStyle.value = "";
  });

  onBeforeUnmount(() => {
    if (successTimeout) clearTimeout(successTimeout);
    if (failureTimeout) clearTimeout(failureTimeout);
  });
</script>

<template>
  <v-dialog v-model="auth.showRegisterDialog">
    <v-card
      class="mx-auto px-8 py-4"
      min-width="300"
      max-width="500"
    >
      <span class="text-h6 text-center pb-6 mt-0">Create Account</span>

      <template v-if="!registrationSuccess">
        <v-form v-model="valid">
          <v-text-field
            v-model="username"
            label="Username"
            :rules="usernameRules"
            required
            variant="underlined"
            autocomplete="username"
          ></v-text-field>

          <v-text-field
            v-model="password"
            label="Password"
            type="password"
            required
            variant="underlined"
            autocomplete="current-password"
          ></v-text-field>

          <v-text-field
            v-model="name"
            label="Name"
            :rules="nameRules"
            required
            variant="underlined"
          ></v-text-field>

          <v-text-field
            v-model="email"
            label="Email"
            :rules="emailRules"
            required
            variant="underlined"
          ></v-text-field>
        </v-form>

        <v-card-actions class="mt-4">
          <v-btn
            :disabled="!valid"
            :loading="loading"
            block
            color="success"
            size="large"
            type="submit"
            variant="elevated"
            @click="register"
          >
            Create
          </v-btn>
        </v-card-actions>
      </template>

      <template v-else>
        <div
          class="mt-4 mb-6 text-center"
          :style="registrationStyle"
        >
          <span>{{ registrationSuccess }}</span>
        </div>
        <v-card-actions class="mt-4">
          <v-btn
            block
            color="success"
            size="large"
            variant="elevated"
            @click="handleOk"
          >
            OK
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

import { defineStore } from "pinia";
import axios from "axios";
import { useMessageStore } from "./useMessageStore";
import { KeyStringData } from "rundown-common";
import { cKeyString } from "../domain/Keystring";
import { useFilterStore } from "./useFilterStore";

export const useKeywordStore = defineStore("keywords", {
  state: () => ({
    all: [] as string[],
    keywords: [] as string[],
    categories: [] as string[],
    allData: [] as cKeyString[]
  }),

  getters: {
    getAll(state) {
      return state.all;
    },
    getKeywords(state) {
      return state.keywords;
    },
    getCategories(state) {
      return state.categories;
    },
    getAdmin(state) {
      return state.allData;
    }
  },

  actions: {
    /**
     * Load all keywords and categories
     */
    async loadAll() {
      this.all = await this.load("");
      this.keywords = await this.load("keywords");
      this.categories = await this.load("category");
      await this.loadAdmin();
    },

    async loadAdmin() : Promise<void> {
      const keyStrings = await this.loadAllKeystrings();
      this.allData = keyStrings.map((ks: KeyStringData) => new cKeyString(ks));
    },

    /**
     *
     */
    async load(pType: string) {
      const messages = useMessageStore();
      const filters = useFilterStore();
    
      try {
        const params = new URLSearchParams();
    
        if (filters.includeHidden.length) {
          params.append("includeHidden", String(filters.includeHidden));
        }

        const endpoint = pType ? `/v1/keystrings/bytag/${pType}` : "/v1/keystrings";
        const { data } = await axios.get(endpoint + "?" + params.toString());
    
        return data;
      } catch (error) {
        messages.handleAxiosError(error, `Failed to load keystrings (${pType})`);
      }
    },

    /**
     *
     */
    async loadAllKeystrings() {
      const messages = useMessageStore();
      const filters = useFilterStore();
    
      try {
        const params = new URLSearchParams();
    
        if (filters.includeHidden.length) {
          params.append("includeHidden", String(filters.includeHidden));
        }

        let data = await axios.get("/v1/keystrings/list-all"  + "?" + params.toString());
        return data.data;
      } catch (error) {
        messages.handleAxiosError(error, `Failed to load all keystrings`);
      }
    },

    /**
     * 
     */
    async updateKeyword(pKeystringData: KeyStringData) {
      const messages = useMessageStore();
      try {
        let data = await axios.put(
          `/v1/keystrings/${pKeystringData.id}`,
          pKeystringData
        );
        return data.data;
      } catch (error) {
        messages.handleAxiosError(error, `Failed to save keystring`);
      }
    },

    async unlockKeywords(pPin: string) : Promise<string|null> {
      const messages = useMessageStore();
      try {
        let data = await axios.post(
          `/v1/keystrings/unlock`,
          {'pin': pPin}
        );
        return data.data;
      } catch (error) {
        messages.handleAxiosError(error, `Failed to unlock`);
      }

      return null;
    }
  },
});

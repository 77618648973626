<script setup lang="ts">
  import { onMounted, ref } from "vue";
  import { useCollectionStore } from "../../stores/useCollectionStore";
  import { useItemSlidingWindowStore } from "../../stores/useItemSlidingWindowStore";
  import ItemTable from "../../components/table/ItemTable.vue";
  import { useItemStore } from "../../stores/useItemStore";
  import { watch } from "vue";
  import { cItem } from "../../domain/item";
  import { useItemSelection } from "../../utility/useItemSelection";
  import { CollectionData } from "rundown-common";
  import { useWebSocketStore } from "../../stores/useWebSocketStore";

  const collections = useCollectionStore();
  const itemWindow = useItemSlidingWindowStore();
  const items = useItemStore();

  const {
    clearSelectedItems,
    selectedItems,
    selectAllItems,
    toggleSelectedItem,
    isAllItemsInPageSelected,
  } = useItemSelection(itemWindow);

  let loading = ref();
  let notready = ref(true);

  let destinationCollection = ref({ id: 0, name: "" } as CollectionData);

  /**
   * Watch selected items to enable/disable the start transfer button
   */
  watch(selectedItems.value, () => {
    checkIfReady();
  });

  /**
   * Check if source/destination are selected
   */
  function checkIfReady() {
    if (
      selectedItems.value.size() > 0 &&
      destinationCollection.value.id != 0 &&
      destinationCollection.value.id != collections.getSelected
    ) {
      notready.value = false;
    } else {
      notready.value = true;
    }
  }

  /**
   * Event: Destination Selected
   */
  function destinationCollectionSelect(pCollection: CollectionData | null) {
    if (pCollection == null) return;

    destinationCollection.value = pCollection;
    checkIfReady();
  }

  /**
   * Event: Start Import
   */
  async function collectionTransfer() {
    loading.value = true;
    const selectedItemIds = Array.from(selectedItems.value.all()).map(
      (item: cItem) => item.getId()
    );

    const taskid = await collections.transfer(
      destinationCollection.value,
      selectedItemIds.map((id) => id.toString())
    );

    if (taskid) {
      useWebSocketStore()
        .onetimeListenChannel(`task-${taskid}`, () => {
          selectedItems.value.clearItems();
        })
        .finally(() => {
          loading.value = false;
        });
    } else {
      loading.value = false;
    }
  }

  onMounted(async () => {});

  watch(
    () => collections.getSelected,
    async () => {
      if (collections.getSelected != 0) {
        await items.setItemsPerPage(100);
        while (items.isLoading) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        clearSelectedItems();
        items.loadAllPages();
      } else {
        await items.setItemsPerPage(10);
      }
    },
    { immediate: true }
  );
</script>

<template>
  <v-container>
    <template v-if="collections.getSelected == 0">
      <v-row
        class="mt-12"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          md="8"
        >
          <v-card
            class="px-6 py-10 rounded-lg"
            color="#252525"
            dark
            elevation="3"
          >
            <h2 class="mb-4 text-h5 text-center text-green">
              Select a Collection
            </h2>
            <p class="mt-10 text-center">
              Use the Collection Filter in the left menu to choose a collection
              for item selection.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <v-card
        class="mx-auto my-12 pa-6 rounded-lg"
        max-width="500"
        variant="outlined"
        elevation="2"
      >
        <template v-slot:title>
          <h3 class="text-h6 text-center">Transfer Items To</h3>
        </template>
        <v-card-item>
          <v-select
            prepend-icon="mdi-export"
            :items="collections.getManaged"
            item-title="name"
            label="Select Destination Collection"
            return-object
            single-line
            class="mb-6"
            @update:modelValue="destinationCollectionSelect"
          ></v-select>

          <v-card-actions class="d-flex justify-center mt-6">
            <v-btn
              :loading="loading"
              :disabled="loading || notready"
              color="blue-grey"
              rounded="pill"
              prepend-icon="mdi-cloud-upload"
              @click="collectionTransfer"
            >
              Start Transfer
            </v-btn>
          </v-card-actions>
        </v-card-item>
      </v-card>
      <ItemTable
        height="100%"
        :isLoading="items.isLoading"
        read-only
        show-actions
        :columns="['path', 'name', 'date', 'category', 'people', 'keywords']"
        :items="itemWindow.items"
        :item-current="items.getSelectedItem"
        :page="itemWindow.windowNumber"
        :pages="itemWindow.totalWindows"
        :items-per-page="itemWindow.windowSize"
        @page-select="itemWindow.changeToWindow"
        @item-select="items.selectItem"
        @items-per-page-change="itemWindow.setWindowSize"
        @item-next="itemWindow.moveDown"
        @item-previous="itemWindow.moveUp"
        @page-next="itemWindow.moveWindowDown"
        @page-previous="itemWindow.moveWindowUp"
      >
        <template #title>Items</template>

        <template #actionHeader="{}">
          <v-checkbox-btn
            label="All"
            :model-value="isAllItemsInPageSelected()"
            @click="selectAllItems"
          ></v-checkbox-btn>
        </template>
        <template #action="{ item }">
          <v-checkbox-btn
            :model-value="selectedItems.hasItem(item)"
            @click.stop="toggleSelectedItem(item)"
          ></v-checkbox-btn>
        </template>
      </ItemTable>
    </template>
  </v-container>
</template>

<script setup lang="ts">
  import { onMounted, onUnmounted, computed } from "vue";
  import { cItem } from "../../domain/item";
  import { usePeopleStore } from "../../stores/usePeopleStore";
  import { PersonInterface, ItemLocationData } from "rundown-common";
  import { PropType } from "vue";
  import { timeUntil } from "../../utility/timeUntil";

  const people = usePeopleStore();

  const props = defineProps({
    readOnly: {
      type: Boolean,
      default: false,
    },
    itemCurrent: {
      type: Object as PropType<cItem | null>,
      required: true,
    },
    items: {
      type: Array<cItem>,
      required: true,
    },
    itemsPerPage: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    page: {
      type: Number,
      default: 1,
      required: false,
    },
    pages: {
      type: Number,
      default: 0,
      required: false,
    },
    height: {
      type: String,
      default: "500px",
      required: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array as PropType<string[]>,
      default: () => [
        "name",
        "date",
        "category",
        "people",
        "keywords",
        "address",
      ],
    },
  });

  const columnDefinitions = [
    {
      key: "path",
      label: "Path",
      accessor: (item: cItem) => item.getPath(),
      chip: false,
    },
    {
      key: "name",
      label: "Name",
      accessor: (item: cItem) => item.getName(),
      chip: false,
    },
    {
      key: "date",
      label: "Date",
      accessor: (item: cItem) => item.getDateTakenFormatted(),
      event: "date-click",
      getEmitData: (item: cItem) => item.getDateTaken(),
      chip: false,
    },
    {
      key: "category",
      label: "Category",
      accessor: (item: cItem) => item.category,
      event: "category-click",
      getEmitData: (item: cItem) => item.category,
      chip: false,
    },
    {
      key: "people",
      label: "People",
      accessor: (item: cItem) => item.peopleSaved,
      event: "person-chip-click",
      getEmitData: (person: PersonInterface) => person.getCurrentName(),
      chip: true,
    },
    {
      key: "keywords",
      label: "Keywords",
      accessor: (item: cItem) => item.keywords,
      event: "keyword-chip-click",
      getEmitData: (keyword: string) => keyword,
      chip: true,
    },
    {
      key: "address",
      label: "Address",
      accessor: (item: cItem) => item.locations[0]?.placeName,
      event: "location-click",
      getEmitData: (item: cItem) => item.locations[0],
      chip: false,
    },
    {
      key: "deleted",
      label: "Will be deleted",
      accessor: (item: cItem) =>
        timeUntil(new Date((Number(item.getProperty("Deleted")) ?? 0) * 1000)),
      getEmitData: (item: cItem) => item,
      chip: false,
    },
  ];

  // Sort columnDefinitions according to the columns prop order
  const orderedColumnDefinitions = computed(() => {
    const orderedColumns: { [key: string]: any } = {};
    props.columns.forEach((col) => {
      const columnDef = columnDefinitions.find((def) => def.key === col);
      if (columnDef) orderedColumns[col] = columnDef;
    });
    return orderedColumns;
  });

  const itemsPerPageArray = [10, 50, 100, 500];

  const emit = defineEmits<{
    (e: "page-select", pPage: number): void;
    (e: "page-next"): void;
    (e: "page-previous"): void;
    (e: "item-select", pItem: cItem): void;
    (e: "item-next"): void;
    (e: "item-previous"): void;
    (e: "items-per-page-change", pItemsPerPage: number): void;
    (e: "date-click", pEvent: Event, pDate: string): void;
    (e: "category-click", pEvent: Event, pCategory: string): void;
    (e: "person-chip-click", pEvent: Event, pPerson: PersonInterface): void;
    (e: "keyword-chip-click", pEvent: Event, pKeyword: string): void;
    (
      e: "location-click",
      pEvent: Event,
      pLocation: ItemLocationData | undefined
    ): void;
    (e: "item-ctrl-click", pEvent: Event, pItem: cItem): void;
  }>();

  const numberedPages = computed(() =>
    Array.from({ length: props.pages }, (_, i) => `${i + 1}`)
  );

  function pageSelect(pPage: number | string | null) {
    if (pPage) emit("page-select", Number(pPage));
  }

  function handleEmit(pEvent: any, pEventName: string | undefined, data: any) {
    if (pEventName && data !== null) emit(pEventName, pEvent, data);
  }

  function keypress(event: KeyboardEvent) {
    if (event.defaultPrevented) return;

    switch (event.key) {
      case "ArrowDown":
        emit("item-next");
        break;
      case "ArrowUp":
        emit("item-previous");
        break;
      case "ArrowLeft":
        emit("page-previous");
        break;
      case "ArrowRight":
        emit("page-next");
        break;
      default:
        return;
    }
    event.preventDefault();
  }

  onMounted(() => document.addEventListener("keydown", keypress));
  onUnmounted(() => document.removeEventListener("keydown", keypress));
</script>

<template>
  <v-card
    elevation="2"
    class="justify-center ma-5 overflow-y-auto mx-0"
  >
    <v-progress-linear
      v-if="isLoading"
      indeterminate
      absolute
      bottom
      color="deep-purple-accent-4"
    />

    <v-card-title>
      <slot name="title"></slot>
    </v-card-title>

    <v-table
      density="compact"
      fixed-header
      :height="height"
    >
      <thead>
        <tr>
          <template v-if="showActions">
            <th style="width: 120px">
              <slot name="actionHeader" />
            </th>
          </template>
          <template
            v-for="columnKey in columns"
            :key="columnKey"
          >
            <th>
              {{ orderedColumnDefinitions[columnKey].label }}
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in items"
          :key="item.getId()"
          @click.ctrl.prevent.capture="$emit('item-ctrl-click', $event, item)"
          @click="readOnly && $emit('item-select', item)"
          :class="{
            'bg-primary': item === itemCurrent,
            'row-pointer': readOnly,
          }"
          :data-itemid="item.getId()"
        >
          <template v-if="showActions">
            <td>
              <slot
                :item="item"
                name="action"
              />
            </td>
          </template>
          <template
            v-for="columnKey in columns"
            :key="columnKey"
          >
            <td>
              <template v-if="orderedColumnDefinitions[columnKey].chip">
                <v-chip-group column>
                  <v-chip
                    v-for="chipItem in orderedColumnDefinitions[
                      columnKey
                    ].accessor(item)"
                    :key="chipItem.id || chipItem"
                    size="x-small"
                    @dblclick="
                      handleEmit(
                        $event,
                        orderedColumnDefinitions[columnKey].event,
                        orderedColumnDefinitions[columnKey].getEmitData(
                          chipItem
                        )
                      )
                    "
                  >
                    {{
                      columnKey === "people"
                        ? chipItem.getCurrentName()
                        : chipItem
                    }}
                  </v-chip>
                </v-chip-group>
              </template>
              <template v-else-if="orderedColumnDefinitions[columnKey].event">
                <span
                  @dblclick="
                    handleEmit(
                      $event,
                      orderedColumnDefinitions[columnKey].event,
                      orderedColumnDefinitions[columnKey].getEmitData(item)
                    )
                  "
                >
                  {{ orderedColumnDefinitions[columnKey].accessor(item) }}
                </span>
              </template>
              <template v-else>
                <span
                  v-if="columnKey === 'name' || columnKey === 'path'"
                  class="row-pointer"
                  @click="$emit('item-select', item)"
                >
                  {{ orderedColumnDefinitions[columnKey].accessor(item) }}
                </span>
                <span v-else>
                  {{ orderedColumnDefinitions[columnKey].accessor(item) }}
                </span>
              </template>
            </td>
          </template>
        </tr>
      </tbody>
    </v-table>
  </v-card>

  <template v-if="props.pages > 0">
    <v-row justify="center">
      <v-col
        :cols="12"
        class="relative-container mb-0"
      >
        <v-pagination
          :total-visible="9"
          :model-value="props.page"
          :length="props.pages"
          @update:modelValue="pageSelect"
        >
          <template v-slot:next="test">
            <v-row no-gutters>
              <v-select
                v-if="pages > 9"
                :items="numberedPages"
                density="comfortable"
                :value="page"
                variant="solo"
                @update:modelValue="pageSelect"
              ></v-select>
              <v-btn
                _as="VPaginationBtn"
                v-bind="test"
              ></v-btn>
            </v-row>
          </template>
        </v-pagination>

        <div class="items-per-page-selector mr-5">
          <span>Items per page</span>
          <v-menu>
            <template v-slot:activator="{ props }">
              <v-btn
                style="min-width: 85px"
                color="primary"
                class="ml-2"
                append-icon="mdi-chevron-down"
                v-bind="props"
              >
                {{ itemsPerPage }}
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                :title="number"
                @click="emit('items-per-page-change', number)"
              ></v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-col>
    </v-row>
  </template>
</template>

<style scoped>
  .row-pointer:hover {
    cursor: pointer;
  }

  .relative-container {
    position: relative;
  }

  .items-per-page-selector {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
  }
</style>
../../utility/timeUntil

import {ImageScale, ItemInterface, ItemObjectData, ItemObjectInterface, ObjectInterface} from 'rundown-common';
import { cItem } from './item';
import { useObjectsStore } from '../stores/useObjectsStore';
import { cObject } from './Object';
import itemobject from '../services/itemobject';
import { cItemObjectBase } from './ItemBaseObject';

/**
 * An ItemObject represents the association between an object and an item.
 */
export class cItemObject extends cItemObjectBase implements ItemObjectInterface {

    constructor(pItemObjectData: ItemObjectData | cObject, pItem: cItem) {
        super(pItemObjectData, pItem);
    }

    getId = (): number => {
        return this.object.id;
    }

    getName = (): string => {
        return this.getObject().name;
    }
    
    getDescription(): string {
        return this.getObject().description;
    }
    
    getObject = (): ObjectInterface => {
        if (!this.object.object) {
            throw new Error("Object is null");
        }

        const objects = useObjectsStore();
        return objects.getObjectById(this.object.object);
    }

    getObjectId = (): number => {
        return this.getObject().getId();
    }

    getCurrentName = (): string => {
        return this.getObject().name;
    }
    
    getSearchName =(): string => {
        return this.getObject().getSearchName();
    }

    save = async(): Promise<boolean> => {
        try {
            if (this.getId() == 0) {
                this.object = await itemobject.objectCreate(this.item.getId(), this.object);
            } else {
                this.object = await itemobject.objectUpdate(this.item.getId(), this.object);
            }


        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    delete = async(): Promise<boolean> => {
        try {
            await itemobject.objectDelete(this.getId(), this.object);
            this.object.id = 0;
        } catch (error) {
            return false;
        }
        return true;
    }

}


<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { useSettingsStore } from '../stores/useSettingsStore';
import { useRoute } from 'vue-router';
import { iScreenSaver, useScreenSaverStore } from '../stores/useScreenSaverStore';

import Tokens from '../components/screensaver/deviceTokens.vue';
import SaverFull from '../components/screensaver/ScreenSaverPanelItem.vue';
import Settings from '../components/screensaver/settings.vue';

import List from '../components/screensaver/list.vue';
import { cItem } from '../domain/item';

const settings = useSettingsStore();
const savers = useScreenSaverStore();

const currentItem = ref<cItem|null>(null);
const currentItems = ref([] as Array<cItem>);

const selectedScreenSaver = ref({} as iScreenSaver);

const tab = ref('play');
const secondsPerPicture = ref(15);
const playVideo = ref(false);

const route = useRoute();

const timer = ref();

async function fetchItems() {
    // Fetch new data if currentItems.value is empty
    if (!currentItems.value || currentItems.value.length === 0) {
        currentItems.value = await savers.next(selectedScreenSaver.value);
    }
}

async function getNextNonVideoItem(maxFetchAttempts = 3) {
    let fetchAttempts = 0;
    let item;

    while (fetchAttempts < maxFetchAttempts) {
        if (!currentItems.value || currentItems.value.length === 0) {
            await fetchItems();
            fetchAttempts++;

            // Break if no new items are fetched
            if (currentItems.value.length === 0) {
                return undefined;
            }
        }

        item = currentItems.value.shift();
        if (item !== undefined && ((playVideo.value && item.isVideo()) || !item.isVideo())) {
            return item;
        }
    }

    // Return undefined if no suitable item is found after max attempts
    return undefined;
}

async function nextItem() {
    try {
        const item = await getNextNonVideoItem();

        if (item !== undefined) {
            currentItem.value = item;
        } else {
            console.error("No suitable items found after multiple attempts.");
        }
    } catch (error) {
        console.error("Error occurred while fetching the next item: ", error);
    }
}

async function timerTick() {
    // Clear any existing timer
    if (timer.value) {
        clearTimeout(timer.value);
        timer.value = null;
    }

    await nextItem();

    // Don't play videos
    if (currentItem.value != null && currentItem.value.isVideo()) {
        await nextItem();
    }

    timer.value = setTimeout(async () => {
        await timerTick();
    }, secondsPerPicture.value * 1000);
}

async function start() {

    // if non selected, create from current filters
    if (selectedScreenSaver.value.id == undefined) {
        selectedScreenSaver.value = await savers.create("web", true, secondsPerPicture.value);
    }

    await timerTick();
}

function stop() {
    clearTimeout(timer.value);
    timer.value = {} as NodeJS.Timeout;
}

function change(newState: boolean) {

    if (newState === false) {
        currentItem.value = new cItem();
        stop();
    }
}

function saverSelected(saver: iScreenSaver) {
    selectedScreenSaver.value = saver;
    if (selectedScreenSaver.value.seconds != undefined)
        secondsPerPicture.value = selectedScreenSaver.value.seconds;
}

onMounted(async function () {
    await savers.load();

    if (route.query?.showtokens == 'true') {
        tab.value = "two";
    }
});

</script>
<template>
    <v-container>
        <v-tabs v-model="tab">
            <v-tab value="play">Play</v-tab>
            <v-tab value="settings">Settings</v-tab>
            <v-tab value="two">Devices</v-tab>
        </v-tabs>

        <v-window v-model="tab">

            <v-window-item value="play">

                <List :screensaver="selectedScreenSaver" @select-screensaver="saverSelected"></List>
                <v-container>
                    <v-card title="Play">
                        <v-row class="mt-1 mt-sm-1 mt-md-1">
                            <v-col></v-col>
                            <v-col cols="2">
                                <div class="text-caption">
                                    Seconds per picture
                                </div>
                                <v-slider
                                min=5 max=60 step=1 v-model="secondsPerPicture" thumb-label="always"></v-slider>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                        <v-row>
                            <v-col></v-col>
                            <v-col cols="2">
                                <div class="text-caption">
                                    Play Videos
                                </div>
                                <v-switch v-model="playVideo" color="primary">
                                </v-switch>
                            </v-col>
                            <v-col></v-col>
                        </v-row>
                        <v-row class="justify-center mb-5">

                            <v-btn color="primary" @click="settings.setFullscreen(true); start();">
                                Start
                            </v-btn>

                        </v-row>
                    </v-card>
                </v-container>

            </v-window-item>

            <v-window-item value="settings">
                <List :screensaver="selectedScreenSaver" @select-screensaver="saverSelected"></List>

                <Settings :screensaver="selectedScreenSaver"></Settings>
            </v-window-item>

            <v-window-item value="two">
                <Tokens></Tokens>
            </v-window-item>

        </v-window>

        <SaverFull :screensaver="selectedScreenSaver" :item="currentItem" @fullscreen-mode-change="change"
            @video-ended="timerTick">
        </SaverFull>
    </v-container>
</template>
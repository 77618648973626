import { ref, computed, nextTick, watch } from 'vue';

export function useItemSlidingWindow(itemStore: any, initialWindowSize = 10) {
    const windowSize = ref(initialWindowSize);
    const currentPosition = ref(0);

    const totalItems = computed(() => itemStore.getTotalRecords);
    const totalWindows = computed(() => Math.ceil(itemStore.getTotalRecords / windowSize.value));
    const items = ref([]);
    const isLoading = computed(() => itemStore.isLoading);
    const reloadCounter = computed(() => itemStore.reloadCounter);
    const refreshCounter = computed(() => itemStore.refreshCounter);

    const windowNumber = computed(() => {
        return Math.ceil((currentPosition.value + 1) / windowSize.value);
    });

    const getItems = computed(() => items.value);

    // Reload items for the current window
    async function reloadItems() {
        const start = currentPosition.value;
        const count = Math.min(windowSize.value, totalItems.value - start + 1);
        items.value = await itemStore.getRange(start, count);
        nextTick(scrollToItem);
    }

    // Move the window up by one position
    async function moveUp() {
        const selectedItemIndex = itemStore.getSelectedItemIndex - 1;
        const diff = selectedItemIndex - currentPosition.value;

        if (selectedItemIndex < 0) {
            currentPosition.value = totalItems.value - 1;
            await itemStore.selectItemAtIndex(itemStore.getTotalRecords - 1);
        } else if (diff <= Math.floor(items.value.length / 2) ) {
            
            if(currentPosition.value > 0) {
                currentPosition.value--;
            } else {
                currentPosition.value = 0;
            }
            await itemStore.moveSelectionByOffset(-1);
        }

        nextTick(scrollToItem);
    }

    // Move the window down by one position
    async function moveDown() {
        const selectedItemIndex = itemStore.getSelectedItemIndex + 1;
        const diff = selectedItemIndex - currentPosition.value;

        // Move the window down if the end of the list isn't reached
        if (diff >= Math.floor(items.value.length / 2)) {
            if (selectedItemIndex < totalItems.value && currentPosition.value < totalItems.value - 1) {
                currentPosition.value++;
                await itemStore.moveSelectionByOffset(1);
            } else {
                currentPosition.value = 0;
                await itemStore.selectItemAtIndex(0);
            }
        } else {
            await itemStore.moveSelectionByOffset(1);
        }
        nextTick(scrollToItem);
    }

    // Move down by one window
    async function moveWindowDown() {
        currentPosition.value = Math.min(currentPosition.value + windowSize.value, totalItems.value - windowSize.value);
        if (currentPosition.value + windowSize.value < totalItems.value) {
            await itemStore.moveSelectionByOffset(windowSize.value);
        } else {
            await itemStore.selectItemAtIndex(0);
        }
    }

    // Move up by one window
    async function moveWindowUp() {
        currentPosition.value = Math.max(0, currentPosition.value - windowSize.value);
        if (currentPosition.value < windowSize.value) {
            await itemStore.selectItemAtIndex(totalItems.value - 1);
        } else {
            await itemStore.moveSelectionByOffset(-windowSize.value);
        }
    }

    // Jump to a specific position
    function jumpTo(position: number) {
        currentPosition.value = Math.max(0, Math.min(position, totalItems.value - windowSize.value));
    }

    // Change to a specific window number
    function changeToWindow(windowNumber: number) {
        const newPosition = (windowNumber - 1) * windowSize.value;
        currentPosition.value = newPosition;
    }

    // Change to the window containing a specific index
    async function changeToWindowWithIndex(index: number) {
        currentPosition.value = Math.floor(index / windowSize.value) * windowSize.value;
    }

    // Change to the window containing a specific item by ID
    async function changeToWindowWithItem(itemId: number) {
        let index = itemStore.getIndexOfItemId(itemId);
        if(index == -1) {
            await itemStore.loadPagesUntilItemFound(itemId);
            index = itemStore.getIndexOfItemId(itemId);
        }
        if(index == -1)
            return;
        
        await changeToWindowWithIndex(index);
        itemStore.selectItemAtIndex(index);
    }

    // Set the size of the window
    function setWindowSize(newWindowSize: number) {
        windowSize.value = newWindowSize;
    }

    // Scroll to the currently selected item
    function scrollToItem() {
        const itemId = itemStore.getSelectedItem.getId();
        const elmnt = document.querySelector(`table tr[data-itemid="${itemId}"]`);
        if (elmnt) {
            elmnt.scrollIntoView({ behavior: "smooth", block: 'center' });
        }
    }

    // Watch for page refresh
    let reloadTimeout: number|null = null;
    let pendingReload = false;

    watch(refreshCounter, () => {
        if (reloadTimeout) {
            pendingReload = true;
            return;
        }
    
        const executeReload = async () => {
            await reloadItems();
            reloadTimeout = null;
    
            if (pendingReload) {
                pendingReload = false;
                reloadTimeout = setTimeout(executeReload, 500);
            }
        };
    
        reloadTimeout = setTimeout(executeReload, 500);
    });

    // Watch for item reload
    watch(reloadCounter, async () => {
        currentPosition.value = 0;
        await reloadItems();
        await itemStore.selectItemAtIndex(0);
    });

    // Watch for position and window size changes
    watch([currentPosition, windowSize], reloadItems, { immediate: true });

    return {
        items,
        getItems,
        currentPosition,
        totalItems,
        totalWindows,
        windowSize,
        isLoading,
        windowNumber,

        reloadItems,
        moveUp,
        moveDown,
        moveWindowDown,
        moveWindowUp,
        jumpTo,
        setWindowSize,
        changeToWindow,
        changeToWindowWithIndex,
        changeToWindowWithItem,
        scrollToItem,
        
    };
}

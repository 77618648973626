import { defineStore } from "pinia";
import { useItemSlidingWindow } from "../utility/useItemSlidingWindow";
import { useItemStore } from "./useItemStore";

export const useItemSlidingWindowStore = defineStore(
  "itemSlidingWindow",
  () => {
    const itemStore = useItemStore();
    const {
      items,
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      windowNumber,
      isLoading,
      moveUp,
      moveDown,
      scrollToItem,
      changeToWindow,
      setWindowSize,
      moveWindowUp,
      moveWindowDown,
      changeToWindowWithIndex,
      changeToWindowWithItem,
      getItems,
    } = useItemSlidingWindow(itemStore);

    return {
      items,
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      windowNumber,
      isLoading,
      getItems,

      moveUp,
      moveDown,
      scrollToItem,
      changeToWindow,
      changeToWindowWithIndex,
      changeToWindowWithItem,
      setWindowSize,
      moveWindowUp,
      moveWindowDown,
    };
  }
);

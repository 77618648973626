import { ItemObjectData, PersonFaceData, ItemPersonInterface, ImageScale, ItemInterface } from "rundown-common";
import { cPerson } from "./Person";
import { usePeopleStore } from "../stores/usePeopleStore";
import itemobject from '../services/itemobject';
import { cItem } from "./item";
import { FaceResult } from "@vladmandic/human";
import { cItemObjectBase } from "./ItemBaseObject";

/**
 * An ItemPersonObject represents the association between a person and an item.
 */
export class cItemPersonObject extends cItemObjectBase implements ItemPersonInterface {
    newFace: FaceResult | null = null;

    /**
     * Constructor
     */
    constructor(pPersonItemObject: ItemObjectData | cPerson, pItem: cItem) {
        super(pPersonItemObject, pItem);
    }

    getCurrentName = (): string => {
        return this.getPerson().getCurrentName().name;
    }

    getSearchName = (): string => {
        const per = this.getPerson();
        return per.getSearchName();
    }
    
    getPersonId = (): number => {
        return this.getPerson()?.getId() ?? 0;
    }

    getPerson = (): cPerson => {
        if (!this.object.person) {
            throw new Error("Person is not null");
        }

        const people = usePeopleStore();
        return people.getPersonById(this.object.person);
    }

    getNewFace = (): PersonFaceData | null => {
        if (this.newFace) {
            return {
                id: 0,
                person: this.object?.person ?? 0,
                object: this.getId(),
                embedding_data: this.newFace.embedding
            } as PersonFaceData;
        }
        return null;
    }

    getExistingFace = (): PersonFaceData | null => {
        const people = usePeopleStore();
        if (this.object.face) {

            return people.getPersonFace(this.object.face) ?? null;
        }
        return null;
    }

    getFace = (): PersonFaceData | null => {
        if (this.newFace) {
            return this.getNewFace();
        }

        const people = usePeopleStore();
        if (this.object.face) {

            return people.getPersonFace(this.object.face) ?? null;
        }

        // If no face is set, find a valid face for this person
        if (this.object.person) {
            const faces = people.getPersonFaces(this.object.person);
            if (faces.length) {
                return faces[0];
            }
        }
        return null;
    }

    /**
     * Create or update the face associated to this object
     */
    saveFace = async (): Promise<boolean> => {
        if (this.newFace) {
            const people = usePeopleStore();

            // Existing face?
            if (this.object.face) {
                const face = this.getExistingFace();
                if (face && this.newFace.embedding) {
                    face.embedding_data = this.newFace.embedding;
                    people.updateFace(this.getPerson(), face);
                    return true;
                }

            }

            const face = this.getNewFace();
            if (face) {
                people.createFace(this.getPerson(), face);
                return true;
            }
        }
        return false;
    }

    /**
     * Save this object and the face
     */
    save = async (): Promise<boolean> => {

        // No ID means its a new object
        try {
            if (this.getId() == 0) {
                this.object = await itemobject.personCreate(this.item.getId(), this.object);
            } else {
                this.object = await itemobject.personUpdate(this.item.getId(), this.object);
            }

            await this.saveFace();

        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    }

    /**
     * Delete this person from the item
     */
    delete = async (): Promise<boolean> => {

        try {
            await itemobject.personDelete(this.item.getId(), this.object);
            this.object.id = 0;
        } catch (error) {
            return false;
        }
        return true;
    }
}
import { defineStore } from "pinia";
import { cItem } from "../domain/item";
import { ItemData } from "rundown-common";
import { handleError } from "../utility/useErrorHandler";
import { useItemPagination } from "../utility/useItemPagination";
import { useItemNavigation } from "../utility/useItemNavigation";
import { ItemStatus } from "../utility/ItemPageManager";

export const useItemRecycledStore = defineStore("itemsRecycled", () => {
  const {
    pages,
    getRange,
    getTotalRecords,
    isLoading,
    reloadCounter,
    load,
    removeItem,
  } = useItemPagination(ItemStatus.Recycled);
  const navigation = useItemNavigation(pages);

  async function remove(pItem: cItem) {
    try {
      if (await pItem.delete()) {
        removeItem(pItem.getId());
      }
      return true;
    } catch (error) {
      handleError(error);
      return false;
    }
  }

  async function removeAll() {
    for (let i = 0; i < getTotalRecords.value; ++i) {
      const item = await pages.getItemByIndex(i);
      if (item?.getId()) {
        await remove(item);
      }
    }
  }

  async function update(pItem: ItemData, pDeleted: boolean, pContext: object) {
    if (pDeleted) {
      removeItem(pItem.id);
      if (
        navigation.getSelectedItem.value.getId() ===
        navigation.getSelectedItemIndex.value
      ) {
        if (navigation.getSelectedItemIndex.value < getTotalRecords.value - 1) {
          navigation.selectNextItem();
        } else {
          navigation.clearSelectedItem();
        }
      }
    } else {
      const item = pages.getItem(pItem.id);
      item?.refresh(pItem, pContext);
    }
  }

  return {
    getRange,
    getTotalRecords,
    isLoading,
    reloadCounter,
    load,
    remove,
    removeAll,
    update,
    ...navigation,
  };
});

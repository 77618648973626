import { defineStore } from "pinia";

import axios from "axios";
import { useMessageStore } from "./useMessageStore";
import { useItemStore } from "./useItemStore";
import collectionService from "../services/collection";
import { PublicationContext } from "centrifuge";
import { useFilterStore } from "./useFilterStore";
import { CollectionData, ActionMessageInterface } from "rundown-common";
import { useItemMissingStore } from "./useItemMissingStore";
import { useLoaderStore } from "./useLoaderStore";
import { ActionInterface } from "./useWebSocketStore";

export const collectionAll: CollectionData = {
  id: 0,
  name: "All",
  path: "",
  pathVariant: "",
  lastscan: "",
  folderFormat: "",
  fileFormat: "",
  managed: true,
  readonly: false,
  itemcount: 0,
  access: [
    {
      id: 0,
      name: "",
      admin: false,
      public: true,
      view: true,
      create: false,
      update: false,
    },
  ],
};

export const useCollectionStore = defineStore("collections", {
  state: () => ({
    collections: [] as Array<CollectionData>,
    selected: 0,
  }),

  getters: {
    getSelected(state): number {
      return state.selected;
    },

    getCollection(state): CollectionData {
      return (
        this.collections.find((element) => element.id == state.selected) ??
        collectionAll
      );
    },

    getCollections(state): Array<CollectionData> {
      return state.collections;
    },

    getUnmanaged(state): Array<CollectionData> {
      let unmanaged = new Array<CollectionData>();

      state.collections.forEach(function (value) {
        if (!value.managed) unmanaged.push(value);
      });
      return unmanaged;
    },

    getManaged(state): Array<CollectionData> {
      let managed = new Array<CollectionData>();

      state.collections.forEach(function (value) {
        if (value.managed) managed.push(value);
      });

      return managed;
    },
  },

  actions: {
    setSelected(pCollection: CollectionData) {
      this.selected = pCollection.id;
      useFilterStore().setCollectionId(pCollection.id);
    },

    getCollectionById(pId: number): CollectionData | null {
      for (const pCollect of this.collections) {
        if (pCollect.id === pId) {
          return pCollect;
        }
      }
      return null;
    },
    

    /**
     * Handle a websocket message for a collection
     */
    async websocketMessage(pData: PublicationContext) {
      const items = useItemStore();
      const itemsmiss = useItemMissingStore();

      const messages = useMessageStore();
      var parsed = pData.data as ActionMessageInterface;

      // Update the collection
      if (parsed.collection !== null) {
        const collectionIndex = this.collections.findIndex(
          (element) => element.id === parsed.collection?.id
        );
        if (collectionIndex !== -1) {
          this.collections[collectionIndex] = parsed.collection;
        }
      }

      // Update item if its currently loaded
      if (parsed.item !== null) {
        items.update(
          parsed.item,
          parsed.action === ActionInterface.Delete,
          parsed.context
        );
        itemsmiss.update(
          parsed.item,
          parsed.action === ActionInterface.Delete,
          parsed.context
        );

        items.reload();
      }

      messages.processAction(parsed);
    },

    /**
     * Load all collections
     */
    async load() {
      const messages = useMessageStore();

      try {
        const data = await axios.get("/v1/collection");
        this.collections = data.data;
      } catch (error) {
        messages.handleAxiosError(error, "Unable to load collection");
        return;
      }
    },

    /**
     * Update a collection
     */
    async update(pCollection: CollectionData) {
      const newCollection = await collectionService.save(pCollection);
      if (pCollection.id > 0) {
        this.collections.forEach(function (pCollect, index, array) {
          if (pCollect.id == pCollection.id) {
            array[index] = newCollection;
          }
        });
      } else {
        this.collections.push(newCollection);
        useLoaderStore().websocketAttach(newCollection);
      }

      return newCollection;
    },

    /**
     * Delete a collection
     */
    async delete(pCollection: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.delete(pCollection);
      } catch (error) {
        messages.handleAxiosError(error, "Failed to delete collection");
        return;
      }
    },

    /**
     * Scan a collection
     */
    async scan(pCollection: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.scan(pCollection);
      } catch (error) {
        messages.handleAxiosError(error, "Failed to scan collection");
        return;
      }
    },

    /**
     * Sort a collection
     */
    async sort(pCollection: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.sort(pCollection);
      } catch (error) {
        messages.handleAxiosError(error, "Failed to sort collection");
        return;
      }
    },

    /**
     * Scan metadata in a collection
     */
    async importmeta(pCollection: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.importitemmeta(pCollection);
      } catch (error) {
        messages.handleAxiosError(
          error,
          "Failed to import metadata for collection"
        );
        return;
      }
    },

    /**
     * Export metadata to items in a collection
     */
    async exportmeta(pCollection: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.exportitemmeta(pCollection);
      } catch (error) {
        messages.handleAxiosError(
          error,
          "Failed to export metadata for collection"
        );
        return;
      }
    },

    /**
     * Import items from a collection to another collection
     */
    async import(pSource: CollectionData, pDestination: CollectionData) {
      const messages = useMessageStore();
      try {
        collectionService.import(pSource, pDestination);
      } catch (error) {
        messages.handleAxiosError(error, "Failed to import collection");
        return;
      }
    },

    /**
     * Export the data from the collection to a CSV
     */
    async export(pCollection: CollectionData): Promise<any> {
      const messages = useMessageStore();
      try {
        collectionService.export(pCollection).then((response) => {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.download = "collection-" + pCollection.id + ".csv";
          link.click();
          window.URL.revokeObjectURL(link.href);
        });
      } catch (error) {
        messages.handleAxiosError(error, "Failed to export collection");
        return;
      }

      return;
    },

    /**
     * Start a transfer of items from one collection to another
     */
    async transfer(
      pDestinationCollection: CollectionData,
      pItemIDs: Array<string>
    ): Promise<string | null> {
      const messages = useMessageStore();
      try {
        const result = await collectionService.transfer(
          pDestinationCollection,
          pItemIDs
        );
        return result.taskid;
      } catch (error) {
        messages.handleAxiosError(
          error,
          "Failed to start transferring items to collection"
        );
        return null;
      }
    },
  },
});

<script setup lang="ts">

import { ref, watch, PropType } from 'vue';
import { useMessageStore } from '../../stores/useMessageStore';
import { cItem } from '../../domain/item';

const messages = useMessageStore();
const menuShow = ref(false);

const props = defineProps({
    item: {
        type: cItem,
        required: true
    },
    show: {
        type: Boolean,
        default: false,
        required: true
    },
    positionX: {
        type: Number,
        required: true
    },
    positionY: {
        type: Number,
        required: true
    }
});

const emit = defineEmits<{
    (e: 'menu-close'): void
    (e: 'menu-item-click-person'): void
    (e: 'menu-item-click-object'): void
}>();

watch(props, async function () {
    menuShow.value = props.show;
});

const menuClosed = () => {
    emit('menu-close');
};

const menuPersonClick = () => {
    emit('menu-item-click-person');
}

const menuObjectClick = () => {
    emit('menu-item-click-object');
}
</script>

<template>
    <v-menu origin="auto" :target="[positionX, positionY]" v-model="menuShow" @update:model-value="menuClosed" style="opacity: 0.85;">
        <v-list>
            <v-list-item prepend-icon="mdi-tag" @click.stop="menuPersonClick()">
                <v-list-item-title>Create Person</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item prepend-icon="mdi-map-marker" @click.stop="menuObjectClick()">
                <v-list-item-title>Create Object</v-list-item-title>
            </v-list-item>



        </v-list>
    </v-menu>
</template>
<script setup lang="ts">
import { onMounted, PropType, ref } from 'vue';
import { usePeopleStore } from '../../../stores/usePeopleStore';
import { PersonNameData} from 'rundown-common';
import { cPerson } from '../../../domain/Person';
import { computed } from 'vue';

const props = defineProps({
    person: {
        type: cPerson,
        required: true
    },
});

const currentYear = computed(() => new Date().getFullYear());

var dialog = ref(false);
var selectedName = ref({} as PersonNameData);

/**
 * Format a date/time
 */
function dateTakenFormat(pDateTaken: string) {
    if (pDateTaken == undefined || pDateTaken == '')
        return '';

    return new Date(pDateTaken).toLocaleString([], {
        year: 'numeric', month: '2-digit', day: '2-digit',
        timeZoneName: undefined
    });
}

/**
 * Get name active range
 */
function getDateRange(name: PersonNameData) {
    var result = '';

    if (name.active_from != null) {
        result += dateTakenFormat(name.active_from);
    }
    if (name.active_to != null && name.active_to) {
        result += " to " + dateTakenFormat(name.active_to);
    }
    return result;
}

function NameAdd() {
    selectedName.value = { id: 0, person: props.person.getId() } as PersonNameData;
    dialog.value = true;

}

function NameEdit(pName: PersonNameData) {
    selectedName.value = pName;
    dialog.value = true;
}

function NameRemove(pName: PersonNameData) {

    props.person.deleteName(pName);
}

async function NameSave() {

    if (selectedName.value.id == 0) {
        props.person.createName(selectedName.value);

    } else {
        props.person.updateName(selectedName.value);
    }
    dialog.value = false;
}

async function save() {
    props.person.save();
}

onMounted(async () => {

});

</script>
<template>
    <v-dialog v-model="dialog" max-width="350" persistent>
        <v-card>
            <v-card-text>
                <v-col cols="12">
                    <v-text-field v-model="selectedName.name" color="primary" label="Name" variant="underlined">
                    </v-text-field>
                </v-col>
                <v-card-subtitle class="pa-0">
                    What dates is/was this name used
                </v-card-subtitle>

                <v-col cols="12">
                    <label>From</label>
                    <Datepicker dark v-model="selectedName.active_from" autoApply :enableTimePicker="false"
                        format="dd-MM-yyyy" :flow="['year', 'month', 'calendar']" :yearRange="[1700, 2050]"
                        :startTime='{ "hours": 0, "minutes": 0 }' />
                </v-col>

                <v-col cols="12">
                    <label>To</label>
                    <Datepicker dark v-model="selectedName.active_to" autoApply :enableTimePicker="false"
                        format="dd-MM-yyyy" :flow="['year', 'month', 'calendar']" :yearRange="[1700, 2050]"
                        :startTime='{ "hours": 0, "minutes": 0 }' />
                </v-col>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue-darken-1" variant="text" @click="dialog = false">
                    Close
                </v-btn>
                <v-btn color="blue-darken-1" variant="text" @click="NameSave">
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-list lines="two" class="">

        <v-list-item v-for="name in props.person.names" :key="name.id" :title="name.name" :value="name.id" rounded="xl"
            :subtitle="getDateRange(name)" @click="NameEdit(name)">


            <template v-slot:prepend>
                <v-avatar color="grey-lighten-1">
                    <v-icon color="white">mdi-account</v-icon>
                </v-avatar>
            </template>

            <template v-slot:append>
                <v-btn color="grey-lighten-1" icon="mdi-close" variant="text"  @click.stop="NameRemove(name)"></v-btn>
            </template>
        </v-list-item>
    </v-list>
    <v-row>
        <v-col cols="3">
            <v-tooltip bottom>
                <template v-slot:activator="{ props }">
                    <v-btn v-bind="props" icon="mdi-account-plus" variant="plain" @click="NameAdd()">
                    </v-btn>
                </template>
                <span>Add Name</span>
            </v-tooltip>
        </v-col>
        <v-col cols="3">
            <v-list-subheader>Birth Date</v-list-subheader>
        </v-col>
        <v-col cols="4">
            <Datepicker dark v-model="person.person.birthdate" autoApply :enableTimePicker="false" @update:modelValue="save"
                format="dd-MM-yyyy" :flow="['year', 'month', 'calendar']" :yearRange="[1700, currentYear]"
                :startTime='{ "hours": 0, "minutes": 0 }' :teleport="true" />
        </v-col>
    </v-row>
</template>

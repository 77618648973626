<script setup lang="ts">
import { ref, watch } from 'vue';
import { cItem } from '../../domain/item';
import { useItemStore } from '../../stores/useItemStore';
import PanelItem from '../PanelItem.vue';
import PanelMediaOverlay from '../PanelMediaOverlay.vue';
import { ItemData } from 'rundown-common';

const itemstore = useItemStore();

const props = defineProps({
  itemid: {
    type: Array<ItemData|GeoJSON.Feature>,
    default: []
  },
});

const currentItemID = ref(0);
const currentItem = ref(new cItem());

watch(props.itemid, async () => {
  currentItemID.value = 0;
});

watch(currentItemID, async () => {
  var item = props.itemid[currentItemID.value];
  if (item != undefined && typeof item.id == 'number')
    currentItem.value = await itemstore.getByID(item.id);
  else
    currentItem.value = new cItem();
}, { immediate: true });

function itemPrevious() {
  if (--currentItemID.value < 0) {
    currentItemID.value = (props.itemid.length - 1);
  }
}

function itemNext() {
  if (++currentItemID.value >= props.itemid.length)
    currentItemID.value = 0;
}

</script>

<template>
  <div style="width:350px" v-if="props.itemid.length > 0">
    <div class="d-flex justify-center">
      <h2>{{ currentItemID + 1 }} of {{ itemid.length }}</h2>
    </div>
    <div class="v-window v-window--show-arrows-on-hover v-theme--dark">
      <div class="v-window__container">

        <PanelItem class="image-popup" :item="currentItem" :image-height="400" :min-height="400" cover>
          <PanelMediaOverlay :item="currentItem" :isHovering="false"></PanelMediaOverlay>
        </PanelItem>

        <div v-if="itemid.length > 1" class="v-window__controls">
          <button type="button" @click="itemPrevious"
            class="v-btn v-btn--elevated v-btn--icon v-theme--dark v-btn--density-default v-btn--size-default v-btn--variant-elevated v-window__left"
            arialabel="Previous visual">
            <div class="v-btn__overlay"></div>
            <div class="v-btn__underlay"></div>
            <!---->
            <div class="v-btn__content" data-no-activator=""><i
                class="mdi-chevron-left mdi v-icon notranslate v-theme--dark v-icon--size-default"
                aria-hidden="true"></i></div>
            <!---->
            <!---->
          </button>

          <button type="button" @click="itemNext"
            class="v-btn v-btn--elevated v-btn--icon v-theme--dark v-btn--density-default v-btn--size-default v-btn--variant-elevated v-window__right"
            arialabel="Next visual">
            <div class="v-btn__overlay"></div>
            <div class="v-btn__underlay"></div>
            <!---->
            <div class="v-btn__content" data-no-activator=""><i
                class="mdi-chevron-right mdi v-icon notranslate v-theme--dark v-icon--size-default"
                aria-hidden="true"></i></div>
            <!---->
            <!---->
          </button>
        </div>
      </div>
    </div>

  </div>



</template>

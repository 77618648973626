<script setup lang="ts">
  import { ref } from "vue";
  import { useKeywordStore } from "../../stores/useKeywordStore";
  import { usePersonSearch } from "../../utility/UsePersonSearch";
  import { useObjectSearch } from "../../utility/useObjectSearch";

  import { ItemPersonInterface, ItemObjectInterface } from "rundown-common";
  import { cItem } from "../../domain/item";
  import { watch } from "vue";
  import { PropType } from "vue";
  import { computed } from "vue";
  import { cPerson } from "../../domain/Person";
  import { cItemPersonObject } from "../../domain/ItemPersonObject";
  import { cItemObject } from "../../domain/ItemObject";
  import { cObject } from "../../domain/Object";

  import ObjectDialog from "../../components/object/ObjectDialog.vue";

  const keywords = useKeywordStore();

  const { personGetName, peopleSearchInput, peopleSearch, peopleAvailable } =
    usePersonSearch();
  const { objectGetName, objectSearchInput, objectSearch, objectsAvailable } =
    useObjectSearch();

  const props = defineProps({
    item: {
      type: cItem,
      required: true,
    },
    canZoomImage: {
      type: Boolean,
      default: false,
      required: false,
    },
    selectedPerson: {
      type: Object as PropType<ItemPersonInterface | null>,
      required: false,
      default: null,
    },
    selectedObject: {
      type: Object as PropType<ItemObjectInterface | null>,
      default: null,
    },
  });

  const emit = defineEmits<{
    (e: "person-selected", pPerson: cItemPersonObject | null): void;
    (e: "object-selected", pObject: cItemObject | null): void;
  }>();

  const categoryBox = ref(null);
  const activePersonObject = computed(() => props.selectedPerson);
  const activeObject = computed(() => props.selectedObject);
  const clickTimeout = ref(-1);
  const dialogEdit = ref(false);

  watch(
    () => props.item,
    () => {
      selectedReset();
    }
  );

  function selectedReset() {
    setPersonSelected(null);
    setObjectSelected(null);
  }

  /**
   * Format the date in the 'date taken' picker
   */
  function dateTakenFormat() {
    if (props.item.getDateTaken() == "") return "Date Taken";

    return new Date(props.item.getDateTaken())
      .toLocaleString("en-us", {
        weekday: "long",
        day: "2-digit",
        year: "numeric",
        month: "long",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
        timeZoneName: undefined,
      })
      .toString();
  }

  /**
   * Event: Category item selected
   */
  function categorySelectItem(pItem: string) {
    props.item.category = pItem;

    categoryBox._value.menu = false;
    props.item.categoryUpdate();
  }

  /**
   * Event: Category box lost focus
   */
  function categoryLostFocus() {
    props.item.categoryUpdate();
  }

  const isPersonSelected = (person: cItemPersonObject | cPerson) => {
    if (person instanceof cPerson) {
      return activePersonObject.value?.getPersonId() == person.getId();
    }
    if (person instanceof cItemPersonObject) {
      return activePersonObject.value?.getId() == person.getId();
    }
    return false;
  };

  const setPersonSelected = (person: cItemPersonObject | null) => {
    if (!person || isPersonSelected(person)) {
      emit("person-selected", null);
    } else {
      emit("person-selected", person);
    }
  };

  const isObjectSelected = (object: cItemObject | cPerson) => {
    if (object instanceof cObject) {
      return activeObject.value?.getObjectId() == object.getId();
    }
    if (object instanceof cItemObject) {
      return activeObject.value?.getId() == object.getId();
    }
    return false;
  };

  const setObjectSelected = (object: cItemObject | null) => {
    if (!object || isObjectSelected(object)) {
      emit("object-selected", null);
    } else {
      emit("object-selected", object);
    }
  };

  /**
   * Display the object editor dialog
   */
  const openObjectEdit = (object: cItemObject | null) => {
    if (object == null) return;

    dialogEdit.value = true;
  };

  /**
   * Handle a click
   */
  const handleClick = (object: cItemObject | null) => {
    clearTimeout(clickTimeout.value);

    if (activeObject.value == null || activeObject.value != object) {
      setObjectSelected(object);
      return;
    }

    clickTimeout.value = setTimeout(() => {
      setObjectSelected(object);
    }, 200);
  };

  /**
   * Handle a double click
   */
  const handleDoubleClick = (object: cItemObject | null) => {
    clearTimeout(clickTimeout.value);

    openObjectEdit(object);
  };

  const handleKeydown = (event) => {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(event.key)) {

            event.stopPropagation();
        }
    };

</script>

<template>
  <v-row>
    <v-col cols="7">
      <Datepicker
        dark
        autoApply
        v-model="item.datetaken"
        placeholder="Date Taken"
        :year-range="[1800, new Date().getFullYear()]"
        :format="dateTakenFormat"
        :startTime="{ hours: 0, minutes: 0 }"
        @blur="item.dateTakenUpdate()"
        @click:clear="item.dateTakenClear()"
      />
    </v-col>

    <v-col>
      <v-combobox
        ref="categoryBox"
        v-model="item.category"
        :items="keywords.getCategories"
        label="Category"
        clearable
        @click:clear="item.categoryClear()"
        item-title="content"
        variant="underlined"
        v-on:blur="categoryLostFocus"
        :menu-props="{ maxHeight: 500 }"
        spellcheck="true"
      >
        <template v-slot:item="{ item }">
          <v-list-item @click="categorySelectItem(item.title)">
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-combobox>
    </v-col>
  </v-row>

  <v-text-field
    v-model="item.description"
    label="Description"
    clearable
    variant="underlined"
    density="compact"
    spellcheck="true"
    v-on:blur="item.descriptionUpdate()"
    @keydown="handleKeydown"
  >
  </v-text-field>

  <v-combobox
    v-model="item.people"
    v-model:search="peopleSearch"
    :delimiters="[',']"
    :items="peopleAvailable"
    label="People"
    multiple
    return-object
    chips
    closable-chips
    @update:modelValue="item.peopleUpdate()"
    @update:search="peopleSearchInput"
    :menu-props="{ maxHeight: 500 }"
    :item-title="personGetName"
    :hide-no-data="false"
    variant="underlined"
    spellcheck="true"
  >
    <template v-slot:chip="{ item, index, props }">
      <v-chip
        :key="index"
        :input-value="isPersonSelected(item.raw)"
        v-bind="props"
        :color="isPersonSelected(item.raw) ? 'blue' : 'default'"
        @click="setPersonSelected(item.raw)"
      >
        {{ personGetName(item.raw) }}
      </v-chip>
    </template>

    <template
      v-slot:no-data
      v-if="peopleSearch.length > 2"
    >
      <v-list-item>
        <v-list-item-title>
          No results matching "<strong>{{ peopleSearch }}</strong
          >". Press <kbd>enter</kbd> to create a new one
        </v-list-item-title>
      </v-list-item>
    </template>

    <template
      v-slot:no-data
      v-else
    >
    </template>
  </v-combobox>

  <v-combobox
    v-model="item.objects"
    v-model:search="objectSearch"
    :delimiters="[',']"
    :items="objectsAvailable"
    label="Objects"
    multiple
    return-object
    chips
    closable-chips
    @update:modelValue="item.objectUpdate()"
    @update:search="objectSearchInput"
    :menu-props="{ maxHeight: 500 }"
    :item-title="objectGetName"
    :hide-no-data="false"
    variant="underlined"
    spellcheck="true"
  >
    <template v-slot:chip="{ item, index, props }">
      <v-chip
        :key="index"
        :input-value="isObjectSelected(item.raw)"
        v-bind="props"
        :color="isObjectSelected(item.raw) ? 'blue' : 'default'"
        @click="handleClick(item.raw)"
        @dblclick="handleDoubleClick(item.raw)"
      >
        {{ objectGetName(item.raw) }}
      </v-chip>
    </template>

    <template
      v-slot:no-data
      v-if="objectSearch.length > 2"
    >
      <v-list-item>
        <v-list-item-title>
          No results matching "<strong>{{ objectSearch }}</strong
          >". Press <kbd>enter</kbd> to create one
        </v-list-item-title>
      </v-list-item>
    </template>

    <template
      v-slot:no-data
      v-else
    >
    </template>
  </v-combobox>

  <v-combobox
    v-model="item.keywords"
    :delimiters="[',']"
    :items="keywords.getKeywords"
    label="Keywords"
    multiple
    return-object
    closable-chips
    chips
    :menu-props="{ maxHeight: 500 }"
    @update:modelValue="item.keywordUpdated()"
    item-title="content"
    variant="underlined"
    spellcheck="true"
  >
  </v-combobox>

  <template v-if="activeObject !== null && activeObject.object.object">
    <ObjectDialog
      :object="activeObject.getObject()"
      :visible="dialogEdit"
      @closed="dialogEdit = false"
    />
  </template>
</template>

import { LngLat } from "mapbox-gl";
import { defineStore } from "pinia";
import { PersonInterface } from "rundown-common";
import { FilterGroup } from "../domain/FilterParameters/Filter";

export interface iMonth {
  month: number;
  year: number;
}

export enum eRadiusMode {
  Meters = "Meters",
  Kilometers = "Kilometers",
}

export enum FilterMode {
  AND,
  NOT,
}

var defaultSearchInTags = ["Keywords", "Category", "People"];

export const useFilterStore = defineStore("filters", {
  state: () => ({
    searchboxmodel: [] as Array<FilterGroup>,

    collectionId: 0,
    dateFrom: { year: 0, month: -1 } as iMonth,
    geolocation: { lat: 0, lng: 0 } as LngLat,
    geoRadius: [0, 1000],
    geoRadiusMode: eRadiusMode.Meters,

    searchInTags: defaultSearchInTags as string[],
    includeHidden: '' as string
  }),

  getters: {
    getSearchboxModel: (state) => state.searchboxmodel,
    getGeoRadiusRange: (state) => state.geoRadius,
    getGeoRadiusMode: (state) => state.geoRadiusMode,

    getGeoLocation: (state) => state.geolocation,
    hasGeoLocation: (state) =>
      state.geolocation.lat != 0 && state.geolocation.lng != 0,

    getCollectionId: (state) => state.collectionId,

    getDateFrom(state): iMonth {
      return state.dateFrom;
    },

    /**
     * Get filter parameters
     */
    getParameters(state): string {
      var query = new URLSearchParams();

      query.append("collection_id", state.collectionId.toString());

      // Date Filtering
      if (state.dateFrom.year > 0) {
        var dateFrom = state.dateFrom.year.toString();

        // If a month is set, we begin there, otherwise January
        if (state.dateFrom.month >= 0) {
          var month = (state.dateFrom.month + 1).toString().padStart(2, "0");
          dateFrom += "-" + month.toString();
        } else {
          dateFrom += "-01";
        }

        dateFrom += "-01T00:00:00";

        var date = new Date(dateFrom);
        query.append("timestampStart", (date.getTime() / 1000).toString());

        // If a month is set, set the end of range as end of the month
        if (state.dateFrom.month >= 0) {
          var dateEnd = new Date(date.getFullYear(), date.getMonth() + 1);
          query.append("timestampEnd", (dateEnd.getTime() / 1000).toString());
        }
      } else {
        // Only a month is set, so we search that month
        if (state.dateFrom.month >= 0) {
          query.append("startMonth", (state.dateFrom.month + 1).toString());
        }
      }

      // Search Filtering
      const groupsQueryParam = JSON.stringify(
        this.searchboxmodel.map((filterGroup) => filterGroup.toQueryParam())
      );
      if (groupsQueryParam.length) {
        query.append("filtergroups", groupsQueryParam);
      }

      // Location Filtering
      if (
        state.geoRadius[1] > 0 &&
        state.geolocation.lat != 0 &&
        state.geolocation.lng != 0
      ) {
        var radius = state.geoRadius[1];
        if (state.geoRadiusMode === eRadiusMode.Kilometers) {
          radius *= 1000;
        }

        query.append("radius", radius.toString());
        query.append("latitude", state.geolocation.lat.toString());
        query.append("longitude", state.geolocation.lng.toString());
      }

      // Append search tags, if they are not the defaults
      if (
        state.searchInTags.length &&
        state.searchInTags.toString() != defaultSearchInTags.toString()
      ) {
        query.append("searchInTags", state.searchInTags.join(","));
      }

      if(this.includeHidden.length) {
        query.append("includeHidden", this.includeHidden);
      }
      
      return query.toString();
    },
  },

  actions: {
    setCollectionId(pCollectionID: number) {
      this.collectionId = pCollectionID;
    },

    setYearFrom(pYear: number) {
      this.dateFrom.year = pYear;
    },

    setMonthFrom(pMonth: iMonth | null) {
      if (pMonth == null) {
        this.dateFrom.month = -1;
        return;
      }

      this.dateFrom.month = pMonth.month;
    },

    setSearchInTags(pTags: string[]) {
      this.searchInTags = pTags;
    },

    resetSearchInTags() {
      this.searchInTags = [];
    },

    setGeoRadius(pRadius: number[]) {
      this.geoRadius = pRadius;
    },

    setGeoLocation(pLocation: LngLat) {
      this.geolocation.lat = pLocation.lat;
      this.geolocation.lng = pLocation.lng;
    },

    setGeoRadiusMode(pMode: eRadiusMode) {
      this.geoRadiusMode = pMode;
    },

    resetGeo() {
      this.geoRadius = [0, 100];
      this.geoRadiusMode = eRadiusMode.Meters;
      this.geolocation.lat = 0;
      this.geolocation.lng = 0;
    },

    searchAddItem(pItem: string | PersonInterface | null) {
      if (pItem == null) return;

      this.searchboxmodel.push(new FilterGroup(pItem));
    },

    setIncludeHidden(pCode: string) {
      this.includeHidden = pCode;
    }
  },
});

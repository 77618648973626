<script setup lang="ts">
import { ref } from 'vue'
import { iMonth, useFilterStore } from '../../stores/useFilterStore';

const filters = useFilterStore();

const year = ref('');
const currentYear = (new Date()).getFullYear() + 1;

const month = ref(null);
const startDate = ref(new Date());

/**
 * Event: Year filter change
 */
function filterYearChange(pSelectedYear: string) {
    if (pSelectedYear != undefined) {
        //   month.value.year = parseInt(pSelectedYear);
    }
    filters.setYearFrom(parseInt(pSelectedYear));
}

/**
 * Event: Month filter changed
 */
function filterMonthChange(pSelectedDate: iMonth) {
    filters.setMonthFrom(pSelectedDate);
}

/**
 * Month Filter format date string
 */
function filterMonthFormat(pDate: Date) {
    return pDate.toLocaleString('en-us', { month: 'long' }).toString();
}
</script>

<template>
    <v-list density="compact" nav>
        <v-list-subheader prepend-icon="mdi-image-multiple">
            <v-list-item-title>Date Filter</v-list-item-title>
        </v-list-subheader>
        <Datepicker dark yearPicker autoApply v-model="year" placeholder="Year" format="yyyy" position="left"
            :start-date="startDate" focus-start-date @update:modelValue="filterYearChange" :yearRange="[1700, currentYear]"
            :teleport="true" />

        <Datepicker dark monthPicker autoApply v-model="month" placeholder="Month" monthNameFormat="long" 
            :format="filterMonthFormat" :ui="{ menu: 'datefilter' }" @update:modelValue="filterMonthChange" :teleport="true" />
    </v-list>
</template>

<style scope>
.datefilter .dp__selection_grid_header {
    display: none !important;
    visibility: hidden !important;
}
</style>

<script setup lang="ts">
  import { onMounted, ref, PropType } from "vue";
  import Image from "../../../components/Image.vue";
  import ItemTable from "../../../components/table/ItemTable.vue";

  const props = defineProps({
    itemStore: {
      type: Object,
      required: true,
    },
    itemWindowStore: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    noitemsMsg: {
      type: String,
      required: true,
    },
    itemColumns: {
      type: Array as PropType<string[]>,
      required: false,
    },
  });

  const emit = defineEmits(["removeItem", "removeAllItems"]);

  const dialogRemoveAll = ref(false);

  onMounted(async () => {
    await props.itemStore.load();
  });
</script>

<template>
  <template v-if="props.itemWindowStore.items.length">
    <v-card
      elevation="2"
      class="justify-center w-100"
    >
      <v-card-title>{{
        props.itemStore.getSelectedItem.getName()
      }}</v-card-title>

      <v-row>
        <v-col cols="6">
          <Image
            :item="props.itemStore.getSelectedItem"
            :height="'300'"
            thumbnail="large"
          />
        </v-col>

        <v-col cols="6">
          <v-row>
            <v-col>{{ description }}</v-col>
          </v-row>
          <v-row>
            <v-col
              cols="6"
              class="mt-4"
            >
              <v-btn
                color="blue"
                prepend-icon="mdi-delete"
                @click="$emit('removeItem', props.itemStore.getSelectedItem)"
              >
                Remove selected item
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              class="mt-4"
            >
              <v-btn
                color="red"
                prepend-icon="mdi-delete"
                @click="dialogRemoveAll = true"
              >
                Remove all
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <ItemTable
        read-only
        :isLoading="props.itemStore.isLoading"
        :items="props.itemWindowStore.items"
        :columns="props.itemColumns"
        :item-current="props.itemStore.getSelectedItem"
        :page="props.itemWindowStore.windowNumber"
        :pages="props.itemWindowStore.totalWindows"
        :items-per-page="props.itemWindowStore.windowSize"
        @page-select="props.itemWindowStore.changeToWindow"
        @item-select="props.itemStore.selectItem"
        @items-per-page-change="props.itemWindowStore.setWindowSize"
        @item-next="props.itemWindowStore.moveDown"
        @item-previous="props.itemWindowStore.moveUp"
        @page-next="props.itemWindowStore.moveWindowDown"
        @page-previous="props.itemWindowStore.moveWindowUp"
      />
    </v-card>
  </template>

  <template v-else>
    <v-row
      v-if="!props.itemStore.isLoading"
      class="mt-12"
      justify="center"
    >
      <v-col
        cols="12"
        sm="8"
        md="6"
        class="text-center"
      >
        <v-card
          class="px-5 py-8 rounded-lg"
          dark
        >
          <h2 class="">{{ noitemsMsg }}</h2>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-else
      class="mt-12"
      justify="center"
    >
      <v-progress-circular indeterminate />
    </v-row>
  </template>

  <v-dialog
    v-model="dialogRemoveAll"
    persistent
    width="auto"
  >
    <v-card>
      <v-card-title class="text-h5"
        >Are you sure you want to remove all items</v-card-title
      >
      <v-card-text
        >There is {{ props.itemStore.getTotalRecords }} items to be
        removed.</v-card-text
      >
      <v-card-actions>
        <v-spacer />
        <v-btn
          variant="text"
          color="green-darken-1"
          @click="dialogRemoveAll = false"
          >Cancel</v-btn
        >
        <v-spacer />
        <v-btn
          variant="text"
          color="green-darken-1"
          @click="
            dialogRemoveAll = false;
            emit('removeAllItems');
          "
        >
          Remove All
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

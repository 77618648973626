<script setup lang="ts">
  import { ref } from "vue";
  import { useFilterStore } from "../../stores/useFilterStore";
  import { useSettingsStore } from "../../stores/useSettingsStore";
  import { useKeywordStore } from "@/stores/useKeywordStore";
import { useAuthStore } from "@/stores/useAuthStore";

  const props = defineProps({
    listMode: {
      type: Boolean,
      default: true,
    },
  });

  const filter = useFilterStore();
  const settings = useSettingsStore();
  const auth= useAuthStore();

  var visible = ref(false);
  var searchInTags = ["Keywords", "Category", "People", "Author"];
  const showPinDialog = ref(false);
  const pin = ref("");

  const submitPin = async () => {
    console.log("Entered PIN:", pin.value);
    showPinDialog.value = false;
    const code = await useKeywordStore().unlockKeywords(pin.value);
    pin.value = "";
    if (code == null) {
      clearIncludeHidden();
      return;
    }
    useFilterStore().setIncludeHidden(code);
  };

  const handleHiddenItemSwitch = () => {
    if (settings.showHiddenItems === true) {
      pin.value = "";
      showPinDialog.value = true;
    } else {
      useFilterStore().setIncludeHidden("");
    }
  };

  const clearIncludeHidden = () => {
    settings.showHiddenItems = false;
    useFilterStore().setIncludeHidden("");
  };
</script>

<template>
  <v-menu
    v-model="visible"
    :close-on-content-click="false"
    location="start"
    transition="scale-transition"
  >
    <template v-slot:activator="{ props }">
      <template v-if="listMode == true">
        <v-btn
          icon
          v-bind="props"
        >
          <v-icon icon="mdi-account-cog"></v-icon>
          <v-tooltip
            activator="parent"
            location="bottom"
            >Options</v-tooltip
          >
        </v-btn>
      </template>
      <template v-else>
        <v-list-item
          v-bind="props"
          prepend-icon="mdi-account-cog"
        >
          <v-list-item-title>Options</v-list-item-title>
        </v-list-item>
      </template>
    </template>

    <v-card min-width="300">
      <v-list>
        <v-list-item
          prepend-icon="mdi-account-cog"
          title="Options"
          subtitle="Filters"
        ></v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list>
        <v-list-item>
          <v-switch
            v-model="settings.homeViewModeIsPaged"
            color="purple"
            true-value="Infinite Scroll"
            false-value="Paging"
            :label="`View Mode: ${settings.homeViewModeIsPaged}`"
            hide-details
          >
          </v-switch>
        </v-list-item>

        <v-list-item v-if="auth.isAuthed == true">
          <v-switch
            v-model="settings.showHiddenItems"
            color="purple"
            :label="`Show hidden items`"
            hide-details
            @update:modelValue="handleHiddenItemSwitch"
          >
          </v-switch>
        </v-list-item>

        <v-list-item>
          <v-combobox
            v-model="filter.searchInTags"
            :items="searchInTags"
            label="Search in tags"
            multiple
            chips
            closable-chips
          ></v-combobox>
        </v-list-item>
      </v-list>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text="Cancel"
          @click="visible = false"
        >
        </v-btn>
        <v-btn
          color="primary"
          text="Done"
          @click="visible = false"
        >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>

  <v-dialog
    v-model="showPinDialog"
    max-width="400"
    persistent
  >
    <v-card>
      <v-card-title>Enter PIN</v-card-title>

      <v-card-text>
        <v-text-field
          v-model="pin"
          label="PIN"
          type="password"
          maxlength="4"
        ></v-text-field>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          @click="
            showPinDialog = false;
            clearIncludeHidden();
          "
          >Cancel</v-btn
        >
        <v-btn
          color="green"
          @click="submitPin"
          >Done</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

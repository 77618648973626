/**
 * Raw API data types
 * 
 * These types are used to parse and interact with the JSON data returned by the API.
 */

import { PropertyData } from "rundown-common/src/data/PropertyInterface";
import { PageLinks, PageMetaData } from "./PagingInterfaces";
import { KeyStringData } from '../data/KeystringInterfaces';

// Tag Types
export type tTagTypeStrings = Array<ItemTagsDataStrings>;
export type tTagTypeObjects = Array<ItemObjectData>;
export type tTagTypeLocations = Array<ItemLocationData>;
export type tTagTypeString = ItemTagData<string>;
export type tTagTypeCategory = Array<KeyStringData>

// Tags
export type tTagKeyword = ItemTagData<tTagTypeStrings>;
export type tTagCategory = ItemTagData<tTagTypeCategory>;
export type tTagDateTime = ItemTagData<string | null>;
export type tTagPeople = ItemTagData<tTagTypeObjects>;
export type tTagObject = ItemTagData<tTagTypeObjects>;
export type tTagLocation = ItemTagData<tTagTypeLocations>;
export type tTagDescription = ItemTagData<string>;

export interface ItemTagsDataStrings {
    content: string
};

export type ItemPropertiesData = { 
    [key: string]: PropertyData 
};

export enum ItemObjectType {
    Other = 'other',
    Person = 'person'
}

export interface ItemObjectData {
    id: number,
    type: string,
    object: number | null,
    person: number | null,
    face: number | null,
    x: number,
    y: number,
    width: number,
    height: number
};

export interface ItemLocationData {
    id: number,
    placeName: string,
    latitude: number,
    longitude: number,
    altitude: string,
    altitudeRef: string,
    speed: number,
    speedRef: string
};

export interface ItemTagData<V> {
    id: number,
    data: V
};

export interface ItemTagsData {
    Category?: tTagCategory,
    DateTimeOriginal?: tTagDateTime,
    DateTimeDigitized?: tTagDateTime,
    People?: tTagPeople,
    Object?: tTagObject,
    Keywords?: tTagKeyword,
    Locations?: tTagLocation,
    Description?: tTagDescription,
    Comments?: tTagTypeString,
    Author?: tTagPeople
};


export interface ItemData {
    id: number;
    name: string;
    path: string;
    type: string;
    missing: boolean;
    collection_id: number;
    tags: ItemTagsData;
    properties: ItemPropertiesData;
}

export interface ItemPageData {
    _meta: PageMetaData;
    _links: PageLinks;
    items: Array<ItemData>;
}

export interface ItemShareData {
    id: number;
    name: string;
    item: string;
    expires: string;
}

export enum ItemRotate {
    Left = "left",
    Right = "right"
}
import { defineStore } from "pinia";
import { useItemSlidingWindow } from "../utility/useItemSlidingWindow";
import { useItemMissingStore } from "./useItemMissingStore";

export const useItemMissingSlidingWindowStore = defineStore(
  "itemMissingSlidingWindow",
  () => {
    const itemStore = useItemMissingStore();
    const {
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      isLoading,
      items,
      windowNumber,
      moveUp,
      moveDown,
      moveWindowDown,
      moveWindowUp,
      jumpTo,
      changeToWindow,
      setWindowSize,
    } = useItemSlidingWindow(itemStore);

    return {
      currentPosition,
      windowSize,
      totalItems,
      totalWindows,
      isLoading,
      items,
      windowNumber,
      moveUp,
      moveDown,
      moveWindowDown,
      moveWindowUp,
      jumpTo,
      changeToWindow,
      setWindowSize,
    };
  }
);
